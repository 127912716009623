import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";

interface GenericGroupState {
  isActive: boolean;
  attribute: string;
  property: string;
}

interface GroupState {
  group: GenericGroupState;
}

const initialState = {
  isActive: false,
  attribute: '',
  property: ''
}

@Injectable({ providedIn: 'root' })
export class GroupStore extends ComponentStore<GroupState> {
  readonly all$ = this.select(({ group }) => group);
  readonly groupByActive$ = this.select(({ group }) => group.isActive);

  readonly toggleGroupBy$ = this.updater((state, prop: any) => ({
    group: { ...state.group, isActive: true, attribute: prop.attribute, property: prop.property }
  }));

  resetGroupBy() {
    this.setState({ group: initialState });
  }

  constructor() {
    super({ group: initialState });
  }
}
