import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {getTemplates, loadTemplates, TemplatesState} from "@app-web-central/web/dashboard/data-access";

@Injectable({ providedIn: 'root' })
export class TemplatesFacade {
  readonly templates$ = this._store.pipe(select(getTemplates));

  constructor(
    private _store: Store<TemplatesState>
  ) { }

  init() {
    this._store.dispatch(loadTemplates());
  }

}
