import {CfRole, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {
  loadRoles,
  loadRolesError,
  loadRolesSuccess,
} from "./roles.actions";

export interface RolesState extends GenericState<CfRole[]> { }

const initialState: RolesState = {
  data: null,
  error: null,
  status: 'pending'
}

export const rolesFeatureKey = 'roles';

export const rolesReducer = createReducer(
  initialState,
  on(loadRoles, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadRolesSuccess, (state, { roles }) => ({
    ...state,
    data: roles,
    status: 'success',
    error: null
  })),
  on(loadRolesError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
