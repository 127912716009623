<nz-steps
  *ngIf="steps && steps.length > 0"
  [nzCurrent]="currentStep"
  nzDirection="vertical"
  nzSize="small">
  <nz-step
    *ngFor="let step of sortBy('stepOrder')"
    nzTitle="{{ getCurrentStep(step) | translate }}"
    nzSubtitle="{{ step.startDate || step.endDate | date: 'medium' : '' : translate.currentLang }}"
    [nzStatus]="getCurrentStatus(step)"
    [nzIcon]="getLoadingStatus(step)"
    nzDescription="{{ getCurrentStepDescription(step) | translate }} {{ getCurrentUserStep(step) }}">
  </nz-step>
</nz-steps>
