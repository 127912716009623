import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {map} from "rxjs";
import {getUsers} from "./users.selectors";
import {UsersState} from "./users.reducer";
import {loadUsers} from "./users.actions";
import {SecurityService} from "@app-web-central/web/settings/data-access";
import {UserStore} from "../user";
import {CfUser} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class UsersFacade {
  readonly users$ = this._store.pipe(select(getUsers));
  readonly usersEnabled$ = this.users$.pipe(
    map(users => users?.filter(user => user.enabled && !user.isBuiltIn))
  );
  readonly usersEnabledAndAgent$ = this.users$.pipe(
    map((users) => users !== null ? users.filter(user => user.enabled && user.enabledAgent && !user.isBuiltIn) : [])
  );

  constructor(
    private _userStore: UserStore,
    private _store: Store<UsersState>,
    private _securityService: SecurityService
  ) { }

  init() {
    const tenantId = this._securityService.getCurrentSession().tenant;
    this._store.dispatch(loadUsers({ tenantId }));
  }

  setAsAway(user: CfUser) {
    this._userStore.updateAsAway(user.id);
  }
}
