import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
  activateSession,
  activateSessionError,
  activateSessionSuccess,
  deactivateSession,
  deactivateSessionError,
  deactivateSessionSuccess,
  loadSession,
  loadSessionError,
  loadSessionSuccess,
  logoutSession,
  logoutSessionError,
  logoutSessionSuccess,
  updateSession,
  updateSessionError,
  updateSessionSuccess
} from "./session.actions";
import {catchError, map, mergeMap} from "rxjs/operators";
import {of} from 'rxjs';
import {UserApi} from "@app-web-central/web/shared/data-access/cf-api";
import {SecurityService} from "@app-web-central/web/settings/data-access";

@Injectable()
export class SessionEffects {
  loadSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSession),
      mergeMap(() => {
        const userId = this._security.getCurrentSession().id;
        return this._userApi.get(userId)
          .pipe(
            map((response) => loadSessionSuccess({ session: response.payload })),
            catchError(() => of(loadSessionError()))
          )
      })
    )
  );

  updateSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSession),
      mergeMap(({ session }) => this._userApi.update(session.id, session)
        .pipe(
          map(response => updateSessionSuccess({ session: response.payload })),
          catchError(() => [updateSessionError()])
        )
      )
    )
  );

  activateSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateSession),
      mergeMap(({ deviceInfo, session }) => this._userApi.activate(session?.id, deviceInfo)
        .pipe(
          map((response) => activateSessionSuccess({ session: response.payload })),
          catchError((error) => [activateSessionError(error)])
        )
      )
    )
  );

  deactivateSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deactivateSession),
      mergeMap(({ deviceInfo, sessionId }) => this._userApi.deactivate(sessionId, deviceInfo)
        .pipe(
          map((response) => deactivateSessionSuccess({ session: response.payload })),
          catchError((error) => [deactivateSessionError(error)])
        )
      )
    )
  );

  logoutSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutSession),
      mergeMap(({ deviceInfo, session }) => this._userApi.logout(session.id, deviceInfo)
        .pipe(
          map(response => logoutSessionSuccess({ session: response.payload })),
          catchError((error) => [logoutSessionError(error)])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _userApi: UserApi,
    private _security: SecurityService
  ) {}
}
