import {Component, HostListener, Inject, Input, OnInit} from '@angular/core';
import {AddTaskModalComponent} from "@app-web-central/web/task/ui/add-modal";
import {NzModalService} from "ng-zorro-antd/modal";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {DrawerQuickSettingsComponent} from "@app-web-central/web/shell/ui/drawer-quick-settings";
import {
  CfUser,
  CfUserSession,
  NavItem,
  NavItemMixin,
  UserStatusValuesDisplay
} from "@app-web-central/web/shared/data-access/models";
import {ModalUserStatusComponent} from "@app-web-central/web/shell/ui/modal-user-status";
import {TranslateService} from "@ngx-translate/core";
import {DrawerHelpComponent} from "@app-web-central/web/shell/ui/drawer-help";
import {DrawerNotificationsComponent} from "@app-web-central/web/shell/ui/drawer-notifications";
import {SessionFacade} from "@app-web-central/web/session/data-access";
import {SecurityService} from "@app-web-central/web/settings/data-access";
import {OrganizationsFacade} from "@app-web-central/web/organization/data-access";
import {RouteUtil} from "@app-web-central/web/shared/utils";
import {UsersFacade} from "@app-web-central/web/user/data-access";
import {BreadcrumbsStore, HeaderLinkStore} from "@app-web-central/web/shared/data-access/store";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {DrawerSearchComponent} from "@app-web-central/web/shell/ui/drawer-search";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {take} from "rxjs";

@UntilDestroy()
@Component({
  selector: 'as-nav-bar-desktop-menu',
  templateUrl: './nav-bar-desktop-menu.component.html',
  styleUrls: ['./nav-bar-desktop-menu.component.scss']
})
export class NavBarDesktopMenuComponent implements OnInit {
  @Input() hasCreationButton = true;
  @Input() hasBreadcrumbs = false;
  readonly session$ = this._sessionFacade.session$;
  readonly organization$ = this._organizationFacade.organization$;
  quickItems!: NavItem[];
  menuLinks!: NavItemMixin[];

  constructor(
    private _modal: NzModalService,
    private _security: SecurityService,
    private _translate: TranslateService,
    private _drawerService: NzDrawerService,
    public headerLinkStore: HeaderLinkStore,
    public breadcrumbStore: BreadcrumbsStore,
    private readonly _usersFacade: UsersFacade,
    private readonly _sessionFacade: SessionFacade,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private readonly _organizationFacade: OrganizationsFacade,
  ) { }

  ngOnInit(): void {
    this.quickItems = [
      new NavItem('tooltips.search', 'outline/magnifying-glass', this.onOpenDrawerSearch.bind(this)),
      new NavItem('tooltips.help', 'outline/lifebuoy', this.openHelpDrawer.bind(this)),
      new NavItem('tooltips.quick_settings', 'outline/cog', this.openQuickSettings.bind(this)),
      new NavItem('tooltips.notifications', 'outline/bell', this.openNotificationsDrawer.bind(this)),
    ];
    this.headerLinkStore.headerLinkItems$
      .pipe(take(1), untilDestroyed(this))
      .subscribe((header) => {
        if (header) {
          this.menuLinks = header;
        }
      });
  }

  isActive(sessions: CfUserSession[]) {
    const sessionId = this._security.getCurrentSessionId();
    return sessions.some((x) => x.sessionId === sessionId);
  }

  routeToProfileUrl() {
    window.open(`${this._appConfig.administrationUrl}${RouteUtil.getMyProfileRouteUrl()}`, '_target');
  }

  getCurrentStatus(session: CfUser) {
    return UserStatusValuesDisplay.find((x) => x.status === session.accountStatus);
  }

  setUserSessionAsAway(user: CfUser) {
    this._usersFacade.setAsAway(user);
  }

  @HostListener('window:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
      this.onOpenDrawerSearch();
    }
  }

  onOpenDrawerSearch() {
    this._drawerService.create({
      nzContent: DrawerSearchComponent,
      nzPlacement: 'left',
      nzClosable: false,
      nzWidth: 500
    });
  }

  openAddEventModal() {
    this._modal.create({
      nzContent: AddTaskModalComponent,
      nzClosable: false,
      nzWidth: 1100,
      nzFooter: null,
      nzStyle: {top: '20px'}
    });
  }

  openUserStatusModal(session: CfUser) {
    this._modal.create({
      nzContent: ModalUserStatusComponent,
      nzComponentParams: { session },
      nzWidth: 500,
      nzClosable: false,
      nzFooter: null
    });
  }

  openNotificationsDrawer() {
    this._drawerService.create({
      nzContent: DrawerNotificationsComponent,
      nzTitle: this._translate.instant('drawers.notifications.title'),
      nzWidth: 500,
      nzPlacement: 'right',
      nzClosable: true
    });
  }

  openQuickSettings() {
    this._drawerService.create({
      nzContent: DrawerQuickSettingsComponent,
      nzTitle: this._translate.instant('drawers.quick_settings.title'),
      nzPlacement: 'right',
      nzClosable: true,
      nzWidth: 300
    });
  }

  openHelpDrawer() {
    this._drawerService.create({
      nzContent: DrawerHelpComponent,
      nzTitle: this._translate.instant('drawers.help.title'),
      nzPlacement: 'right',
      nzClosable: true,
      nzWidth: 370
    });
  }

  signOut(user: CfUser) {
    this._modal.confirm({
      nzTitle: user.fullName,
      nzContent: this._translate.instant('modals.logout.title'),
      nzClosable: true,
      nzOnOk: () => this._logout(user)
    });
  }

  _logout(session: CfUser) {
    this._sessionFacade.logout(session).then((logout) => {
      if (logout) {
        this._security.signOutSession();
      }
    });
  }

}
