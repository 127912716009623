import {Component, Inject, OnInit} from '@angular/core';
import {NavItemWithUrl} from "@app-web-central/web/shared/data-access/models";
import {ModalFeedbackComponent} from "@app-web-central/web/shell/ui/modal-feedback";
import {NzModalService} from "ng-zorro-antd/modal";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {RouterUtil} from "@app-web-central/web/shared/utils";

@Component({
  selector: 'as-drawer-help',
  templateUrl: './drawer-help.component.html',
  styleUrls: ['./drawer-help.component.scss'],
})
export class DrawerHelpComponent implements OnInit {
  items!: NavItemWithUrl[];
  privacyUrl = `${this._appConfig.legalUrl}/p/legal/privacy-policy`;
  termsUrl = `${this._appConfig.legalUrl}/p/legal/terms-of-use`;

  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _modal: NzModalService
  ) { }

  ngOnInit(): void {
    this.items = [
      new NavItemWithUrl('tooltips.help', 'outline/document', this.routeUrl.bind(this), RouterUtil.ExternalUrls.Documentation, true),
      new NavItemWithUrl('tooltips.support', 'solid/feedback', this.openFeedbackModal.bind(this)),
      new NavItemWithUrl('tooltips.privacy', 'outline/shield-check', this.routeUrl.bind(this), this.privacyUrl, true),
      new NavItemWithUrl('tooltips.terms', 'outline/document', this.routeUrl.bind(this), this.termsUrl, true),
    ];
  }

  routeUrl(url: string) {
    window.open(url, '_blank');
  }

  openFeedbackModal() {
    this._modal.create({
      nzContent: ModalFeedbackComponent,
      nzClosable: true,
      nzWidth: 450
    });
  }

}
