export enum PeriodEnum {
  LAST_1_H = '1H',
  LAST_24_H = '24H',
  LAST_7_D = '7D',
  LAST_14_D = '14D',
  LAST_30_D = '30D',
  LAST_90_D = '90D',
  LAST_1_Y = '1Y',
  LAST_ABD = 'ABD',
}

export const PeriodDisplayValues = {
  [PeriodEnum.LAST_1_H]: 'components.dashboard.period.last_1_hour',
  [PeriodEnum.LAST_24_H]: 'components.dashboard.period.last_24_hours',
  [PeriodEnum.LAST_7_D]: 'components.dashboard.period.last_7_days',
  [PeriodEnum.LAST_14_D]: 'components.dashboard.period.last_14_days',
  [PeriodEnum.LAST_30_D]: 'components.dashboard.period.last_30_days',
  [PeriodEnum.LAST_90_D]: 'components.dashboard.period.last_90_days',
  [PeriodEnum.LAST_1_Y]: 'components.dashboard.period.current_year',
  [PeriodEnum.LAST_ABD]: 'components.dashboard.period.custom_date',
} as any;

export const PeriodAndIntervalValues = {
  [PeriodEnum.LAST_1_H]: '30m',
  [PeriodEnum.LAST_24_H]: '12h',
  [PeriodEnum.LAST_7_D]: '1d',
  [PeriodEnum.LAST_14_D]: '1d',
  [PeriodEnum.LAST_30_D]: '1d',
  [PeriodEnum.LAST_90_D]: '1m',
  [PeriodEnum.LAST_1_Y]: '1m',
}

export const PeriodAndTimestampValues = {
  [PeriodEnum.LAST_1_H]: 'day',
  [PeriodEnum.LAST_24_H]: 'day',
  [PeriodEnum.LAST_7_D]: 'day',
  [PeriodEnum.LAST_14_D]: 'day',
  [PeriodEnum.LAST_30_D]: 'day',
  [PeriodEnum.LAST_90_D]: 'month',
  [PeriodEnum.LAST_1_Y]: 'month',
}

export type WidgetTypes = 'count' | 'lines' | 'area' | 'table';

export interface UrlParams {
  type: string;
  interval: string;
  orderBy: string | null;
  query: string;
  filters: string;
  criterias: string | null;
  timestamp: string | null;
  referrer: string;
  statsPeriod: string;
  switchAxis: boolean | null;
}

export interface CfWidget {
  posX: string;
  posY: string;
  type: WidgetTypes;
  label: string;
  tooltip: string | null;
  params: UrlParams;
}
