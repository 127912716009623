import {Component, Input} from '@angular/core';
import {BreadcrumbsLink} from "@app-web-central/web/shared/data-access/models";
import {BreadcrumbsStore} from "@app-web-central/web/shared/data-access/store";

@Component({
  selector: 'as-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {
  @Input() breadcrumbs!: BreadcrumbsLink[];
  constructor(
    private _breadcrumbStore: BreadcrumbsStore
  ) { }
  routeToUrl(breadcrumb: BreadcrumbsLink) {
    let canBePop = false;
    const newBreadcrumbs: BreadcrumbsLink[] = [];
    this.breadcrumbs.forEach((value) => {
      if (value.url === breadcrumb.url) {
        newBreadcrumbs.push(value);
        canBePop = true;
      }
      if (!canBePop) {
        newBreadcrumbs.push(value);
      }
    });
    this._breadcrumbStore.toggleBreadcrumbsState$(newBreadcrumbs);
  }
}
