import {Component, Input} from '@angular/core';
import {CfTask} from "@app-web-central/web/shared/data-access/models";
import {FormControl} from "@angular/forms";
import {ModalReminderComponent} from "@app-web-central/web/shell/ui/modal-reminder";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {NzModalService} from "ng-zorro-antd/modal";

@UntilDestroy()
@Component({
  selector: 'as-task-drawer-reminder',
  templateUrl: './task-drawer-reminder.component.html',
  styleUrls: ['./task-drawer-reminder.component.scss'],
})
export class TaskDrawerReminderComponent {
  @Input() task!: CfTask;
  @Input() control!: FormControl;

  constructor(
    private _modal: NzModalService
  ) { }

  handleOnAddReminder(checked: boolean) {
    if (checked) {
      const reminderModal = this._modal.create({
        nzContent: ModalReminderComponent,
        nzComponentParams: { task: this.task },
        nzClosable: false,
        nzFooter: null,
        nzWidth: 400
      });

      reminderModal.afterClose
        .pipe(untilDestroyed(this))
        .subscribe((hasData) => {
          if (hasData) {
            // this.control.setValue();
          }
        });
    }
  }

}
