import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {loadProjects, ProjectsState} from "@app-web-central/web/project/data-access";

@Injectable({ providedIn: 'root' })
export class ProjectsFacade {
  constructor(
    private _store: Store<ProjectsState>,
  ) { }

  init() {
    this._store.dispatch(loadProjects());
  }
}
