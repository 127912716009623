import {CfHub} from "@app-web-central/web/shared/data-access/models";

export class HubUtil {
  static replaceHubAndGetNewHubs(hubs: CfHub[] | null, hub: CfHub) {
    const newHubs = hubs !== null ? [...hubs] : [];
    const index = newHubs.findIndex((x) => x.id === hub.id);
    if (index === -1) {
      newHubs.push(hub);
    } else {
      newHubs[index] = hub;
    }
    return newHubs;
  }

  static removeHubFromHubs(hubs: CfHub[] | null, hub: CfHub) {
    const newHubs = hubs !== null ? [...hubs] : [];
    const index = newHubs.findIndex((x) => x.id === hub.id);
    newHubs.splice(index, 1);
    return newHubs;
  }
}
