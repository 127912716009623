import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {
  activateSession,
  deactivateSession,
  logoutSession,
  SessionsState,
  SessionStore
} from "@app-web-central/web/session/data-access";
import {CfUser} from "@app-web-central/web/shared/data-access/models";
import {loadSession, updateSession} from "./session.actions";
import {SecurityService} from "@app-web-central/web/settings/data-access";

@Injectable()
export class SessionFacade {
  session$ = this._sessionStore.session$;

  constructor(
    private _security: SecurityService,
    private _sessionStore: SessionStore,
    private _store: Store<SessionsState>
  ) { }

  /**
   * Init current local user session.
   *
   */
  init() {
    this._store.dispatch(loadSession());
  }

  /**
   * Activate user session.
   *
   * @param session the user session as { CfUser }.
   */
  activate(session: CfUser) {
    this._security.getDeviceInformation('user.login_verified').then((deviceInfo) => {
      if (deviceInfo && session) {
        this._store.dispatch(activateSession({ deviceInfo, session }));
      } else {
        console.warn('Cannot validate and activate user session.');
      }
    });
  }

  /**
   * Send a heart beat to ensure server-side user is alive.
   *
   * @param session the user session as { CfUser }.
   */
  sendHeartBeat(session: CfUser) {
    const sessionId = this._security.getCurrentSessionId();
    try {
      if (session.id && sessionId) {
        this._sessionStore.sendSessionHeartBeat$(session.id, sessionId)
          .pipe()
          .subscribe();
      } else {
        console.warn('Cannot send heart beat to ensure if user is alive.');
      }
    } catch (e) {
      console.warn('Call out of context.');
    }
  }

  /**
   * Deactivate user session.
   *
   * @param sessionId the user session id as { string }.
   */
  deactivate(sessionId: string) {
    this._security.getDeviceInformation('user.app_leave').then((deviceInfo) => {
      this._store.dispatch(deactivateSession({ deviceInfo, sessionId }));
    });
  }

  /**
   * Update session.
   *
   * @param session the user session data as { CfUser }.
   */
  setSession(session: CfUser) {
    this._store.dispatch(updateSession({ session }));
  }

  /**
   * @Async method to log out the current user session.
   *
   */
  async logout(session: CfUser) {
    return new Promise<boolean>((resolve) => {
      this._security.getDeviceInformation('user.logout').then((deviceInfo) => {
        this._store.dispatch(logoutSession({ deviceInfo, session }));
        resolve(true);
      });
    });
  }

}
