import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavBarBurgerAreaComponent} from './nav-bar-burger-area.component';
import {IconModule} from "@app-web-central/web/shared/ui/icon";

@NgModule({
  imports: [
    CommonModule,
    IconModule
  ],
  declarations: [
    NavBarBurgerAreaComponent
  ],
  exports: [
    NavBarBurgerAreaComponent
  ],
})
export class NavBarBurgerAreaModule {
}
