import {CfProject, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {loadProjects, loadProjectsSuccess, loadProjectsError, setLoadingProjectsState} from "./projects.actions";

export interface ProjectsState extends GenericState<CfProject[]> { }

const initialState: ProjectsState = {
  data: null,
  error: null,
  status: 'pending'
};

export const projectsFeatureKey = 'projects';

export const projectsReducer = createReducer(
  initialState,
  on(setLoadingProjectsState, (state, { status }) => ({ ...state, status })),
  on(loadProjects, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadProjectsSuccess, (state, { projects }) => ({
    ...state,
    data: projects,
    status: 'success',
    error: null
  })),
  on(loadProjectsError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
