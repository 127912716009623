import {createAction, props} from "@ngrx/store";
import {CfSuite} from "@app-web-central/web/shared/data-access/models";

export const loadSuites = createAction(
  '[Suite List] Load Suites'
);

export const loadSuitesSuccess = createAction(
  '[Suite List] Load Suites Success',
  props<{
    suites: CfSuite[];
  }>()
);

export const loadSuitesError = createAction(
  '[Suite List] Load Suites Error',
  props<{
    error: string;
  }>()
);
