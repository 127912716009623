import {TaskStatus, TaskStatusClassName} from "@app-web-central/web/shared/data-access/models";

export class BadgeItemWithClass {
  value: string;
  className: string;

  constructor(taskStatus: TaskStatus) {
    this.value = `task_detail.status.${taskStatus.toLowerCase()}`;
    this.className = TaskStatusClassName[taskStatus];
  }
}
