import {Injectable} from "@angular/core";
import {CacheStore} from "@app-web-central/web/shared/data-access/store";
import {UsersConfigFacade, UsersFacade} from "@app-web-central/web/user/data-access";
import {SuitesFacade} from "@app-web-central/web/suite/data-access";
import {SessionFacade} from "@app-web-central/web/session/data-access";
import {SettingsFacade} from "@app-web-central/web/settings/data-access";
import {AddressesFacade} from "@app-web-central/web/address/data-access";
import {RecipientsFacade} from "@app-web-central/web/recipient/data-access";
import {NotificationFacade} from "@app-web-central/web/notification/data-access";
import {OrganizationsFacade} from "@app-web-central/web/organization/data-access";
import {InterventionsFacade} from "@app-web-central/web/intervention/data-access";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {WebAdminStore} from "./web-admin.store";
import {RolesFacade} from "@app-web-central/web/role/data-access";
import {HubsFacade} from "@app-web-central/web/hub/data-access";
import {GroupsFacade} from "@app-web-central/web/group/data-access";
import {ScopesFacade} from "@app-web-central/web/scope/data-access";
import {LicensesFacade} from "@app-web-central/web/license/data-access";

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class WebAdminFacade {
  constructor(
    private _adminStore: WebAdminStore,
    private _cacheStore: CacheStore,
    private _hubsFacade: HubsFacade,
    private _usersFacade: UsersFacade,
    private _rolesFacade: RolesFacade,
    private _scopesFacade: ScopesFacade,
    private _groupsFacade: GroupsFacade,
    private _suitesFacade: SuitesFacade,
    private _sessionFacade: SessionFacade,
    private _settingsFacade: SettingsFacade,
    private _licensesFacade: LicensesFacade,
    private _addressesFacade: AddressesFacade,
    private _recipientsFacade: RecipientsFacade,
    private _usersConfigFacade: UsersConfigFacade,
    private _notificationFacade: NotificationFacade,
    private _organizationFacade: OrganizationsFacade,
    private _interventionsFacade: InterventionsFacade
  ) {
    this._adminStore.setWebAdminStateLoading$('loading');
  }

  initApp() {
    this._cacheStore.loadInitialState();
    this._organizationFacade.init();
    this._usersFacade.init();
    this._recipientsFacade.init();
    this._settingsFacade.init();
    this._notificationFacade.init();
    this._interventionsFacade.init();
    this._addressesFacade.init();
    this._suitesFacade.init();
    this._sessionFacade.init();
    this._rolesFacade.init();
    this._usersConfigFacade.init();
    this._hubsFacade.init();
    this._groupsFacade.init();
    this._scopesFacade.init();
    this._licensesFacade.init();

    setTimeout(() => {
      this._adminStore.setWebAdminStateLoading$('success');
      this._sessionFacade.session$
        .pipe(untilDestroyed(this))
        .subscribe((session) => {
          if (session) {
            // this._idleService.init(session);
            this._sessionFacade.activate(session);
          }
        });
    }, 4000);
  }

  isLoading() {
    return this._adminStore.getAppStateLoading$;
  }
}
