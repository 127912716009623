import {CfSuite, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {loadSuites, loadSuitesError, loadSuitesSuccess} from "./suites.actions";

export interface SuitesState extends GenericState<CfSuite[]> { }

const initialState: SuitesState = {
  data: null,
  error: null,
  status: 'pending'
}

export const suitesFeatureKey = 'Suites';

export const suitesReducer = createReducer(
  initialState,
  on(loadSuites, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadSuitesSuccess, (state, { suites }) => ({
    ...state,
    data: suites,
    status: 'success',
    error: null
  })),
  on(loadSuitesError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
)
