import {Injectable} from "@angular/core";
import {InterventionsState} from "./interventions.reducer";
import {select, Store} from "@ngrx/store";
import {loadInterventions} from "./interventions.actions";
import {getInterventions} from "./interventions.selectors";

@Injectable()
export class InterventionsFacade {
  interventions$ = this._store.pipe(select(getInterventions));

  constructor(
    private _store: Store<InterventionsState>
  ) { }

  init() {
    this._store.dispatch(loadInterventions());
  }
}
