import {CfScope, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {loadScopes, loadScopesError, loadScopesSuccess} from "./scopes.actions";

export interface ScopesState extends GenericState<CfScope[]> { }

const initialState: ScopesState = {
  data: null,
  error: null,
  status: 'pending'
}

export const scopesFeatureKey = 'scopes';

export const scopesReducer = createReducer(
  initialState,
  on(loadScopes, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadScopesSuccess, (state, { scopes }) => ({
    ...state,
    data: scopes,
    status: 'success',
    error: null
  })),
  on(loadScopesError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
