import {isSameMonth} from "date-fns";
import {IsToday} from "./week-item";
import {WeekdaysValues} from "./const";

export enum MonthEnum {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL = 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11,
}

export const MonthEnumValues = {
  [MonthEnum.JANUARY]: 'calendar.months.january',
  [MonthEnum.FEBRUARY]: 'calendar.months.february',
  [MonthEnum.MARCH]: 'calendar.months.march',
  [MonthEnum.APRIL]: 'calendar.months.april',
  [MonthEnum.MAY]: 'calendar.months.may',
  [MonthEnum.JUNE]: 'calendar.months.june',
  [MonthEnum.JULY]: 'calendar.months.july',
  [MonthEnum.AUGUST]: 'calendar.months.august',
  [MonthEnum.SEPTEMBER]: 'calendar.months.september',
  [MonthEnum.OCTOBER]: 'calendar.months.october',
  [MonthEnum.NOVEMBER]: 'calendar.months.november',
  [MonthEnum.DECEMBER]: 'calendar.months.december',
} as any;


export function IsSameMonth(leftDate: Date, rightDate: Date): boolean {
  return isSameMonth(leftDate, rightDate);
}

export interface MonthGrid {
  day: number;
  isToday: boolean;
  isInMonth: boolean;
  isSelected: boolean;
  date: Date;
  weekday: string;
}

export class MonthItem {
  constructor(public text: string, public value: number) {}
}

export class MonthGridValue {
  day: number;
  date: Date;
  isInMonth: boolean;
  isSelected: boolean;
  isToday: boolean;
  weekday: string;
  constructor(date: Date, rightDate: Date) {
    const weekDay = date.getDay() == 0 ? 6 : date.getDay()-1;
    this.day = date.getDate();
    this.date = new Date(date);
    this.isToday = IsToday(date);
    this.isInMonth = IsSameMonth(date, rightDate);
    this.isSelected = IsToday(date);
    this.weekday = WeekdaysValues[weekDay];
  }
}
