<ul class="flex items-center space-x-4">
  <li *ngFor="let breadcrumb of breadcrumbs; let idx = index">
    <div class="flex items-center">
      <a [routerLink]="breadcrumb.url"
         (click)="routeToUrl(breadcrumb)"
         class="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700 hover:cursor-pointer">
        {{ breadcrumb.name | translate }}
      </a>
      <svg-icon
        *ngIf="idx !== breadcrumbs.length-1"
        class="text-gray-400 !flex !items-center !flex-shrink-0"
        key="solid/chevron-right">
      </svg-icon>
    </div>
  </li>
</ul>
