<div
  id="dropzone"
  #dropzone
  [style.top.px]="topPlacement"
  [style.left.px]="leftPlacement"
  (click)="stopPopupPropagation($event)"
  (dragenter)="onDragEnter($event)"
  (dragleave)="onDragLeave($event)"
  (dragover)="onDragOver($event)"
  (drop)="onDrop($event)">

  <div class="dropzone-info flex flex-col items-center">
    <svg-icon key="outline/cloud-arrow-up" size="xxl"></svg-icon>
    <p>{{'attachments.dropzone.text' | translate}}</p>
  </div>
</div>
