import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {map, Observable, switchMap} from "rxjs";
import {getSession, SessionsState} from "../sessions";
import {UserApi} from "@app-web-central/web/shared/data-access/cf-api";


@Injectable({ providedIn: 'root' })
export class SessionStore extends ComponentStore<SessionsState> {
  session$ = this._store.pipe(select(getSession));

  languageCode$ = this.session$.pipe(
    map(session => session?.settings.preferredLanguage?.code || 'en')
  );
  shortDate$ = this.session$.pipe(
    map(session => session?.settings.dateFormat)
  );
  fullDate$ = this.session$.pipe(
    map(session => session?.settings.dateFormatFull)
  );
  fTime$ = this.session$.pipe(
    map(session => session?.settings.timeFormat)
  );
  timezone$ = this.session$.pipe(
    map(session => session?.settings.timezone)
  );

  sendSessionHeartBeat$ = (userId: string, sessionId: string): Observable<string> => this.session$.pipe(
    switchMap(() => this._userApi.isAlive(userId, sessionId)
      .pipe(
        map((response) => response.payload)
      )
    )
  );

  constructor(
    private _store: Store,
    private _userApi: UserApi
  ) {
    super(<SessionsState>{});
  }

}
