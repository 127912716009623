import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalFolderShareComponent} from './modal-folder-share.component';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from '@app-web-central/web/shared/ui/button';
import {ModalFolderShareResultComponent} from './modal-folder-share-result/modal-folder-share-result.component';
import {AvatarModule} from "@app-web-central/web/shared/ui/avatar";

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule,
    ReactiveFormsModule,
    AvatarModule
  ],
  declarations: [
    ModalFolderShareComponent,
    ModalFolderShareResultComponent
  ],
  exports: [
    ModalFolderShareComponent,
    ModalFolderShareResultComponent
  ],
})
export class ModalFolderShareModule {
}
