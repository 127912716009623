import {createAction, props} from "@ngrx/store";
import {CfDashboardTemplate} from "@app-web-central/web/shared/data-access/models";

export const loadTemplates = createAction(
  '[Template List] Load Templates'
);

export const loadTemplatesSuccess = createAction(
  '[Template List] Load Templates Success',
  props<{
    templates: CfDashboardTemplate[]
  }>()
);

export const loadTemplatesError = createAction(
  '[Template List] Load Templates Error',
  props<{
    error: string
  }>()
);
