import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HeaderApi} from "@app-web-central/web/shared/data-access/cf-api";
import {HttpClient} from "@angular/common/http";
import {ResponseState} from "@app-web-central/web/shared/data-access/models";

export enum UsageStateEnum {
  SUCCESS = "success",
  ERROR = "error",
}

export interface CfUsageState {
  count: number;
  state: UsageStateEnum;
}

@Injectable({providedIn: 'root'})
export class UsageApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) { }

  /**
   * Get event usage data by params.
   *
   * @param period the date in milliseconds to request on { number }.
   * @param usageType the feature type like EVENT | STORAGE { string }
   */
  get(period: number, usageType: string) {
    return this._http.get<ResponseState<CfUsageState>>(
      `${this._appConfig.usageV1Url}manage/usages/${period}/count/${usageType}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

}
