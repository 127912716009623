<div class="w-full h-full flex flex-col overflow-y-auto">
  <div class="flex items-center text-textDarkest">
    <p class="text-2xl">{{ 'modals.user_status.title' | translate }}</p>
    <div class="flex-auto"></div>
    <as-button
      icon="outline/x-mark"
      iconSize="md"
      (click)="closeModal()"
      className="tertiary">
    </as-button>
  </div>

  <div class="pt-6 p-2">
    <div class="pb-6" *ngIf="statusSelected && statusSelected.status">
      <p class="text-textMedium font-semibold">{{ 'modals.user_status.current_status' | translate }}</p>
      <div class="flex items-center text-textDarkest">
        <span class="mr-2">{{ currentStatus[statusSelected.status]?.emoji }}</span>
        <span>{{ currentStatus[statusSelected.status]?.text | translate }}</span>
        <div class="flex-auto"></div>
        <as-button
          icon="outline/x-mark"
          iconSize="xs"
          (click)="removeStatus()"
          className="tertiary">
        </as-button>
      </div>
    </div>
    <p class="text-textMedium font-semibold">{{ 'modals.user_status.available_statuses' | translate }}</p>
    <div class="py-1" role="none">
      <a
        *ngFor="let status of statuses"
        (click)="onSetUserStatus(status)"
        [ngClass]="status.active ? 'active' : ''"
        routerLinkActive="active"
        class="submenu-item allowed"
        role="button"
      >
        <span class="mr-2">{{ status.emoji }}</span>
        <span>{{ status.value | translate }}</span>
      </a>
    </div>
  </div>

  <div class="mt-5 form-group form-action">
    <as-button
      className="primary mr-2"
      type="submit"
      [disabled]="!statusSelected">
      {{ 'buttons.save' | translate }}
    </as-button>
    <as-button
      className="tertiary"
      (click)="closeModal()">
      {{ 'buttons.cancel' | translate }}
    </as-button>
  </div>

</div>
