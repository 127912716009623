<div [ngClass]="['input-container', containerClassName]">
  <div *ngIf="icon"
       class="input-icon-container ml-2"
       [style.width.px]="iconContainerWidth">
    <svg-icon
      class="!contents"
      [key]="icon"
      [size]="iconSize">
    </svg-icon>
  </div>

  <input [formControl]="control"
         [placeholder]="placeholder"
         [ngClass]="['input', 'form-input']"
         [style.padding-left.px]="icon ? iconContainerWidth : 0"
         [style.padding-right.px]="isShowClearButton ? iconContainerWidth : 0"/>

  <div *ngIf="isShowClearButton"
       class="input-icon-container right"
       [style.width.px]="iconContainerWidth">
    <svg-icon [key]="'times'"
              [size]="'sm'"
              (click)="clear()">
    </svg-icon>
  </div>
</div>
