import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CfProject} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-task-project-select',
  templateUrl: './task-project-select.component.html',
  styleUrls: ['./task-project-select.component.scss'],
})
export class TaskProjectSelectComponent {
  @Input() control!: FormControl;
  @Input() projects!: CfProject[] | null;
  @Output() activeProject = new EventEmitter<CfProject>();

  onChangeProject(value: {activatedValue: CfProject}) {
    this.activeProject.emit(value.activatedValue);
  }
}
