import {CfTeam, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {loadGroups, loadGroupsError, loadGroupsSuccess} from "./groups.actions";

export interface GroupsState extends GenericState<CfTeam[]> { }

const initialState: GroupsState = {
  data: null,
  error: null,
  status: 'pending'
}

export const groupsFeatureKey = 'groups';

export const groupsReducer = createReducer(
  initialState,
  on(loadGroups, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadGroupsSuccess, (state, { groups }) => ({
    ...state,
    data: groups,
    status: 'success',
    error: null
  })),
  on(loadGroupsError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
