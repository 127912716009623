<div class="mt-3">
  <h2 class="pb-2 text-sm font-medium text-gray-900">{{ 'task_detail.description' | translate }}</h2>

  <quill-editor
    [disabled]="!!task.dates?.validationDate"
    [readOnly]="!!task.dates?.validationDate"
    class="bg-gray-50 content-editor"
    [modules]="editorOptions"
    [formControl]="control">
  </quill-editor>
</div>
