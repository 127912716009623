import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import * as dateFns from 'date-fns';
import {TaskStatus} from "@app-web-central/web/shared/data-access/models";

export interface GenericFilterState {
  searchTerm: string;
  userIds: string[];
  onlyMyTasks: boolean;
  ignoreResolved: boolean;
  rangeSelected: boolean;
  day: number[];
  week: number[];
  month: number[];
  year: number[];
  customRange: number[];
  inProgress: TaskStatus | null;
  unassigned: TaskStatus | null;
  isToday: Date | null;
  isWeek: Date | null;
  next7Days: boolean;
  isAll: boolean;
  highPriority: boolean;
  authorName: string | null;
  tag: string | null;
  status: TaskStatus | null;
  sortBy: string | null;
  dates: number[] | null;
}

interface FilterState {
  filter: GenericFilterState;
}

const initialState = {
  searchTerm: '',
  userIds: [],
  onlyMyTasks: false,
  ignoreResolved: false,
  rangeSelected: false,
  day: [],
  week: [],
  month: [],
  year: [],
  customRange: [],
  inProgress: null,
  unassigned: null,
  isToday: null,
  isWeek: null,
  next7Days: false,
  isAll: false,
  highPriority: false,
  authorName: null,
  tag: null,
  status: null,
  sortBy: null,
  dates: null,
}

@Injectable({ providedIn: 'root' })
export class FilterStore extends ComponentStore<FilterState> {
  any$ = this.select(({ filter }) =>
    !!filter.searchTerm || !!filter.userIds?.length || filter.onlyMyTasks || filter.ignoreResolved || filter.rangeSelected
  );

  readonly all$ = this.select(({ filter }) => filter);
  readonly userIds$ = this.select(({ filter }) => filter.userIds);
  readonly onlyMyTasks$ = this.select(({ filter }) => filter.onlyMyTasks);
  readonly ignoreResolved$ = this.select(({ filter }) => filter.ignoreResolved);
  readonly rangeSelected$ = this.select(({ filter }) => filter.rangeSelected);

  updateSearchTerm(searchTerm: string) {
    this.patchState((state) => ({
      filter: { ...state.filter, isAll: searchTerm === '', searchTerm }
    }));
  }

  readonly toggleUserId = this.updater((state, userId: string) => {
    const hasUser = state.filter.userIds.includes(userId);
    const userIds = hasUser
      ? state.filter.userIds.filter((x) => x !== userId)
      : [...state.filter.userIds, userId];
    return {
      filter:
        {
          ...state.filter,
          userIds
        }
      }
  });

  readonly toggleOnlyMyTasks = this.updater((state) => ({
    filter: { ...state.filter, onlyMyTasks: !state.filter.onlyMyTasks }
  }));

  readonly toggleIgnoreResolved = this.updater((state) => ({
    filter: { ...state.filter, ignoreResolved: !state.filter.ignoreResolved }
  }));

  readonly toggleRangeSelected = this.updater((state) => ({
    filter: { ...state.filter, rangeSelected: true }
  }));

  readonly toggleInProgress = this.updater((state) => ({
    filter: ({ ...initialState, userIds: state.filter.userIds, inProgress: TaskStatus.IN_TRANSIT })
  }));

  readonly toggleUnassigned = this.updater((state) => ({
    filter: ({ ...initialState, userIds: state.filter.userIds, unassigned: TaskStatus.UNASSIGNED })
  }));

  readonly toggleIsToday = this.updater((state) => ({
    filter: ({ ...initialState, userIds: state.filter.userIds, isToday: new Date() })
  }));

  readonly toggleInWeek = this.updater((state) => ({
    filter: ({ ...initialState, userIds: state.filter.userIds, isWeek: new Date() })
  }));

  readonly toggleNext7Days = this.updater((state) => ({
    filter: ({ ...initialState, userIds: state.filter.userIds, next7Days: true })
  }));

  readonly toggleIsAll = this.updater((state) => ({
    filter: ({
      ...initialState,
      userIds: state.filter.userIds,
      isAll: state.filter.isAll,
      sortBy: state.filter.sortBy,
      dates: state.filter.dates
    })
  }));

  readonly toggleHighPriority = this.updater((state) => ({
    filter: ({ ...initialState, userIds: state.filter.userIds, highPriority: true })
  }));

  readonly toggleAuthorName = this.updater((state, authorName: string | null) => ({
    filter: ({ ...state.filter, isAll: authorName === null, authorName })
  }));

  readonly toggleTags = this.updater((state, tag: string | null) => ({
    filter: ({ ...state.filter, tag })
  }));

  readonly toggleEventStatus = this.updater((state, status: TaskStatus | null) => ({
    filter: ({ ...state.filter, isAll: status === null, status })
  }));

  readonly toggleSortEvents = this.updater((state, sortBy: string | null) => ({
    filter: ({ ...state.filter, sortBy })
  }));

  readonly toggleSortEventsForDates = this.updater((state, dates: number[] | null) => ({
    filter: ({ ...state.filter, sortBy: 'absolute_dates', dates })
  }));

  resetAll() {
    this.setState({ filter: initialState });
  }

  readonly addDayDates = this.updater((state, now: Date) => {
    const start = dateFns.set(now, { hours: 0, minutes: 0, seconds: 0 });
    const end = dateFns.addDays(start, 1);
    return {
      filter:
        {
          ...state.filter,
          day: [start.getTime(), end.getTime()], week: [], month: [], year: [], customRange: []
        }
    }
  });

  readonly addWeekDates = this.updater((state, now: Date) => {
    const newNow = dateFns.set(now, { hours: 0, minutes: 0, seconds: 0 });
    const wStart = dateFns.startOfWeek(newNow, { weekStartsOn: 1 });
    const wEnd = dateFns.endOfWeek(newNow, { weekStartsOn: 1 });
    return {
      filter:
        {
          ...state.filter,
          day: [], week: [wStart.getTime(), wEnd.getTime()], month: [], year: [], customRange: []
        }
    }
  });

  readonly addMonthDates = this.updater((state, now: Date) => {
    const newNow = dateFns.set(now, { hours: 0, minutes: 0, seconds: 0 });
    const start = dateFns.startOfMonth(newNow);
    const end = dateFns.endOfMonth(newNow);
    return {
      filter:
        {
          ...state.filter,
          day: [], week: [], month: [start.getTime(), end.getTime()], year: [], customRange: []
        }
    }
  });

  readonly addYearDates = this.updater((state, now: Date) => {
    const newNow = dateFns.set(now, { hours: 0, minutes: 0, seconds: 0 });
    const start = dateFns.startOfYear(newNow);
    const end = dateFns.endOfYear(newNow);
    return {
      filter:
        {
          ...state.filter,
          day: [], week: [], month: [], year: [start.getTime(), end.getTime()], customRange: []
        }
    }
  });

  readonly addCustomDates = this.updater((state, range: Date[]) => {
    const start = dateFns.startOfDay(range[0]);
    const end = dateFns.endOfDay(range[1]);
    return {
      filter:
        {
          ...state.filter,
          day: [], week: [], month: [], year: [], customRange: [start.getTime(), end.getTime()]
        }
    }
  });

  // Initial state for filters.
  constructor() {
    super({ filter: initialState });
  }
}
