import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {HeaderApi} from "./header-api";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {CfCountry, CfLanguage} from "@app-web-central/web/shared/data-access/models";
import {ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({providedIn: 'root'})
export class ConfigApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) {}

  fetchCountries = () => this._http.get<ResponseState<CfCountry[]>>(
    `${this._appConfig.generalConfigV1Url}manage/countries`, {
      headers: this._header.getServiceHeader(),
      withCredentials: true
    }
  );

  fetchLanguages = () => this._http.get<ResponseState<CfLanguage[]>>(
    `${this._appConfig.generalConfigV1Url}manage/languages`, {
      headers: this._header.getServiceHeader(),
      withCredentials: true
    }
  );

}
