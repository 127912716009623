import {NzTableFilterFn, NzTableSortFn} from "ng-zorro-antd/table";

export interface ColumnItem<T> {
  name: string;
  sortFn: NzTableSortFn<T> | null;
  filterFn: NzTableFilterFn<T> | boolean | null;
  filtered?: boolean;
}

export class ColumnItemBuilder<T> {
  constructor(
    public name: string,
    public sortFn: NzTableSortFn<T> | null,
    public filterFn: NzTableFilterFn<T> | boolean | null,
    public filtered?: boolean
  ) {}
}
