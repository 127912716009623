import {Injectable} from "@angular/core";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {TranslateService} from "@ngx-translate/core";

export const SUCCESS = 'success';
export const ERROR = 'error';

@Injectable({ providedIn: 'root' })
export class LocalNotificationService {
  constructor(
    private _translate: TranslateService,
    private _notificationService: NzNotificationService
  ) { }

  success(title: string, message: string) {
    this._notificationService.create(
      SUCCESS,
      this._translate.instant(title),
      this._translate.instant(message)
    );
  }

  error(title: string, message: string) {
    this._notificationService.create(
      ERROR,
      this._translate.instant(title),
      this._translate.instant(message)
    );
  }

}
