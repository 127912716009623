import {Injectable} from "@angular/core";
import {UsersFacade} from "@app-web-central/web/user/data-access";
import {SettingsFacade} from "@app-web-central/web/settings/data-access";
import {SessionFacade} from "@app-web-central/web/session/data-access";
import {AddressesFacade} from "@app-web-central/web/address/data-access";
import {RecipientsFacade} from "@app-web-central/web/recipient/data-access";
import {NotificationFacade} from "@app-web-central/web/notification/data-access";
import {InterventionsFacade} from "@app-web-central/web/intervention/data-access";
import {OrganizationsFacade} from "@app-web-central/web/organization/data-access";
import {TasksFacade} from "@app-web-central/web/task/data-access";
import {ChannelsFacade} from "@app-web-central/web/channel/data-access";
import {CacheStore} from "../cache";
import {InitializeStore} from "./initialize.store";
import {ProjectsFacade} from "@app-web-central/web/project/data-access";
import {SuitesFacade} from "@app-web-central/web/suite/data-access";
import {FoldersFacade} from "@app-web-central/web/folder/data-access";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {TemplatesFacade} from "@app-web-central/web/dashboard/data-access";

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class InitializeFacade {
  constructor(
    private _appStore: InitializeStore,
    private _cacheStore: CacheStore,
    private _usersFacade: UsersFacade,
    private _tasksFacade: TasksFacade,
    private _suitesFacade: SuitesFacade,
    private _foldersFacade: FoldersFacade,
    private _sessionFacade: SessionFacade,
    private _projectsFacade: ProjectsFacade,
    private _channelsFacade: ChannelsFacade,
    private _settingsFacade: SettingsFacade,
    private _addressesFacade: AddressesFacade,
    private _templatesFacade: TemplatesFacade,
    private _recipientsFacade: RecipientsFacade,
    private _notificationFacade: NotificationFacade,
    private _organizationFacade: OrganizationsFacade,
    private _interventionsFacade: InterventionsFacade
  ) {
    this._appStore.setAppStateLoading$('loading');
  }

  initApp() {
    this._cacheStore.loadInitialState();
    this._organizationFacade.init();
    this._projectsFacade.init();
    this._channelsFacade.init();
    this._usersFacade.init();
    this._foldersFacade.init();
    this._recipientsFacade.init();
    this._settingsFacade.init();
    this._notificationFacade.init();
    this._interventionsFacade.init();
    this._addressesFacade.init();
    this._suitesFacade.init();
    this._sessionFacade.init();
    this._tasksFacade.init();
    this._templatesFacade.init();

    setTimeout(() => {
      this._appStore.setAppStateLoading$('success');
      this._sessionFacade.session$
        .pipe(untilDestroyed(this))
        .subscribe((session) => {
          if (session) {
            // this._idleService.init(session);
            this._sessionFacade.activate(session);
          }
        });
    }, 4000);
  }

  isLoading() {
    return this._appStore.getAppStateLoading$;
  }

}
