import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
  CfProject,
  CfTaskMetadata,
  quillConfiguration,
  TaskStatus
} from "@app-web-central/web/shared/data-access/models";
import {SessionStore} from "@app-web-central/web/session/data-access";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {getRecipients, RecipientStore} from "@app-web-central/web/recipient/data-access";
import {AddressesFacade} from "@app-web-central/web/address/data-access";
import {createTask, TasksState} from "@app-web-central/web/task/data-access";
import {ProjectStore} from "@app-web-central/web/project/data-access";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {UsersFacade} from "@app-web-central/web/user/data-access";

@UntilDestroy()
@Component({
  selector: 'as-add-task-modal',
  templateUrl: './add-task-modal.component.html',
  styleUrls: ['./add-task-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RecipientStore]
})
export class AddTaskModalComponent implements OnInit {
  @Input() project$!: Observable<CfProject | null>;
  readonly session$ = this._sessionStore.session$;
  readonly recipients$ = this._recipientStore.select(getRecipients);
  reporters$ = this._usersFacade.usersEnabled$;
  assignees$ = this._usersFacade.usersEnabledAndAgent$;
  readonly addresses$ = this._addressFacade.addressesEnabled$;
  readonly projects$ = this._projectStore.projectsFilteredByUserAccess$();
  openMetadataForm = false;
  editLastMeta!: CfTaskMetadata | null;

  taskForm!: FormGroup;
  editorOptions = quillConfiguration;

  f(field: string) {
    return this.taskForm.get(field) as FormControl;
  }

  constructor(
    private store: Store<TasksState>,
    private _sessionStore: SessionStore,
    private _projectStore: ProjectStore,
    private _usersFacade: UsersFacade,
    private _addressFacade: AddressesFacade,
    private _recipientStore: RecipientStore,
    private _modalRef: NzModalRef,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._initForm();
    this._setSessionIds();
  }

  closeModal() {
    this._modalRef.close();
  }

  cancel() {
    this.taskForm.reset();
    this.closeModal();
  }

  onChangeProject(project: CfProject) {
    this.reporters$ = this._projectStore.usersEnabledByProject$(project.id);
    this.assignees$ = this._projectStore.usersEnabledAgentByProject$(project.id);
  }

  onOpenMetadataForm() {
    this.openMetadataForm = !this.openMetadataForm;
  }

  onCancelMetadataForm() {
    this._resetMetadata();
  }

  onAddMetadata(formData: CfTaskMetadata) {
    if (formData) {
      const formValue = this.taskForm.getRawValue();
      const metadata = formValue.metadata && formValue.metadata.length
        ? [ ...formValue.metadata ]
        : [];
      if (this.editLastMeta && metadata.some((y) =>
        y.key.trim().toLowerCase() === this.editLastMeta?.key.trim().toLowerCase())
      ) {
        const index = metadata.findIndex((x) =>
          x.key.trim().toLowerCase() === this.editLastMeta?.key.trim().toLowerCase());
        metadata[index] = { ...formData };
      } else {
        metadata.push(formData)
      }
      this.taskForm.controls['metadata'].setValue(metadata);
    }
    this._resetMetadata();
  }

  onRemoveMetadata(meta: CfTaskMetadata) {
    const formValue = this.taskForm.getRawValue();
    let metadata = [ ...formValue.metadata ];
    const index = metadata.findIndex((x) =>
      x.key.trim().toLowerCase() === meta.key.trim().toLowerCase());
    if (index) {
      metadata.splice(index, 1);
      this.taskForm.controls['metadata'].setValue(metadata);
    } else if (index === 0) {
      if (metadata.length > 1) {
        metadata.splice(index, 1);
      } else {
        metadata = [];
        this.taskForm.controls['metadata'].setValue(metadata);
      }
    }
  }

  onEditMetadata(meta: CfTaskMetadata) {
    this.editLastMeta = meta;
    this.openMetadataForm = !this.openMetadataForm;
    this.taskForm.controls['metadataKey'].setValue(meta.key);
    this.taskForm.controls['metadataValue'].setValue(meta.value);
  }

  submit() {
    if (this.taskForm.invalid) {
      return;
    }

    const formValue = this.taskForm.getRawValue();
    formValue["projectId"] = formValue.project.id;
    if (formValue.deliveryDate) {
      formValue["dates"] = { startDate: formValue.deliveryDate.getTime() };
      delete formValue["deliveryDate"];
    }
    delete formValue["project"];
    delete formValue["metadataKey"];
    delete formValue["metadataValue"];
    this.store.dispatch(createTask({ task: formValue }));
    this.closeModal();
  }

  private _resetMetadata() {
    this.editLastMeta = null;
    this.taskForm.controls['metadataKey'].setValue(null);
    this.taskForm.controls['metadataValue'].setValue(null);
    this.openMetadataForm = !this.openMetadataForm;
  }

  private _setSessionIds() {
    this.session$.pipe(untilDestroyed(this)).subscribe(session => {
      if (session) {
        this.taskForm.controls['createdBy'].setValue(session);
        this.taskForm.controls['modifiedBy'].setValue(session);
      }
    });
  }

  private _initForm() {
    this.taskForm = this._formBuilder.group({
      name: [null, [Validators.required, Validators.minLength(6)]],
      description: [null, [Validators.maxLength(2048)]],
      project: [null, [Validators.required]],
      recipient: [null],
      status: [TaskStatus.UNASSIGNED, [Validators.required]],
      assignee: [null],
      deliveryDate: [null],
      createdBy: [null, [Validators.required]],
      modifiedBy: [null, [Validators.required]],
      address: [null, [Validators.required]],
      suite: [null],
      enabled: [true],
      metadata: [null],
      metadataKey: [null],
      metadataValue: [null],
    });
  }

}
