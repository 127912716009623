import {CfSuite} from "@app-web-central/web/shared/data-access/models";

export class UnitUtil {
  static replaceSuiteAndGetNewSuites(suites: CfSuite[] | null, suite: CfSuite) {
    const newSuites = suites !== null ? [...suites] : [];
    const index = newSuites.findIndex((x) => x.id === suite.id);
    if (index === -1) {
      newSuites.push(suite);
    } else {
      newSuites[index] = suite;
    }
    return newSuites;
  }

  static removeSuiteFromSuites(suites: CfSuite[] | null, suite: CfSuite) {
    const newSuites = suites !== null ? [...suites] : [];
    const index = newSuites.findIndex((x) => x.id === suite.id);
    newSuites.splice(index, 1);
    return newSuites;
  }
}
