import {Injectable} from "@angular/core";

const APP_PREFIX = 'CF-';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {

  /**
   * Sets item in local storage
   *
   * @param {string} key
   * @param {*} value
   */
  public setItem(key: string, value: any) {
    try {
      localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
    } catch (e) {
      localStorage.setItem(`${APP_PREFIX}${key}`, value);
    }
  }

  /**
   * Gets item from local storage by key
   *
   * @param {string} key
   * @return {*}  {*}
   */
  public getItem(key: string): any {
    const value = localStorage.getItem(`${APP_PREFIX}${key}`);
    try {
      return JSON.parse(value as any);
    } catch (e) {
      return value;
    }
  }

  /**
   * Removes item from local storage by key
   *
   * @param {string} key
   */
  public removeItem(key: string) {
    localStorage.removeItem(`${APP_PREFIX}${key}`);
  }

}
