import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {loadHubs} from "./hubs.actions";
import {HubsState} from "./hubs.reducer";
import {getHubs} from "./hubs.selectors";

@Injectable({ providedIn: 'root' })
export class HubsFacade {
  readonly hubs$ = this._store.pipe(select(getHubs));

  constructor(
    private _store: Store<HubsState>
  ) { }

  init() {
    this._store.dispatch(loadHubs());
  }

}
