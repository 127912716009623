import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {SessionStore} from "@app-web-central/web/session/data-access";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {CfScope} from "@app-web-central/web/shared/data-access/models";

@UntilDestroy()
@Directive({
  selector: '[asHasPermission]'
})
export class HasPermissionDirective implements OnInit {
  @Input() public asHasPermission: Array<string> = [];
  readonly session$ = this._store.session$;
  isVisible = false;

  constructor(
    private _store: SessionStore,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.session$.pipe(untilDestroyed(this)).subscribe(session => {
      if (session) {
        const scopes = session.roles[0].scopes;
        if (scopes.length < 1) {
          this.isVisible = false;
          this.viewContainerRef.clear();
        }

        const found = scopes.some((r: CfScope) => this.asHasPermission.includes(r.scopeKey));

        if (found) {
          if (!this.isVisible) {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
          this.isVisible = false;
          this.viewContainerRef.clear();
        }

      }
    });
  }

}
