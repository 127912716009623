import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CfTask, TaskType} from "@app-web-central/web/shared/data-access/models";
import {TaskUtil} from "@app-web-central/web/shared/utils";

@Component({
  selector: 'as-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultComponent {
  @Input() task!: CfTask;
  get issueTypeIcon() {
    return TaskUtil.getTaskTypeIcon(TaskType.TASK);
  }
}
