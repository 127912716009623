import {Injectable} from "@angular/core";
import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {CfAddress, GenericState} from "@app-web-central/web/shared/data-access/models";
import {select, Store} from "@ngrx/store";
import {AddressUtil, SelectorUtil} from "@app-web-central/web/shared/utils";
import {filter, map, Observable, switchMap, tap, withLatestFrom} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {AddressApi} from "@app-web-central/web/shared/data-access/cf-api";
import {AddressesState, getAddresses, loadAddressesSuccess} from "../addresses";

interface AddressState extends GenericState<CfAddress> {
  addressId: string;
}

@Injectable()
export class AddressStore extends ComponentStore<AddressState> {
  addresses$ = this._store.pipe(select(getAddresses));
  isAddressLoading$ = this.select(SelectorUtil.isLoading);

  addressIdParams$: Observable<string> = this._route.params.pipe(
    map((params) => params["addressId"]),
    filter((addressId: string) => !!addressId)
  );

  address$ = this.addressIdParams$.pipe(
    tap((addressId) => {
      this.patchState({
        addressId
      });
      this.loadAddress({ addressId });
    }),
    switchMap(() => this.select((s) => s.data))
  );

  loadAddress = this.effect<{ addressId: string }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({ addressId }) =>
        this._addressApi.get(addressId).pipe(
          tapResponse(
            (response) => {
              const address = { ...response.payload };
              this.patchState({
                data: address,
                status: 'success',
                error: ''
              });
            },
            (error) => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }
          )
        )
      )
    )
  );

  update = this.effect<CfAddress>((params$) => (
      params$.pipe(
        withLatestFrom(this.addresses$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([address, addresses]) =>
          this._addressApi.update(address.id, address)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'save');
                  const newAddresses = addresses !== null ? [...addresses] : [];
                  const index = newAddresses.findIndex((item) => item.id === response.payload.id);
                  newAddresses[index] = {...response.payload};
                  this._store.dispatch(
                    loadAddressesSuccess({
                      addresses: newAddresses
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'save');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  create = this.effect<CfAddress>((params$) => (
      params$.pipe(
        withLatestFrom(this.addresses$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([address, addresses]) =>
          this._addressApi.create(address)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'create');
                  const newAddresses = addresses !== null ? [...addresses] : [];
                  newAddresses.push(response.payload);
                  this._store.dispatch(
                    loadAddressesSuccess({
                      addresses: newAddresses
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'create');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  delete = this.effect<{ address: CfAddress }>((params$) => (
      params$.pipe(
        withLatestFrom(this.addresses$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ address }, addresses]) =>
          this._addressApi.delete(address.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'delete');
                  const newAddresses = AddressUtil.removeAddressFromAddresses(addresses, address);
                  this._store.dispatch(
                    loadAddressesSuccess({
                      addresses: newAddresses
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'delete');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  enable = this.effect<{ address: CfAddress }>((params$) => (
      params$.pipe(
        withLatestFrom(this.addresses$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ address }, addresses]) =>
          this._addressApi.enable(address.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'enable');
                  const newAddresses = AddressUtil.replaceAddressAndGetNewAddresses(addresses, response.payload);
                  this._store.dispatch(
                    loadAddressesSuccess({
                      addresses: newAddresses
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'enable');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  disable = this.effect<{ address: CfAddress }>((params$) => (
      params$.pipe(
        withLatestFrom(this.addresses$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ address }, addresses]) =>
          this._addressApi.disable(address.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'disable');
                  const newAddresses = AddressUtil.replaceAddressAndGetNewAddresses(addresses, response.payload);
                  this._store.dispatch(
                    loadAddressesSuccess({
                      addresses: newAddresses
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'disable');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  private _notify(state: string, action: string) {
    this._notification.create(
      state,
      this._translate.instant(`notifications.${state}.address.${action}.title`),
      this._translate.instant(`notifications.${state}.address.${action}.subtitle`)
    );
  }

  constructor(
    private _addressApi: AddressApi,
    private _route: ActivatedRoute,
    private _store: Store<AddressesState>,
    private _translate: TranslateService,
    private _notification: NzNotificationService
  ) {
    super(<AddressState>{});
  }
}
