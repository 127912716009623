import {Injectable} from "@angular/core";
import {ModalRecurrenceEditComponent} from "@app-web-central/web/shell/ui/modal-recurrence-edit";
import {NzModalService} from "ng-zorro-antd/modal";

@Injectable({ providedIn: 'root'})
export class TasksService {
  constructor(
    private _modalService: NzModalService
  ) { }

  confirmEditRecurringTask() {
    return this._modalService.create({
      nzContent: ModalRecurrenceEditComponent,
      nzClosable: false,
      nzFooter: null,
      nzWidth: 400
    });
  }

}
