import {createAction, props} from "@ngrx/store";
import {CfHub} from "@app-web-central/web/shared/data-access/models";

export const loadHubs = createAction(
  '[Hub List] Load Hubs'
);

export const loadHubsSuccess = createAction(
  '[Hub List] Load Hubs Success',
  props<{
    hubs: CfHub[]
  }>()
);

export const loadHubsError = createAction(
  '[Hub List] Load Hubs Error',
  props<{
    error: string
  }>()
);
