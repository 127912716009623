import {Injectable} from "@angular/core";
import {AddressesState, getAddresses, loadAddresses} from "@app-web-central/web/address/data-access";
import {select, Store} from "@ngrx/store";
import {map} from "rxjs";

@Injectable({ providedIn: 'root' })
export class AddressesFacade {
  readonly addresses$ = this._store.pipe(select(getAddresses));
  readonly addressesEnabled$ = this.addresses$.pipe(
    map(addresses => addresses?.filter(address => address.enabled))
  );

  constructor(
    private _store: Store<AddressesState>
  ) { }

  init() {
    this._store.dispatch(loadAddresses());
  }

}
