import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'as-nav-bar-vertical',
  templateUrl: './nav-bar-vertical.component.html',
  styleUrls: ['./nav-bar-vertical.component.scss'],
})
export class NavBarVerticalComponent implements OnInit {
  items!: LegalNavItem[];
  ngOnInit(): void {
    this.items = [
      new LegalNavItem('tooltips.terms', 'legal/terms-of-use', ''),
      new LegalNavItem('tooltips.privacy', 'legal/privacy-policy', ''),
      new LegalNavItem('tooltips.privacy_choice', 'legal/privacy-choices', ''),
      new LegalNavItem('tooltips.cookie', 'legal/cookie-policy', ''),
      new LegalNavItem('tooltips.eula', 'legal/eula', ''),
    ]
  }
}

class LegalNavItem {
  label: string;
  url: string;
  icon: string;
  constructor(label: string, url: string, icon: string) {
    this.label = label;
    this.url = url;
    this.icon = icon;
  }
}
