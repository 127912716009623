<div class="border border-gray-200 rounded-md divide-y divide-gray-200"
     *ngIf="control.value && control.value.length">
  <ng-container *ngFor="let data of control.value">
    <div class="grid grid-cols-1 lg:grid-cols-12 gap-3">
      <div class="col-span-4 px-2 flex items-center">
        <p class="font-semibold text-gray-700">{{ data.key }}</p>
      </div>
      <div class="col-span-5 px-2 flex items-center">
        <p class="text-gray-700">{{ data.value }}</p>
      </div>
      <div class="col-span-3 flex items-center justify-end">
        <as-button
          class="ml-1.5"
          (click)="onEditMetadata(data)"
          className="tertiary"
          iconSize="xs"
          icon="outline/pencil">
        </as-button>
        <as-button
          class="ml-1.5"
          (click)="onRemoveMetadata(data)"
          className="tertiary"
          iconSize="xs"
          icon="outline/trash">
        </as-button>
      </div>
    </div>
  </ng-container>
</div>
