import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CfAddress} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-task-address-select',
  templateUrl: './task-address-select.component.html',
  styleUrls: ['./task-address-select.component.scss'],
})
export class TaskAddressSelectComponent {
  @Input() control!: FormControl;
  @Input() addresses!: CfAddress[];
}
