import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BreadcrumbsComponent} from './breadcrumbs.component';
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {IconModule} from "@app-web-central/web/shared/ui/icon";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    IconModule
  ],
  declarations: [
    BreadcrumbsComponent
  ],
  exports: [
    BreadcrumbsComponent
  ],
})
export class BreadcrumbsModule {
}
