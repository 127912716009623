export class TableUtil {
  static Configuration = {
    Events: 'EVENT_LIST',
    Recent: 'RECENT_LIST',
    Projects: 'PROJECT_LIST',
    ProjectsAccess: 'PROJECT_ACCESS',
    ProjectsPendingInvitation: 'PROJECT_PENDING_INVITATION',
    Files: 'FILES_LIST',
    Folders: 'FOLDERS_LIST',
  };
}
