import {CfLicense, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {loadLicenses, loadLicensesError, loadLicensesSuccess} from "./licenses.actions";

export interface LicensesState extends GenericState<CfLicense[]> { }

const initialState: LicensesState = {
  data: null,
  error: null,
  status: 'pending'
}

export const licensesFeatureKey = 'licenses';

export const licensesReducer = createReducer(
  initialState,
  on(loadLicenses, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadLicensesSuccess, (state, { licenses }) => ({
    ...state,
    data: licenses,
    status: 'success',
    error: null
  })),
  on(loadLicensesError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
