import {ComponentStore} from "@ngrx/component-store";
import {NavItemWithIconAndUrl} from "@app-web-central/web/shared/data-access/models";
import {Injectable} from "@angular/core";
import {RouteUtil} from "@app-web-central/web/shared/utils";

interface NavBarLeftState {
  items: NavItemWithIconAndUrl[]
}

const initialState = [
  new NavItemWithIconAndUrl('constants.home', 'outline/home', RouteUtil.getHomeRouteUrl()),
  new NavItemWithIconAndUrl('constants.projects', 'outline/collection', RouteUtil.getProjectsRouteUrl()),
  new NavItemWithIconAndUrl('constants.box', 'outline/folder', RouteUtil.getStorageRouteUrl()),
  // new NavItemWithIconAndUrl('constants.map', 'outline/map', RouteUtil.getMapRouteUrl()),
  new NavItemWithIconAndUrl('constants.settings', 'outline/cog', RouteUtil.getAdminRouteUrl(), true),
];

@Injectable({ providedIn: 'root' })
export class NavBarLeftStore extends ComponentStore<NavBarLeftState> {
  leftBarMenuItems$ = this.select(({ items }) => items);
  constructor() {
    super(<NavBarLeftState>{ items: initialState });
  }
}
