import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'as-combobox',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.scss'],
})
export class ComboboxComponent implements OnInit {
  @Input() label!: string;
  @Input() items!: any[] | null;
  @Input() props!: string[];
  @Input() searchProp!: string;
  @Input() placeholder!: string;
  @Input() selectedItem!: any[] | any | null;
  @Input() disabled = false;
  @Input() removable = true;
  @Input() multipleSelect = false;
  @Output() activeItem = new EventEmitter<any | null>();
  visible!: boolean;
  search!: string;
  searchSubProp!: string;

  ngOnInit(): void {
    if (Array.isArray(this.selectedItem)) {
      this.visible = !!this.selectedItem.length;
    } else {
      this.visible = this.selectedItem !== null;
    }
  }

  getIsVisible() {
    return this.visible ? 'visible' : '';
  }

  onBlur() {
    this.visible = this.selectedItem !== null;
    this.search = '';
  }

  handleSelectItem(item: any) {
    this.search = '';
    this.activeItem.emit(item);
    this.visible = true;
  }

  handleRemoveItem() {
    this.search = '';
    this.visible = false;
    this.activeItem.emit(null);
  }

}
