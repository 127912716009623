import {Component, Input} from '@angular/core';
import {CfComment, CfTask, CfUser} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-task-drawer-comments',
  templateUrl: './task-drawer-comments.component.html',
  styleUrls: ['./task-drawer-comments.component.scss'],
})
export class TaskDrawerCommentsComponent {
  @Input() task!: CfTask;
  @Input() session!: CfUser;

  editionMode = false;
  editComment!: CfComment;
  replyComment!: CfComment;

  onEditComment(event: any) {
    if (!event.comment) {
      return;
    }
    this.editionMode = event.editMode;
    this.editComment = {...event.comment};
  }

  onReplyComment(event: any) {
    if (!event.comment) {
      return;
    }
    this.editionMode = event.editMode;
    this.replyComment = {...event.comment};
  }
}
