<div class="project-members-modal">
  <div class="flex items-center pb-3">
    <div class="text-left">
      <h2 class="title">{{ 'modals.share.title' | translate: {param: folder.name.value} }}</h2>
      <p class="subtitle">{{ 'modals.share.subtitle' | translate }}</p>
    </div>
    <div class="flex-auto"></div>
    <as-button
      icon="outline/x-mark"
      iconSize="md"
      (click)="closeModal()"
      className="tertiary">
    </as-button>
  </div>

  <form class="w-full pt-2 p-4 flex flex-col">
    <input
      type="email"
      [formControl]="control"
      class="form-input"
      placeholder="{{ 'modals.share.form.email_placeholder' | translate }}"/>

    <ng-container *ngIf="hasSearchTermInput">
      <ng-container *ngIf="results$ | async as results">
        <ng-container *ngIf="results?.length; else noResultTmpl">
          <div class="added-list mt-10">
            <h3 class="previously-added">{{ 'drawers.search.search_result' | translate }}</h3>
            <ul role="list">
              <as-modal-folder-share-result
                [users]="results"
                [folderId]="folder.name.id"
                [session]="session"
                [organization]="organization"
                [endSlice]="5">
              </as-modal-folder-share-result>
            </ul>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </form>

</div>


<ng-template #noResultTmpl>
  <div class="pt-10 flex flex-col justify-center items-center">
    <div class="border-l-4 border-yellow-400 bg-yellow-50 rounded-md p-3">
      <div class="pt-2 text-15 text-center text-yellow-800">
        {{ 'drawers.search.no_result' | translate }}
      </div>
      <div class="pt-2 text-15 text-center text-yellow-800">{{ 'drawers.search.try_again' | translate }}</div>
    </div>
  </div>
</ng-template>
