import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {RolesState} from "./roles.reducer";
import {getRoles} from "./roles.selectors";
import {loadRoles} from "./roles.actions";

@Injectable({ providedIn: 'root' })
export class RolesFacade {
  roles$ = this._store.pipe(select(getRoles));

  constructor(
    private _store: Store<RolesState>
  ) { }

  init() {
    this._store.dispatch(loadRoles());
  }
}
