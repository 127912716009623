import {
  CfReminder,
  CfUserNotification,
  GenericStoreStatus
} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {
  createReminder,
  createReminderError,
  createReminderSuccess,
  loadMore10Notifications,
  loadMore10NotificationsError,
  loadMore10NotificationsSuccess,
  loadNotifications,
  loadNotificationsError,
  loadNotificationsSuccess,
  readOneNotification,
  readOneNotificationError,
  readOneNotificationSuccess,
  updateReminder,
  updateReminderError,
  updateReminderSuccess
} from "./notification.actions";

export const NOTIFICATIONS_FEATURE_KEY = 'notifications';

export interface NotificationsState {
  notifications: CfUserNotification[] | [] | null,
  reminder: CfReminder | null,
  error: string | null,
  status: GenericStoreStatus
}

export const initialState = {
  notifications: null,
  status: 'pending',
  error: null
} as NotificationsState;

export const notificationsReducer = createReducer(
  initialState,
  on(loadNotifications, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadNotificationsSuccess, (state, { notifications }) => ({
    ...state,
    notifications,
    status: 'success',
    error: null
  })),
  on(loadNotificationsError, (state) => ({
    ...state,
    status: 'error',
    error: 'Unable to fetch notifications.'
  })),
  on(loadMore10Notifications, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadMore10NotificationsSuccess, (state, { notifications }) => ({
    ...state,
    notifications,
    status: 'success',
    error: null
  })),
  on(loadMore10NotificationsError, (state) => ({
    ...state,
    status: 'error',
    error: 'Unable to fetch notifications.'
  })),
  on(readOneNotification, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(readOneNotificationSuccess, (state, { notifications }) => ({
    ...state,
    notifications,
    status: 'success',
    error: null
  })),
  on(readOneNotificationError, (state) => ({
    ...state,
    status: 'error',
    error: 'Unable to read notification.'
  })),
  on(createReminder, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(createReminderSuccess, (state, { reminder }) => ({
    ...state,
    reminder,
    status: 'success',
    error: null
  })),
  on(createReminderError, (state, { error }) => ({
    ...state,
    status: 'error',
    error
  })),
  on(updateReminder, (state) => ({
  ...state,
  status: 'loading',
  error: null
})),
  on(updateReminderSuccess, (state, { reminder }) => ({
    ...state,
    reminder,
    status: 'success',
    error: null
  })),
  on(updateReminderError, (state, { error }) => ({
    ...state,
    status: 'error',
    error
  }))
);
