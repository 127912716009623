import {createAction, props} from "@ngrx/store";
import {CfFolder, GenericStoreStatus} from "@app-web-central/web/shared/data-access/models";

export const setLoadingFoldersState = createAction(
  '[Browse Page] Set Folders State Status',
  props<{
    status: GenericStoreStatus;
  }>()
);

export const loadFolders = createAction(
  '[Folder List] Load Folders'
);

export const loadFoldersSuccess = createAction(
  '[Folder List] Load Folders Success',
  props<{
    folders: CfFolder[];
  }>()
);

export const loadFoldersError = createAction(
  '[Folder List] Load Folders Error',
  props<{
    error: string;
  }>()
);
