import {Component, Input, OnInit} from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";
import {UsersFacade} from "@app-web-central/web/user/data-access";
import {combineLatest, debounceTime, Observable, of, startWith, switchMap} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {TaskUtil} from "@app-web-central/web/shared/utils";
import {FormControl} from "@angular/forms";
import {CfOrganization, CfUIFolder, CfUser} from "@app-web-central/web/shared/data-access/models";

@UntilDestroy()
@Component({
  selector: 'as-modal-folder-share',
  templateUrl: './modal-folder-share.component.html',
  styleUrls: ['./modal-folder-share.component.scss'],
})
export class ModalFolderShareComponent implements OnInit {
  @Input() folder!: CfUIFolder;
  @Input() session!: CfUser | null;
  @Input() organization!: CfOrganization;
  control: FormControl = new FormControl('');
  users$ = this._usersFacade.usersEnabled$;
  results$!: Observable<CfUser[]>;

  constructor(
    private _modalRef: NzModalRef,
    private _usersFacade: UsersFacade
  ) { }

  get hasSearchTermInput(): boolean {
    return !!this.control.value;
  }

  ngOnInit(): void {
    const search$ = this.control.valueChanges.pipe(debounceTime(50), startWith(this.control.value));
    this.results$ = combineLatest([search$, this.users$]).pipe(
      untilDestroyed(this),
      switchMap(([term, users]) => {
        const matchUsers = users?.filter((user) => {
          const foundInEmail = TaskUtil.searchString(user.email, term);
          const foundInUserName = TaskUtil.searchString(user.username, term);
          const foundInFullName = TaskUtil.searchString(user.fullName, term);
          return foundInEmail || foundInUserName || foundInFullName;
        });
        return of(matchUsers || []);
      })
    );
  }

  closeModal() {
    this._modalRef.close();
  }

}
