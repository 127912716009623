import {Route} from "@angular/router";
import {LayoutNoSidebarComponent} from "@app-web-central/web/shell/ui/layout-no-sidebar";

export const webLegalRoutes: Route[] = [
  {
    path: 'p',
    component: LayoutNoSidebarComponent,
    children: [
      {
        path: '',
        redirectTo: 'legal',
        pathMatch: 'full'
      },
      {
        path: 'legal',
        loadChildren: async () => (await import('@app-web-central/web/legal/feature/shell')).LegalShellModule
      }
    ]
  },
  {
    path: '',
    redirectTo: 'p/legal',
    pathMatch: 'full'
  }
];
