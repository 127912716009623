import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {loadUsersConfig} from "./users-config.actions";
import {UsersConfigState} from "./users-config.reducer";
import {getUsersConfig} from "./users-config.selectors";

@Injectable({ providedIn: 'root' })
export class UsersConfigFacade {
  readonly usersConfigurations$ = this._store.pipe(select(getUsersConfig));

  constructor(
    private _store: Store<UsersConfigState>
  ) { }

  init() {
    this._store.dispatch(loadUsersConfig());
  }

}
