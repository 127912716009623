import {Component, Input} from '@angular/core';
import {CfAddress, CfTask} from "@app-web-central/web/shared/data-access/models";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'as-task-drawer-address',
  templateUrl: './task-drawer-address.component.html',
  styleUrls: ['./task-drawer-address.component.scss'],
})
export class TaskDrawerAddressComponent {
  @Input() task!: CfTask;
  @Input() control!: FormControl;
  @Input() addresses!: CfAddress[] | null;

  onSelectAddress(address: CfAddress) {
    if (address) {
      this.control.setValue(address);
    }
  }

}
