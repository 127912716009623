<div class="drawer-notification-list" *ngIf="loaded">
  <nz-list nzItemLayout="vertical" class="load-more-list" [nzLoading]="loadingMore">
    <nz-list-item *ngFor="let notification of list">
      <nz-list-item-meta>
        <nz-list-item-meta-avatar
          *ngIf="notification.referenceModifiedBy"
          [nzSrc]="notification.referenceModifiedBy.avatarUrl">
        </nz-list-item-meta-avatar>
        <nz-list-item-meta-avatar
          *ngIf="!notification.referenceModifiedBy"
          [nzSrc]="'assets/images/pendora-cubes-10-white.svg'">
        </nz-list-item-meta-avatar>
        <nz-list-item-meta-title class="mb-0">
          <a href="javascript:void(0)">{{ notification.notificationSubject }}</a>
        </nz-list-item-meta-title>
        <nz-list-item-meta-description>
          {{ notification.dateAdd | date: fullDateFormat }}
        </nz-list-item-meta-description>
      </nz-list-item-meta>
      <p class="text-textMedium text-13" nz-typography nzEllipsis [nzEllipsisRows]="2">
        <span [innerHTML]="notification.referenceDescription"></span>
      </p>
      <ul nz-list-item-actions>
        <nz-list-item-action></nz-list-item-action>
      </ul>
      <nz-list-item-extra>
        <a *ngIf="notification.markedAsRead" (click)="openDrawerShowNotification(notification)">
          <nz-badge nzDot [nzShowDot]="false">
            <svg-icon class="mr-2" key="eye" [size]="'sm'"></svg-icon>
          </nz-badge>
        </a>
        <a *ngIf="!notification.markedAsRead" (click)="openDrawerShowNotification(notification)">
          <nz-badge nzDot [nzShowDot]="!notification.markedAsRead">
            <svg-icon class="mr-2" key="eye-off" [size]="'sm'"></svg-icon>
          </nz-badge>
        </a>
      </nz-list-item-extra>
    </nz-list-item>

    <div *ngIf="list.length > 0" class="hidden sm:block relative" aria-hidden="true" nz-list-load-more>
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
      <div class="load-more">
        <as-button *ngIf="!loadingMore"
                   [className]="'btn-link icon-only bg-white'"
                   (click)="onLoadMore()" >
          <svg-icon class="mr-0" key="arrow-circle-down" [size]="'md'"></svg-icon>
        </as-button>
      </div>
    </div>
  </nz-list>
</div>

<div *ngIf="!loaded">
  <nz-list class="load-more-list" [nzLoading]="!loadingMore">
    <nz-list-item *ngFor="let int of [0,1,2,3,4,5,6,7,8,9]">
      <nz-skeleton
        [nzAvatar]="true"
        [nzActive]="true"
        [nzTitle]="false"
        [nzLoading]="true"
      ></nz-skeleton>
    </nz-list-item>
  </nz-list>
</div>

<nz-empty *ngIf="list.length < 1"></nz-empty>
