import {Frequency, RRule} from "rrule";
import {FormUtil} from "@app-web-central/web/shared/utils";
import {CfFrequency} from "@app-web-central/web/shared/data-access/models";


export class RruleBuilder {
  freq!: Frequency;
  dtstart!: Date;
  interval!: number;
  count!: number | null;
  until!: Date | null;
  tzid!: string | null;
  wkst = RRule.MO;
  bysetpos!: number | number[] | null;
  bymonth!: number | number[] | null;
  bymonthday!: number | number[] | null;
  byweekday!: number[] | null;

  constructor(formValue: any) {
    if (FormUtil.fieldNotNull(formValue.frequency)) {
      this.freq = RRuleFrequency[formValue.frequency];
    }
    if (FormUtil.fieldNotNull(formValue.dateStart)) {
      this.dtstart = formValue.dateStart;
    }
    if (FormUtil.fieldNotNull(formValue.count)) {
      const length = this.byweekday != null ? this.byweekday.length : 1;
      this.count = parseInt(formValue.count, 10) * length;
    }
    if (FormUtil.fieldNotNull(formValue.interval)) {
      this.interval = parseInt(formValue.interval, 10);
    }
    if (FormUtil.fieldNotNull(formValue.until)) {
      this.until = formValue.until;
    }
    if (FormUtil.fieldNotNull(formValue.bySetPosition)) {
      this.bysetpos = FormUtil.convertToArray(formValue.bySetPosition);
    }
    if (FormUtil.fieldNotNull(formValue.byMonth)) {
      this.bymonth = FormUtil.convertToArray(formValue.byMonth);
    }
    if (FormUtil.fieldNotNull(formValue.byMonthDay)) {
      this.bymonthday = FormUtil.convertToArray(formValue.byMonthDay);
    }
    if (FormUtil.fieldNotNull(formValue.byWeekDay)) {
      this.byweekday = FormUtil.convertToArray(formValue.byWeekDay);
    }
    this.tzid = formValue.timezoneId;
  }

}

export const RRuleFrequency = {
  [CfFrequency.DAILY]: RRule.DAILY,
  [CfFrequency.WEEKLY]: RRule.WEEKLY,
  [CfFrequency.MONTHLY]: RRule.MONTHLY,
  [CfFrequency.YEARLY]: RRule.YEARLY
} as any;
