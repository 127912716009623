import {createAction, props} from "@ngrx/store";
import {CfOrganization} from "@app-web-central/web/shared/data-access/models";

export const loadOrganizations = createAction('[Organization List] Load Organizations');

export const loadOrganizationsSuccess = createAction(
  '[Organization List] Load Organizations Success',
  props<{
    organizations: CfOrganization[];
  }>()
);

export const loadOrganizationsError = createAction(
  '[Organization List] Load Organizations Error',
  props<{
    error: string;
  }>()
);

export const updateOrganizations = createAction(
  '[Organization List] Update Organizations',
  props<{
    organization: CfOrganization;
  }>()

);

export const updateOrganizationsSuccess = createAction(
  '[Organization List] Update Organizations Success',
  props<{
    organizations: CfOrganization[];
  }>()
);

export const updateOrganizationsError = createAction(
  '[Organization List] Update Organizations Error',
  props<{
    error: string;
  }>()
);
