import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SvgIconsModule} from "@ngneat/svg-icon";
import {asHomeOutlineIcon} from "./svg/outline-home";
import {asCollectionOutlineIcon} from "./svg/outline-collection";
import {asBars3OutlineIcon} from "./svg/outline-bars-3";
import {asXMarkOutlineIcon} from "./svg/outline-x-mark";
import {asMagnifyingGlassMiniIcon} from "./svg/mini-magnifying-glass";
import {asBellOutlineIcon} from "./svg/outline-bell";
import {asCogOutlineIcon} from "./svg/outline-cog";
import {asLifebuoyOutlineIcon} from "./svg/outline-lifebuoy";
import {asDocumentOutlineIcon} from "./svg/outline-document";
import {asShieldCheckOutlineIcon} from "./svg/outline-shield-check";
import {asFeedbackIcon} from "./svg/solid-feedback";
import {asFolderOutlineIcon} from "./svg/outline-folder";
import {asCalendarDaysOutlineIcon} from "./svg/outline-calendar-days";
import {asClockOutlineIcon} from "./svg/outline-clock";
import {asFireMiniIcon} from "./svg/mini-fire";
import {asClipboardDocumentOutlineIcon} from "./svg/outline-clipboard-document";
import {asUserPlusOutlineIcon} from "./svg/outline-user-plus";
import {asFireOutlineIcon} from "./svg/outline-fire";
import {asChevronDownMiniIcon} from "./svg/mini-chevron-down";
import {asRefreshMiniIcon} from "./svg/mini-refresh";
import {asDownloadMiniIcon} from "./svg/mini-download";
import {asUserPlusMiniIcon} from "./svg/mini-user-plus";
import {asTrashMiniIcon} from "./svg/mini-trash";
import {asChevronLeftMiniIcon} from "./svg/mini-chevron-left";
import {asChevronRightMiniIcon} from "./svg/mini-chevron-right";
import {asTrashOutlineIcon} from "./svg/outline-trash";
import {asPencilOutlineIcon} from "./svg/outline-pencil";
import {asTagOutlineIcon} from "./svg/outline-tag";
import {asCalendarOutlineIcon} from "./svg/outline-calendar";
import {asTagSolidIcon} from "./svg/solid-tag";
import {asUserCircleSolidIcon} from "./svg/solid-user-circle";
import {asCalendarSolidIcon} from "./svg/solid-calendar";
import {asUserCircleMiniIcon} from "./svg/mini-user-circle";
import {asPlusMiniIcon} from "./svg/mini-plus";
import {asDocumentTextOutlineIcon} from "./svg/outline-document-text";
import {asSquaresPlusOutlineIcon} from "./svg/outline-squares-plus";
import {asPencilSquareSolidIcon} from "./svg/solid-pencil-square";
import {asTrashSolidIcon} from "./svg/solid-trash";
import {asMapPinSolidIcon} from "./svg/solid-map-pin";
import {asHomeModernSolidIcon} from "./svg/solid-home-modern";
import {asCheckCircleMiniIcon} from "./svg/mini-check-circle";
import {asChevronDoubleUpSolidIcon} from "./svg/solid-chevron-double-up";
import {asChevronDoubleDownSolidIcon} from "./svg/solid-chevron-double-down";
import {asStarSolidIcon} from "./svg/solid-star";
import {asChevronRightSolidIcon} from "./svg/solid-chevron-right";
import {asChevronDownSolidIcon} from "./svg/solid-chevron-down";
import {asDotsVerticalSolidIcon} from "./svg/solid-dots-vertical";
import {asDocumentPlusOutlineIcon} from "./svg/outline-document-plus";
import {asCalendarDaysSolidIcon} from "./svg/solid-calendar-days";
import {asEyeOutlineIcon} from "./svg/outline-eye";
import {asEyeOffOutlineIcon} from "./svg/outline-eye-off";
import {asDotsHorizontalSolidIcon} from "./svg/solid-dots-horizontal";
import {asBoardOutlineIcon} from "./svg/outline-board";
import {asChatBubbleBottomOutlineIcon} from "./svg/outline-chat-bubble-bottom";
import {asPaperClipOutlineIcon} from "./svg/outline-paper-clip";
import {asCloudArrowUpOutlineIcon} from "./svg/outline-cloud-arrow-up";
import {asDocumentArrowDownOutlineIcon} from "./svg/outline-document-arrow-down";
import {asThumbDownOutlineIcon} from "./svg/outline-thumb-down";
import {asThumbUpOutlineIcon} from "./svg/outline-thumb-up";
import {asChevronUpDownMiniIcon} from "./svg/mini-chevron-up-down";
import {asCheckMiniIcon} from "./svg/mini-check";
import {asXCircleMiniIcon} from "./svg/mini-x-circle";
import {asChevronUpSolidIcon} from "./svg/solid-chevron-up";
import {asBuildingOffice2SolidIcon} from "./svg/solid-building-office-2";
import {asCheckCircleSolidIcon} from "./svg/solid-check-circle";
import {asCheckCircleOutlineIcon} from "./svg/outline-check-circle";
import {asMagnifyingGlassSolidIcon} from "./svg/solid-magnifying-glass";
import {asDocumentCheckSolidIcon} from "./svg/solid-document-check";
import {asSelectorMiniIcon} from "./svg/mini-selector";
import {asFolderSolidIcon} from "./svg/solid-folder";
import {asArrowPointingOutSolidIcon} from "./svg/solid-arrow-pointing-out";
import {asLinkSolidIcon} from "./svg/solid-link";
import {asShareSolidIcon} from "./svg/solid-share";
import {asMapOutlineIcon} from "./svg/outline-map";
import {asInboxStackOutlineIcon} from "./svg/outline-inbox-stack";
import {asInboxOutlineIcon} from "./svg/outline-inbox";
import {asStarOutlineIcon} from "./svg/outline-star";
import {asCloudArrowUpMiniIcon} from "./svg/mini-cloud-arrow-up";
import {asShareMiniIcon} from "./svg/mini-share";
import {asPhotoSolidIcon} from "./svg/solid-photo";
import {asDocumentTextSolidIcon} from "./svg/solid-document-text";
import {asPencilMiniIcon} from "./svg/mini-pencil";
import {asSpinOutlineIcon} from "./svg/outline-spin";
import {asStorageOutlineIcon} from "./svg/outline-storage";
import {asXCircleSolidIcon} from "./svg/solid-x-circle";
import {asInformationCircleSolidIcon} from "./svg/solid-information-circle";
import {asExclamationCircleSolidIcon} from "./svg/solid-exclamation-circle";
import {asClockSolidIcon} from "./svg/solid-clock";
import {asCloudOutlineIcon} from "./svg/outline-cloud";
import {asTruckOutlineIcon} from "./svg/outline-truck";
import {asBookmarkMiniIcon} from "./svg/mini-bookmark";
import {asMagnifyingGlassOutlineIcon} from "./svg/outline-magnifying-glass";
import {asKeyOutlineIcon} from "./svg/outline-key";
import {asAdjustmentHorizontalOutlineIcon} from "./svg/outline-adjustments-horizontal";
import {asQuestionCircleOutlineIcon} from "./svg/outline-question-circle";
import {asQuestionCircleMiniIcon} from "./svg/mini-question-circle";
import {asCreditCardOutlineIcon} from "./svg/outline-credit-card";
import {asRectangleGroupOutlineIcon} from "./svg/outline-rectangle-group";
import {asWalletOutlineIcon} from "./svg/outline-wallet";
import {asBuilingOffice2OutlineIcon} from "./svg/outline-building-office-2";
import {asMapPinOutlineIcon} from "./svg/outline-map-pin";
import {asUsersOutlineIcon} from "./svg/outline-users";
import {asUserGroupOutlineIcon} from "./svg/outline-user-group";
import {asUserOutlineIcon} from "./svg/outline-user";
import {asComputerSolidIcon} from "./svg/solid-computer";
import {asMobileSolidIcon} from "./svg/solid-mobile";
import {asVisaSolidIcon} from "./svg/solid-visa";
import {asArrowLongLeftOutlineIcon} from "./svg/outline-arrow-long-left";
import {asChevronLeftSolidIcon} from "./svg/solid-chevron-left";
import {asSquare2StackOutlineIcon} from "./svg/outline-square-2-stack";

@NgModule({
  imports: [
    CommonModule,
    SvgIconsModule.forRoot({
      sizes: {
        xs: '16px',
        sm: '20px',
        md: '24px',
        lg: '28px',
        xl: '32px',
        xxl: '36px'
      },
      defaultSize: 'md',
      icons: [
        asSquare2StackOutlineIcon,
        asChevronLeftSolidIcon,
        asArrowLongLeftOutlineIcon,
        asVisaSolidIcon,
        asComputerSolidIcon,
        asMobileSolidIcon,
        asUsersOutlineIcon,
        asUserGroupOutlineIcon,
        asUserOutlineIcon,
        asMapPinOutlineIcon,
        asBuilingOffice2OutlineIcon,
        asWalletOutlineIcon,
        asRectangleGroupOutlineIcon,
        asCreditCardOutlineIcon,
        asQuestionCircleMiniIcon,
        asQuestionCircleOutlineIcon,
        asAdjustmentHorizontalOutlineIcon,
        asKeyOutlineIcon,
        asMagnifyingGlassOutlineIcon,
        asBookmarkMiniIcon,
        asTruckOutlineIcon,
        asCloudOutlineIcon,
        asClockSolidIcon,
        asExclamationCircleSolidIcon,
        asInformationCircleSolidIcon,
        asXCircleSolidIcon,
        asStorageOutlineIcon,
        asSpinOutlineIcon,
        asPencilMiniIcon,
        asPhotoSolidIcon,
        asDocumentTextSolidIcon,
        asShareMiniIcon,
        asCloudArrowUpMiniIcon,
        asStarOutlineIcon,
        asInboxStackOutlineIcon,
        asInboxOutlineIcon,
        asMapOutlineIcon,
        asShareSolidIcon,
        asLinkSolidIcon,
        asArrowPointingOutSolidIcon,
        asFolderSolidIcon,
        asSelectorMiniIcon,
        asDocumentCheckSolidIcon,
        asMagnifyingGlassSolidIcon,
        asCheckCircleOutlineIcon,
        asCheckCircleSolidIcon,
        asBuildingOffice2SolidIcon,
        asChevronUpSolidIcon,
        asXCircleMiniIcon,
        asCheckMiniIcon,
        asChevronUpDownMiniIcon,
        asChatBubbleBottomOutlineIcon,
        asPaperClipOutlineIcon,
        asCloudArrowUpOutlineIcon,
        asDocumentArrowDownOutlineIcon,
        asThumbDownOutlineIcon,
        asThumbUpOutlineIcon,
        asBoardOutlineIcon,
        asDotsHorizontalSolidIcon,
        asEyeOffOutlineIcon,
        asEyeOutlineIcon,
        asCalendarDaysSolidIcon,
        asDocumentPlusOutlineIcon,
        asDotsVerticalSolidIcon,
        asChevronRightSolidIcon,
        asChevronDownSolidIcon,
        asStarSolidIcon,
        asChevronDoubleUpSolidIcon,
        asChevronDoubleDownSolidIcon,
        asCheckCircleMiniIcon,
        asHomeModernSolidIcon,
        asMapPinSolidIcon,
        asPencilSquareSolidIcon,
        asTrashSolidIcon,
        asSquaresPlusOutlineIcon,
        asDocumentTextOutlineIcon,
        asPlusMiniIcon,
        asUserCircleMiniIcon,
        asCalendarSolidIcon,
        asUserCircleSolidIcon,
        asTagSolidIcon,
        asTagOutlineIcon,
        asCalendarOutlineIcon,
        asTrashOutlineIcon,
        asPencilOutlineIcon,
        asChevronLeftMiniIcon,
        asChevronRightMiniIcon,
        asTrashMiniIcon,
        asUserPlusMiniIcon,
        asDownloadMiniIcon,
        asRefreshMiniIcon,
        asChevronDownMiniIcon,
        asFireOutlineIcon,
        asCalendarDaysOutlineIcon,
        asClockOutlineIcon,
        asFireMiniIcon,
        asClipboardDocumentOutlineIcon,
        asFolderOutlineIcon,
        asFeedbackIcon,
        asMagnifyingGlassMiniIcon,
        asXMarkOutlineIcon,
        asUserPlusOutlineIcon,
        asBars3OutlineIcon,
        asCollectionOutlineIcon,
        asHomeOutlineIcon,
        asDocumentOutlineIcon,
        asShieldCheckOutlineIcon,
        asCogOutlineIcon,
        asLifebuoyOutlineIcon,
        asBellOutlineIcon,
      ]
    })
  ],
  exports: [
    SvgIconsModule
  ]
})
export class IconModule {
}
