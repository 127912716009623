import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {
  CfComment,
  CfProject,
  CfTask,
  CfUser,
  GenericState,
  Priority,
  Segment,
  TaskStatus
} from "@app-web-central/web/shared/data-access/models";
import {BehaviorSubject, map, Observable, pluck, switchMap, tap, withLatestFrom} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {DateUtil, RouterUtil, SelectorUtil, TaskUtil} from "@app-web-central/web/shared/utils";
import {TaskApi, TaskAssignees} from "@app-web-central/web/shared/data-access/cf-api";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {select, Store} from "@ngrx/store";
import {getTasks, loadTasksSuccess} from "../tasks";
import {TranslateService} from "@ngx-translate/core";
import {ChoiceState} from "@app-web-central/web/shell/ui/modal-recurrence-edit";
import {getUsers} from "@app-web-central/web/user/data-access";
import {getSession} from "@app-web-central/web/session/data-access";
import {ERROR, SUCCESS} from "@app-web-central/web/shared/services/local-notification";

export interface CfUITask {
  name: any;
  assignee: CfUser | null | undefined;
  status: TaskStatus;
  dateAdd: number;
  dueDate: number;
}

interface TaskState extends GenericState<CfTask> {
  taskId: string;
}

@Injectable()
export class TaskStore extends ComponentStore<TaskState> {
  users$ = this._store.pipe(select(getUsers));
  tasks$ = this._store.pipe(select(getTasks));
  session$ = this._store.pipe(select(getSession));

  taskIdParams$: Observable<string> = this._route.params.pipe(
    pluck(RouterUtil.Configuration.TaskId)
  );

  isTasksLoading$ = new BehaviorSubject<boolean>(true);
  isCurrentTaskLoading$ = this.select(SelectorUtil.isLoading);

  /**
   * Get all tasks within a date (start / end date) and project id.
   * Filter by date and sort by start time window.
   *
   * @param projectId the project id as { string }.
   * @param date the date to check as { Date }.
   */
  tasksByProjectIdAndIsInBetweenDateSorted$ = (projectId: string, date: Date): Observable<CfTask[]> => this.tasks$.pipe(
    map((tasks) => tasks !== null ? tasks
      .filter((x) => DateUtil.isDateWithinDay(x.dates.startDate, date) && x.projectId === projectId)
      .sort((a, b) => a.dates.startDate - b.dates.startDate)
      : []
    )
  );

  /**
   * Get all tasks by status and project Id and return a UI Events definition.
   *
   * @param status the task status as { TaskStatus }.
   * @param projectId the project id as { string }.
   */
  tasksByStatusAndProjectIdAsUIObject$ = (status: TaskStatus, projectId: string): Observable<any[]> => this.tasks$.pipe(
    tap(() => this.isTasksLoading$.next(true)),
    map((tasks) => tasks !== null
      ? tasks
        .filter((x) => x.status === status && x.projectId === projectId)
        .map((y) => (TaskUtil.buildUITaskObject(y)))
      : []
    ),
    tap(() => this.isTasksLoading$.next(false))
  );

  /**
   * Get all tasks filtered by last updated and limit array size to 10.
   *
   */
  tasksFilteredByLastUpdateAndLimited$ = (): Observable<any[]> => this.tasks$.pipe(
    map((tasks) => tasks !== null
      ? tasks.slice()
        .sort((a: CfTask, b: CfTask) => b.dateUpd - a.dateUpd)
        .map((y) => (TaskUtil.buildUITaskObjectWithoutDateAdd(y)))
      : []
    )
  );

  /**
   * Get all tasks by status and project Id.
   *
   * @param status the task status as { TaskStatus }.
   * @param projectId the project id as { string }.
   */
  tasksByStatusSortedAndProjectId$ = (status: TaskStatus, projectId: string): Observable<CfTask[]> => this.tasks$.pipe(
    tap(() => this.isTasksLoading$.next(true)),
    map((tasks) => tasks !== null
      ? tasks.filter((x) => x.status === status && x.projectId === projectId)
      : []
    ),
    tap(() => this.isTasksLoading$.next(false))
  );

  /**
   * Get all tasks by today date and project Id.
   *
   * @param projectId the project id as { string }.
   */
  tasksByProjectIdAndTodayDate$ = (projectId: string): Observable<CfTask[]> => this.tasks$.pipe(
    map((tasks) => tasks !== null
      ? tasks.filter((x) => DateUtil.isDateWithinDay(x.dates.deliveryDate, new Date()) && x.projectId === projectId)
      : []
    )
  );

  /**
   * Get all tasks by today date during the next 7 days and project Id.
   *
   * @param projectId the project id as { string }.
   */
  tasksByProjectIdAndForNext7Date$ = (projectId: string): Observable<CfTask[]> => this.tasks$.pipe(
    map((tasks) => tasks !== null
      ? tasks.filter((x) => DateUtil.isDateWithinNext7Days(x.dates.deliveryDate, new Date()) && x.projectId === projectId)
      : []
    )
  );

  /**
   * Get all tasks by priority and project Id.
   *
   * @param priority the task priority as { Priority }.
   * @param projectId the project id as { string }.
   */
  tasksByProjectIdAndPriority$ = (priority: Priority, projectId: string): Observable<CfTask[]> => this.tasks$.pipe(
    map((tasks) => tasks !== null
      ? tasks.filter((x) => x.priority === priority && x.projectId === projectId)
      : []
    )
  );

  /**
   * Get all tasks by project Id.
   *
   * @param projectId the project id as { string }.
   */
  tasksByProjectId$ = (projectId: string): Observable<CfTask[]> => this.tasks$.pipe(
    map((tasks) => tasks !== null
      ? tasks.filter((x) => x.projectId === projectId)
      : []
    )
  );

  /**
   * Get all @non-recurring tasks by status and group by object attribute.
   *
   * @param attr the object attribute as { string }.
   * @param projectId the project id as { string }.
   * @param projects$
   */
  tasksGroupByAttributeAndProjectId$ = (
    attr: string, projectId: string, projects$: Observable<CfProject[] | null>
  ): Observable<any> => this.tasks$.pipe(
    withLatestFrom(this.users$, projects$),
    map(([tasks, users, projects]) => tasks !== null && users !== null && projects !== null
      ? this.groupBy(tasks, attr, projectId, users, projects)
      : new Map()
    )
  );

  /**
   * Count the number of tasks by status and project id.
   *
   * @param segments the list of status as a ui segment.
   * @param projectId the project id to filter on as { string }.
   */
  countOfTasksByStatusAndProjectId$ = (segments: Segment[], projectId: string): Observable<number[]> => this.tasks$.pipe(
    map((tasks) => tasks !== null
      ? segments.map((s) => tasks.filter((x) => x.projectId === projectId && x.status === s.status).length )
      : [0, 0, 0, 0, 0]
    )
  );

  /**
   * Count the number of tasks by status and project id.
   *
   * @param status the task status as { TaskStatus }.
   * @param projectId the project id to filter on as { string }.
   */
  countOneOfTasksByStatusAndProjectId$ = (status: TaskStatus, projectId: string): Observable<number> => this.tasks$.pipe(
    map((tasks) => tasks !== null
      ? tasks.filter((x) => x.projectId === projectId && x.status === status).length
      : 0
    )
  );

  /**
   * Count the number of tasks by spec status and project id.
   *
   * @param status the task status as { TaskStatus }.
   * @param projectId the project id to filter on as { string }.
   */
  countOfTasksByStatusAndProjectIdWithinMonth$ = (status: TaskStatus, projectId: string): Observable<number> => this.tasks$.pipe(
    map((tasks) => tasks !== null
      ? tasks.filter((x) => x.projectId === projectId
        && x.status === status
        && DateUtil.isDateWithinMonth(x.dates.startDate)).length
      : 0
    )
  );

  /**
   * Count the number of tasks by projects and user in project's members.
   *
   */
  countOfTasksWithinCurrentMonth$ = (): Observable<number> => this.tasks$.pipe(
    withLatestFrom(this.session$),
    map(([tasks, session]) => tasks !== null
      ? tasks.filter((x) => DateUtil.isDateWithinMonth(x.dates.startDate)).length
      : 0
    )
  );

  /**
   * Count the number of tasks by status.
   *
   * @param status the task status as { TaskStatus }.
   */
  countOfTasksByStatusWithinCurrentMonth$ = (status: TaskStatus): Observable<number> => this.tasks$.pipe(
    map((tasks) => tasks !== null
      ? tasks.filter((x) => x.status === status
        && DateUtil.isDateWithinMonth(x.dates.startDate)).length
      : 0
    )
  );

  countOfRemainingTasksToCompleteForToday$ = (projectId: string): Observable<number> => this.tasks$.pipe(
    map((tasks) => tasks !== null
      ? tasks.filter((x) => x.projectId === projectId
        && x.status !== TaskStatus.COMPLETED
        && x.status !== TaskStatus.FAILED
        && DateUtil.isDateToday(x.dates.deliveryDate)).length
      : 0
    )
  );

  percentageOfAllTasksCompletedForToday$ = (projectId: string): Observable<number> => this.tasks$.pipe(
    map((tasks) => tasks !== null
      ? ((tasks.filter((x) => x.projectId === projectId
        && x.status === TaskStatus.COMPLETED
        && DateUtil.isDateToday(x.dates.deliveryDate)).length)
      / (tasks.filter((x) => x.projectId === projectId
        && DateUtil.isDateToday(x.dates.deliveryDate)).length)) * 100
      : 0
    )
  );

  /**
   * Get all tags form tasks list.
   *
   */
  getAllTasksTags$ = (): Observable<string[]> => this.tasks$.pipe(
    map((tasks) => tasks !== null
      ? tasks
        .flatMap((x) => x.tags)
        .filter((item, i, tags) => tags.indexOf(item) === i)
      : []
    )
  )

  /**
   * Get all tasks authors from tasks list.
   *
   */
  getAllTasksAuthors$ = (): Observable<string[]> => this.tasks$.pipe(
    map((tasks) => tasks !== null
      ? tasks
        .flatMap((x) => x.createdBy ? x.createdBy.fullName : '')
        .filter((item, i, authors) => authors.indexOf(item) === i)
      : []
    )
  )

  /**
   * Helper function to group tasks by attribute and return a map of a group like:
   * data = < uuid > : [{}, {}, {}];
   *
   * @param tasks the input array of tasks.
   * @param prop the object property as { string } ie: assignee.
   * @param projectId as { string }.
   * @param users the list of available users as { CfUser[] }.
   * @param projects the list of available projects as { CfProject[] }.
   */
  groupBy(tasks: any[], prop: string, projectId: string, users: CfUser[], projects: CfProject[]) {
    tasks = tasks.filter((t) => t.projectId === projectId
      && users.filter((u) => projects
        .find((p) => p.id === projectId)?.members
        .some((m) => m.members === u.id))
    );
    const map = new Map<CfUser, CfTask[]>(Array.from(tasks, obj => [obj[prop], []]));
    tasks.forEach((task: any) => {
      const assignee = task[prop];
      const entry = map.get(assignee);
      entry?.push(task);
    });
    return map;
  }

  task$ = this.taskIdParams$.pipe(
    tap((taskId) => {
      this.patchState({
        taskId
      });
      if (!taskId) {
        taskId = this._route.snapshot.queryParams[RouterUtil.Configuration.TaskId];
      }
      this.loadTask({ taskId });
    }),
    switchMap(() => this.select((s) => s.data))
  );

  loadTask = this.effect<{ taskId: string }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({ taskId }) =>
        this._taskApi.get(taskId).pipe(
          withLatestFrom(this.users$),
          tapResponse(
            ([response, users]) => {
              const task = { ...response.payload };
              task.channel.subscribers = TaskUtil.setChannelSubscribers(task, users);
              this.patchState({
                data: task,
                status: 'success',
                error: ''
              });
            },
            error => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }
          )
        )
      )
    )
  );

  updateTasks = this.effect<{ choice: ChoiceState, task: CfTask }>((params$) =>
    params$.pipe(
      withLatestFrom(this.tasks$, this.session$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{ choice, task }, tasks, session]) => {
        const storeTasks = tasks != null ? [...tasks] : [];
        const ids = this._filterTasksByRecurrenceId(task, storeTasks, choice);
        task.modifiedBy = session;
        return this._taskApi.updateAll(ids, task).pipe(
          tapResponse(
            (response) => {
              const newTasks = tasks != null ? [...tasks, ...response.payload] : [...response.payload];
              this._store.dispatch(
                loadTasksSuccess({
                  tasks: newTasks
                })
              );
              this.patchState({
                data: task,
                status: 'success',
                error: ''
              });
            },
            error => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
              this._notification.create(
                ERROR,
                this._translate.instant('notifications.update.error'),
                this._translate.instant('task_detail.notifications.update.error')
              );
            }
          )
        )
      })
    )
  );

  createTask = this.effect<CfTask>( (params$) =>
    params$.pipe(
      withLatestFrom(this.tasks$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([task, tasks]) => this._taskApi.create(task)
        .pipe(
          tapResponse(
            (response) => {
              const newTasks = tasks !== null
                ? [...tasks, ...[response.payload]]
                : [response.payload];
              this._store.dispatch(
                loadTasksSuccess({
                  tasks: newTasks
                })
              );
              this.patchState({
                data: response.payload,
                status: 'success',
                error: ''
              });
              this._notification.create(
                SUCCESS,
                this._translate.instant('notifications.creation.success'),
                this._translate.instant('task_detail.notifications.creation.success')
              );
            },
            error => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
              this._notification.create(
                ERROR,
                this._translate.instant('notifications.creation.error'),
                this._translate.instant('task_detail.notifications.creation.error')
              );
            }
          )
        )
      )
    )
  );

  updateTask = this.effect<CfTask>( (params$) =>
    params$.pipe(
      withLatestFrom(this.tasks$, this.users$, this.session$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([task, tasks, users, session]) => {
          task.modifiedBy = session;
          return this._taskApi.update(task.id, task).pipe(
            tapResponse(
              (response) => {
                const newTask = { ...response.payload };
                const newTasks = tasks !== null ? [...tasks] : [];
                const index = newTasks.findIndex(item => item.id === newTask.id);
                newTask.channel.subscribers = TaskUtil.setChannelSubscribers(newTask, users)
                newTasks[index] = { ...newTask };
                this._store.dispatch(
                  loadTasksSuccess({
                    tasks: newTasks
                  })
                );
                this.patchState({
                  data: newTask,
                  status: 'success',
                  error: ''
                });
              },
              error => {
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });
                this._notification.create(
                  ERROR,
                  this._translate.instant('notifications.update.error'),
                  this._translate.instant('task_detail.notifications.update.error')
                );
              }
            )
          )
        }
      )
    )
  );

  updateTaskStatus = this.effect<CfTask>( (params$) =>
    params$.pipe(
      withLatestFrom(this.tasks$, this.session$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([task, tasks, session]) => {
          task.modifiedBy = session;
          return this._taskApi.updateStatus(task.id, task).pipe(
            tapResponse(
              (response) => {
                const newTask = response.payload;
                const newTasks = tasks != null ? [...tasks] : [];
                const index = newTasks.findIndex(item => item.id === newTask.id);
                if (newTasks.length) {
                  newTasks[index] = newTask;
                }
                this._store.dispatch(
                  loadTasksSuccess({
                    tasks: newTasks
                  })
                );
                this.patchState({
                  data: newTask,
                  status: 'success',
                  error: ''
                });
                this._notification.create(
                  SUCCESS,
                  this._translate.instant('notifications.update.success'),
                  this._translate.instant('task_detail.notifications.update.success')
                );
              },
              error => {
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });
                this._notification.create(
                  ERROR,
                  this._translate.instant('notifications.update.error'),
                  this._translate.instant('task_detail.notifications.update.error')
                );
              }
            )
          )
        }
      )
    )
  );

  assignTask = this.effect<{ user: CfUser, task: CfTask }>( (params$) =>
    params$.pipe(
      withLatestFrom(this.tasks$, this.session$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{ user, task }, tasks, session]) => {
          const sessionId = session !== null ? session.id : '';
          const assignee: TaskAssignees = ({assignee: user.id, modifiedBy: sessionId, eventIds: [task.id]});
          return this._taskApi.assignees(assignee).pipe(
            tapResponse(
              (response) => {
                const newTasks = tasks !== null ? [...tasks] : [];
                const newTask = ({ ...task, assignee: user });
                response.payload.forEach((responseTask) => {
                  const index = newTasks?.findIndex((item) => item.id === responseTask.id);
                  if (index && newTasks) {
                    newTasks[index] = responseTask;
                  }
                });
                this._store.dispatch(
                  loadTasksSuccess({
                    tasks: newTasks
                  })
                );
                this.patchState({
                  data: newTask,
                  status: 'success',
                  error: ''
                });
                this._notification.create(
                  SUCCESS,
                  this._translate.instant('notifications.assignees.success'),
                  this._translate.instant('task_detail.notifications.assignees.success')
                );
              },
              error => {
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });
                this._notification.create(
                  ERROR,
                  this._translate.instant('notifications.assignees.error'),
                  this._translate.instant('task_detail.notifications.assignees.error')
                );
              }
            )
          )
        }
      )
    )
  );

  unassignedTask = this.effect<CfTask>( (params$) =>
    params$.pipe(
      withLatestFrom(this.tasks$, this.session$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([task, tasks, session]) => {
          task.modifiedBy = session;
          return this._taskApi.unassigned(task.id).pipe(
            tapResponse(
              (response) => {
                const newTask = {...response.payload};
                const newTasks = tasks !== null ? [...tasks] : [];
                const index = newTasks.findIndex((x) => x.id === newTask.id);
                newTasks[index] = newTask;
                this._store.dispatch(
                  loadTasksSuccess({
                    tasks: newTasks
                  })
                );
                this.patchState({
                  data: newTask,
                  status: 'success',
                  error: ''
                });
              },
              error => {
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });
                this._notification.create(
                  ERROR,
                  this._translate.instant('notifications.unassigned.error'),
                  this._translate.instant('task_detail.notifications.unassigned.error')
                );
              }
            )
          )
        }
      )
    )
  );

  addTags = this.effect<{ task: CfTask, tags: string[] }>( (params$) =>
    params$.pipe(
      withLatestFrom(this.tasks$, this.session$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{ task, tags }, tasks, session]) =>
        this._taskApi.tags(task.id, tags).pipe(
          tapResponse(
            (response) => {
              const newTasks = tasks !== null ? [...tasks] : [];
              const newTask = { ...response.payload };
              newTask.modifiedBy = session;
              const index = newTasks.findIndex((x) => x.id === newTask.id);
              newTasks[index] = newTask;
              this._store.dispatch(
                loadTasksSuccess({
                  tasks: newTasks
                })
              );
              this.patchState({
                data: newTask,
                status: 'success',
                error: ''
              });
              this._notification.create(
                SUCCESS,
                this._translate.instant('notifications.tags.success'),
                this._translate.instant('task_detail.notifications.tags.success')
              );
            },
            error => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
              this._notification.create(
                ERROR,
                this._translate.instant('notifications.tags.error'),
                this._translate.instant('task_detail.notifications.tags.error')
              );
            }
          )
        )
      )
    )
  );

  readonly removeTasks = this.effect<{ choice: ChoiceState, task: CfTask }>((params$) =>
    params$.pipe(
      withLatestFrom(this.tasks$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{ choice, task }, tasks]) => {
        const storeTasks = tasks != null ? [...tasks] : [];
        const ids = this._filterTasksByRecurrenceId(task, storeTasks, choice);
        return this._taskApi.deleteBulk(ids).pipe(
          tapResponse(
            (response) => {
              const newTasks = storeTasks.filter((x) => ids.some((y) => y !== x.id))
              this._store.dispatch(
                loadTasksSuccess({
                  tasks: newTasks
                })
              );
              this.patchState({
                data: task,
                status: 'success',
                error: ''
              });
              this._notification.create(
                SUCCESS,
                this._translate.instant('task_detail.notifications.delete_all.success'),
                this._translate.instant('task_detail.notifications.delete_all.success_desc')
              );
              // this._router.navigate([RouteUtil.getProjectsRouteUrl()]);
            },
            error => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
              this._notification.create(
                ERROR,
                this._translate.instant('notifications.update.error'),
                this._translate.instant('task_detail.notifications.update.error')
              );
            }
          )
        )
      })
    )
  )

  readonly removeTask = this.effect<string>( (params$) =>
    params$.pipe(
      withLatestFrom(this.tasks$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([taskId, tasks]) =>
        this._taskApi.delete(taskId).pipe(
          tapResponse(
            (response) => {
              const newTasks = tasks !== null ? [...tasks] : [];
              const index = newTasks.findIndex(item => item.id === taskId);
              newTasks.splice(index, 1);
              this._store.dispatch(
                loadTasksSuccess({
                  tasks: newTasks
                })
              );
              this._notification.create(
                SUCCESS,
                this._translate.instant('notifications.delete.success'),
                response.payload
              );
              // this._router.navigate([RouteUtil.getProjectsRouteUrl()]);
            },
            error => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
              this._notification.create(
                ERROR,
                this._translate.instant('notifications.delete.error'),
                this._translate.instant('notifications.delete.message_error')
              );
            }
          )
        )
      )
    )
  );

  readonly startTask = this.effect<CfTask>( (params$) =>
    params$.pipe(
      withLatestFrom(this.session$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([task, session]) => {
          task.modifiedBy = session;
          return this._taskApi.start(task.id).pipe(
            tapResponse(
              (response) => {
                const newTask = {...response.payload};
                this.patchState({
                  data: newTask,
                  status: 'success',
                  error: ''
                });
              },
              error => {
                this._notification.create(
                  'error',
                  this._translate.instant('task_detail.notifications.start.error'),
                  this._translate.instant('task_detail.notifications.start.error_content')
                );
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });
              }, () => {
                this._notification.create(
                  'success',
                  this._translate.instant('task_detail.notifications.start.success'),
                  this._translate.instant('task_detail.notifications.start.success_content')
                );
              }
            )
          )
        }
      )
    )
  );

  readonly stopTask = this.effect<CfTask>( (params$) =>
    params$.pipe(
      withLatestFrom(this.session$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([task, session]) => {
          task.modifiedBy = session;
          return this._taskApi.hold(task.id).pipe(
            tapResponse(
              (response) => {
                const newTask = {...response.payload};
                this.patchState({
                  data: newTask,
                  status: 'success',
                  error: ''
                });
              },
              error => {
                this._notification.create(
                  'error',
                  this._translate.instant('task_detail.notifications.stop.error'),
                  this._translate.instant('task_detail.notifications.stop.error_content')
                );
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });
              }, () => {
                this._notification.create(
                  'success',
                  this._translate.instant('task_detail.notifications.stop.success'),
                  this._translate.instant('task_detail.notifications.stop.success_content')
                );
              }
            )
          )
        }
      )
    )
  );

  readonly validateTask = this.effect<CfTask>( (params$) =>
    params$.pipe(
      withLatestFrom(this.session$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([task, session]) => {
          task.modifiedBy = session;
          return this._taskApi.validate(task.id).pipe(
            tapResponse(
              (response) => {
                const newTask = {...response.payload};
                this.patchState({
                  data: newTask,
                  status: 'success',
                  error: ''
                });
              },
              error => {
                this._notification.create(
                  'error',
                  this._translate.instant('task_detail.notifications.validate.error'),
                  this._translate.instant('task_detail.notifications.validate.error_content')
                );
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });
              }, () => {
                this._notification.create('success',
                  this._translate.instant('task_detail.notifications.validate.success'),
                  this._translate.instant('task_detail.notifications.validate.success_content')
                );
              }
            )
          )
        }
      )
    )
  );

  readonly addCommentTask = this.effect<{ task: CfTask, comment: CfComment }>( (params$) =>
    params$.pipe(
      withLatestFrom(this.tasks$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{task, comment}, tasks]) =>
        this._taskApi.addComment(comment).pipe(
          tapResponse(
            (response) => {
              const newTask = {...task};
              newTask.comments = [...response.payload];

              const newTasks = tasks !== null ? [...tasks] : [];
              const idx = newTasks.findIndex((t) => t.id === task.id);
              newTasks[idx] = newTask;

              this._store.dispatch(
                loadTasksSuccess({
                  tasks: newTasks
                })
              );
              this.patchState({
                data: newTask,
                status: 'success',
                error: ''
              });
            },
            error => {
              this._notification.create(
                'error',
                this._translate.instant('task_detail.notifications.add_comment.error'),
                this._translate.instant('task_detail.notifications.add_comment.error_content')
              );
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }, () => {
              this._notification.create(
                'success',
                this._translate.instant('task_detail.notifications.add_comment.success'),
                this._translate.instant('task_detail.notifications.add_comment.success_content')
              );
            }
          )
        )
      )
    )
  );

  readonly editCommentTask = this.effect<{ task: CfTask, comment: CfComment }>( (params$) =>
    params$.pipe(
      withLatestFrom(this.tasks$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{task, comment}, tasks]) =>
        this._taskApi.editComment(comment.id, comment).pipe(
          tapResponse(
            (response) => {
              const newTask = {...task};
              newTask.comments = [...response.payload];

              const newTasks = tasks !== null ? [...tasks] : [];
              const idx = newTasks.findIndex((t) => t.id === task.id);
              newTasks[idx] = newTask;

              this._store.dispatch(
                loadTasksSuccess({
                  tasks: newTasks
                })
              );
              this.patchState({
                data: newTask,
                status: 'success',
                error: ''
              });
            },
            error => {
              this._notification.create(
                'error',
                this._translate.instant('task_detail.notifications.edit_comment.error'),
                this._translate.instant('task_detail.notifications.edit_comment.error_content')
              );
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }, () => {
              this._notification.create(
                'success',
                this._translate.instant('task_detail.notifications.edit_comment.success'),
                this._translate.instant('task_detail.notifications.edit_comment.success_content')
              );
            }
          )
        )
      )
    )
  );

  readonly removeCommentTask = this.effect<{ task: CfTask, comment: CfComment }>( (params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({task, comment}) =>
        this._taskApi.removeComment(comment.id).pipe(
          tapResponse(
            (response) => {
              const newTask = {...task};
              newTask.comments = [...response.payload];
              this.patchState({
                data: newTask,
                status: 'success',
                error: ''
              });
            }, error => {
              this._notification.create(
                'error',
                this._translate.instant('task_detail.notifications.remove_comment.error'),
                this._translate.instant('task_detail.notifications.remove_comment.error_content')
              );
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }, () => {
              this._notification.create(
                'success',
                this._translate.instant('task_detail.notifications.remove_comment.success'),
                this._translate.instant('task_detail.notifications.remove_comment.success_content')
              );
            }
          )
        )
      )
    )
  );

  readonly likeComment = this.effect<{ task: CfTask, comment: CfComment, userId: string }>( (params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({task, comment, userId}) =>
        this._taskApi.like(comment.id, userId).pipe(
          tapResponse(
            (response) => {
              const newTask = {...task};
              const newComments = [...newTask.comments];
              this._replaceComment(newComments, response.payload);
              newTask.comments = newComments;
              this.patchState({
                data: newTask,
                status: 'success',
                error: ''
              });
            }, error => {
              this._notification.create(
                'error',
                this._translate.instant('task_detail.notifications.like_comment.error'),
                this._translate.instant('task_detail.notifications.like_comment.error_content')
              );
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }, () => {
              this._notification.create(
                'success',
                this._translate.instant('task_detail.notifications.like_comment.success'),
                this._translate.instant('task_detail.notifications.like_comment.success_content')
              );
            }
          )
        )
      )
    )
  );

  readonly dislikeComment = this.effect<{ task: CfTask, comment: CfComment, userId: string }>( (params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({task, comment, userId}) =>
        this._taskApi.dislike(comment.id, userId).pipe(
          tapResponse(
            (response) => {
              const newTask = {...task};
              const newComments = [...newTask.comments];
              this._replaceComment(newComments, response.payload);
              newTask.comments = newComments;
              this.patchState({
                data: newTask,
                status: 'success',
                error: ''
              });
            }, error => {
              this._notification.create(
                'error',
                this._translate.instant('task_detail.notifications.dislike_comment.error'),
                this._translate.instant('task_detail.notifications.dislike_comment.error_content')
              );
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }, () => {
              this._notification.create(
                'success',
                this._translate.instant('task_detail.notifications.dislike_comment.success'),
                this._translate.instant('task_detail.notifications.dislike_comment.success_content')
              );
            }
          )
        )
      )
    )
  );

  private _replaceComment(comments: CfComment[], newComment: CfComment) {
    const index = this._getIndex(comments, newComment);
    if (index == -1) {
      comments.forEach(comment => {
        if (Array.isArray(comment.children)) {
          if (this._getIndex(comments, newComment) == -1) {
            this._replaceComment(comment.children, newComment);
          }
        }
      });
    } else {
      comments[index].likes = newComment.likes;
    }
  }

  private _getIndex(comments: CfComment[], newComment: CfComment) {
    return comments.findIndex(item => item.id === newComment.id);
  }

  private _filterTasksByRecurrenceId(task: CfTask, tasks: CfTask[], choice: ChoiceState) {
    let filteredTasks: Array<CfTask> = [];
    if (tasks) {
      if (choice.all) {
        filteredTasks = [...tasks.filter((x) => x.isRecurring && x.recurrence.id === task.recurrence.id)]
      } else if (choice.after) {
        filteredTasks = tasks.filter((x) =>
          x.dates.deliveryDate >= task.dates.deliveryDate
          && x.isRecurring
          && x.recurrence.id === task.recurrence.id
        );
      } else {
        filteredTasks = [task];
      }
    }
    return filteredTasks
      .map((x) => x.id);
  }

  constructor(
    private _store: Store,
    private _taskApi: TaskApi,
    private _route: ActivatedRoute,
    private _translate: TranslateService,
    private _notification: NzNotificationService
  ) {
    super(<TaskState>{});
  }
}
