import {CfTeam} from "@app-web-central/web/shared/data-access/models";

export class GroupUtil {
  static replaceGroupAndGetNewGroups(groups: CfTeam[] | null, group: CfTeam) {
    const newGroups = groups !== null ? [...groups] : [];
    const index = newGroups.findIndex((x) => x.id === group.id);
    if (index === -1) {
      newGroups.push(group);
    } else {
      newGroups[index] = group;
    }
    return newGroups;
  }

  static removeGroupFromGroups(groups: CfTeam[] | null, group: CfTeam) {
    const newGroups = groups !== null ? [...groups] : [];
    const index = newGroups.findIndex((x) => x.id === group.id);
    newGroups.splice(index, 1);
    return newGroups;
  }
}
