<nz-select
  class="w-full"
  nzPlaceHolder="{{ 'add_task.task_reporter_placeholder' | translate }}"
  [formControl]="control"
  [compareWith]="compareFn"
  [nzCustomTemplate]="selectedReporterTmpl"
  nzNoAnimation>
  <nz-option
    nzCustomContent
    *ngFor="let user of users"
    [nzValue]="user">
    <as-user [user]="user"></as-user>
  </nz-option>
</nz-select>

<ng-template #selectedReporterTmpl
             let-selected>
  <as-user [user]="getUser(selected.nzValue.id)"></as-user>
</ng-template>
