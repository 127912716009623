import {createAction, props} from "@ngrx/store";
import {CfChannel} from "@app-web-central/web/shared/data-access/models";

export const loadChannels = createAction(
  '[Channel Object] Load Channel'
);

export const loadChannelsSuccess = createAction(
  '[Channel Object] Load Channel Success',
  props<{
    channels: CfChannel[] | null
  }>()
);

export const loadChannelsError = createAction(
  '[Channel Object] Load Channel Error',
  props<{
    error: string
  }>()
);


export const loadChannel = createAction(
  '[Channel Object] Load Channel',
  props<{
    channelId: string
  }>()
);

export const loadChannelSuccess = createAction(
  '[Channel Object] Load Channel Success',
  props<{
    channel: CfChannel | null
  }>()
);

export const loadChannelError = createAction(
  '[Channel Object] Load Channel Error',
  props<{
    error: string
  }>()
);

export const startWatchingChannel = createAction(
  '[Channel Object] Start Watching Channel',
  props<{
    channelId: string,
    userId: string
  }>()
);

export const startWatchingChannelSuccess = createAction(
  '[Channel Object] Start Watching Channel Success',
  props<{
    channel: CfChannel
  }>()
);

export const startWatchingChannelError = createAction(
  '[Channel Object] Start Watching Channel Error',
  props<{
    error: string
  }>()
);

export const stopWatchingChannel = createAction(
  '[Channel Object] Stop Watching Channel',
  props<{
    channelId: string,
    userId: string
  }>()
);

export const stopWatchingChannelSuccess = createAction(
  '[Channel Object] Stop Watching Channel Success',
  props<{
    channel: CfChannel
  }>()
);

export const stopWatchingChannelError = createAction(
  '[Channel Object] Stop Watching Channel Error',
  props<{
    error: string
  }>()
);
