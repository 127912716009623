import {CfProject, CfProjectMember, CfUser} from "@app-web-central/web/shared/data-access/models";

export class ProjectUtil {
  static replaceProjectAndGetNewProjects(projects: CfProject[] | null, project: CfProject) {
    const newProjects = projects !== null ? [...projects] : [];
    const index = newProjects.findIndex((x) => x.id === project.id);
    if (index === -1) {
      newProjects.push(project);
    } else {
      newProjects[index] = project;
    }
    return newProjects;
  }

  static removeProjectFromActiveProjects(projects: CfProject[] | null, project: CfProject) {
    const newProjects = projects !== null ? [...projects] : [];
    const index = newProjects.findIndex((x) => x.id === project.id);
    newProjects.splice(index, 1);
    return newProjects;
  }

  static replaceProjectLeadAndDefaultAssignee(project: CfProject, users: CfUser[] | null) {
    project.projectLead = users?.find((x) => x.id === project.projectLeadId);
    project.defaultAssignee = users?.find((x) => x.id === project.defaultAssigneeId);
  }

  static buildUserAccessUIObject(projectMember: CfProjectMember, users: CfUser[] | undefined) {
    const user = users?.find((y) => y.id === projectMember.members);
    return {
      fullName: user?.fullName,
      email: user?.email,
      role: projectMember.role,
      imageUrl: user?.avatarUrl,
    };
  }
}
