import {CfRecipient, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {loadRecipients, loadRecipientsError, loadRecipientsSuccess} from "./recipients.actions";

export interface RecipientsState extends GenericState<CfRecipient[]> { }

const initialState: RecipientsState = {
  data: null,
  status: 'pending',
  error: null
}

export const recipientsFeatureKey = 'recipients';

export const recipientsReducer = createReducer(
  initialState,
  on(loadRecipients, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadRecipientsSuccess, (state, { recipients }) => ({
    ...state,
    data: recipients,
    status: 'success',
    error: null
  })),
  on(loadRecipientsError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
)
