import {Injectable} from "@angular/core";
import {NotificationApi} from "@app-web-central/web/shared/data-access/cf-api";
import {CfContactUs, CfFeedback} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private _notificationApi: NotificationApi
  ) { }

  /**
   * Load the last 10 notifications for the current user session.
   *
   */
  loadLast10notifications(userId: string, count: number) {
    return this._notificationApi.findLoad10ByUserId(userId, count);
  }

  /**
   * Read one notification by notification id.
   *
   * @param notificationId uuid as { string }.
   */
  readOneNotification(notificationId: string) {
    return this._notificationApi.read(notificationId);
  }

  /**
   * Send feedback to CFCube support.
   *
   * @param feedback uuid as { CfFeedback }.
   */
  feedback(feedback: CfFeedback) {
    return this._notificationApi.feedback(feedback);
  }

  /**
   * Send contact us to CFCube support.
   *
   * @param contactUs uuid as { CfContactUs }.
   */
  contactUs(contactUs: CfContactUs) {
    return this._notificationApi.contactUs(contactUs);
  }

}
