<form [formGroup]="quickSettingsForm">

  <label class="label">
    {{ 'drawers.quick_settings.language_title' | translate | uppercase }}
  </label>

  <div class="form-group">
    <label class="label" for="languages">
      {{ 'drawers.quick_settings.language' | translate }}
    </label>

    <nz-select #nzLanguages
               id="languages"
               class="w-full"
               formControlName="preferredLanguage"
               [compareWith]="compareFn"
               nzShowSearch
               (ngModelChange)="onChangeLanguage(nzLanguages)" >
      <nz-option *ngFor="let language of languages$ | async"
                 nzLabel=" {{ 'languages.' + language.code | translate }} "
                 [nzValue]="language">
        {{ 'languages.' + language.code | translate }}
      </nz-option>
    </nz-select>
  </div>

  <div class="form-group mt-3">
    <label class="label" for="countries">
      {{ 'drawers.quick_settings.country' | translate }}
    </label>

    <nz-select id="countries"
               class="w-full"
               formControlName="country"
               [compareWith]="compareFn"
               nzShowSearch
               (ngModelChange)="onChange()" >
      <nz-option *ngFor="let country of countries$ | async"
                 nzLabel=" {{ 'countries.' + country.isoCode | translate }} "
                 [nzValue]="country">
        {{ 'countries.' + country.isoCode | translate }}
      </nz-option>
    </nz-select>
  </div>

  <div class="form-group mt-3">
    <label class="label" for="timezones">
      {{ 'drawers.quick_settings.timezone' | translate }}
    </label>

    <nz-select id="timezones"
               class="w-full"
               formControlName="timezone"
               nzShowSearch
               (ngModelChange)="onChange()" >
      <nz-option *ngFor="let timezone of timezones"
                 nzLabel=" {{ timezone }} "
                 [nzValue]="timezone">
        {{ timezone }}
      </nz-option>
    </nz-select>
  </div>

  <div class="form-group mt-3">
    <label class="label" for="date-format">
      {{ 'drawers.quick_settings.date_format' | translate }}
    </label>

    <nz-select id="date-format"
               class="w-full"
               formControlName="dateFormat"
               nzShowSearch
               (ngModelChange)="onChange()" >
      <nz-option *ngFor="let dateFormat of selectedDateFormats"
                 nzLabel=" {{ dateFormat }} "
                 [nzValue]="dateFormat">
        {{ dateFormat }}
      </nz-option>
    </nz-select>
  </div>

  <div class="form-group mt-3">
    <label class="label" for="full-date-format">
      {{ 'drawers.quick_settings.date_format_full' | translate }}
    </label>

    <nz-select id="full-date-format"
               class="w-full"
               formControlName="dateFormatFull"
               nzShowSearch
               (ngModelChange)="onChange()" >
      <nz-option *ngFor="let fullDateFormat of selectedFullDateFormats"
                 nzLabel=" {{ fullDateFormat }} "
                 [nzValue]="fullDateFormat">
        {{ fullDateFormat }}
      </nz-option>
    </nz-select>
  </div>

  <div class="form-group mt-3">
    <label class="label" for="time-format">
      {{ 'drawers.quick_settings.time_format' | translate }}
    </label>

    <nz-select id="time-format"
               class="w-full"
               formControlName="timeFormat"
               nzShowSearch
               (ngModelChange)="onChange()" >
      <nz-option *ngFor="let timeFormat of timeFormats"
                 nzLabel=" {{ timeFormat }} "
                 [nzValue]="timeFormat">
        {{ timeFormat }}
      </nz-option>
    </nz-select>
  </div>

  <div class="form-group mt-3 switch-end">
    <nz-switch id="meridian" nzSize="small" formControlName="meridian"
               (ngModelChange)="onChange()"></nz-switch>
    <label class="ml-2 label-no-p" for="meridian">
      {{ 'drawers.quick_settings.meridian' | translate }}
    </label>
  </div>

  <div class="divider"></div>

  <label class="label">
    {{ 'drawers.quick_settings.themes' | translate | uppercase }}
  </label>

  <div class="quick-settings-info-panel">
    <i nz-icon nzType="question-circle" nzTheme="outline" class="mr-2"></i>
    <p class="w-full text-textMedium text-13">
      {{ 'drawers.quick_settings.themes_info' | translate }}
    </p>
  </div>

  <nz-radio-group formControlName="userAppTheme" class="flex items-center flex-row mt-3"
                  (ngModelChange)="onChange()">
    <div class="radio-group-item">
      <img src="assets/images/light_preview.svg" alt="option-light">
      <label nz-radio nzValue="LIGHT">{{ 'drawers.quick_settings.themes_light' | translate }}</label>
    </div>
    <div class="radio-group-item">
      <img src="assets/images/dark_preview.svg" alt="option-dark">
      <label nz-radio nzValue="DARK">{{ 'drawers.quick_settings.themes_dark' | translate }}</label>
    </div>
  </nz-radio-group>

  <div class="divider"></div>

  <label class="label">
    {{ 'drawers.quick_settings.vehicles' | translate | uppercase }}
  </label>

  <div class="quick-settings-info-panel">
    <i nz-icon nzType="question-circle" nzTheme="outline" class="mr-2"></i>
    <p class="w-full text-textMedium text-13">
      {{ 'drawers.quick_settings.vehicles_info' | translate }}
    </p>
  </div>

  <div class="form-group mt-3">
    <label class="label" for="vehicle">
      {{ 'drawers.quick_settings.vehicle' | translate }}
    </label>

    <nz-select id="vehicle"
               class="w-full"
               formControlName="userVehicle"
               nzShowSearch
               (ngModelChange)="onChange()">
      <nz-option *ngFor="let vehicle of vehicles$ | async"
                 nzLabel=" {{ vehicle.label | translate }} "
                 [nzValue]="vehicle.value">
        {{ vehicle.label | translate }}
      </nz-option>
    </nz-select>
  </div>

</form>
