export enum Priority {
  LOWEST = 'LOWEST',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  HIGHEST = 'HIGHEST'
}

export const PriorityColors = {
  [Priority.HIGHEST]: '#CD1317',
  [Priority.HIGH]: '#E9494A',
  [Priority.MEDIUM]: '#E97F33',
  [Priority.LOW]: '#57A55A',
  [Priority.LOWEST]: '#57A55A'
};

export const PriorityDisplayValues = {
  [Priority.HIGHEST]: 'priorities.highest',
  [Priority.HIGH]: 'priorities.high',
  [Priority.MEDIUM]: 'priorities.medium',
  [Priority.LOW]: 'priorities.low',
  [Priority.LOWEST]: 'priorities.lowest',
};

export const PriorityIconValues = {
  [Priority.HIGHEST]: 'solid/chevron-double-up',
  [Priority.HIGH]: 'solid/chevron-up',
  [Priority.MEDIUM]: 'solid/chevron-up',
  [Priority.LOW]: 'solid/chevron-down',
  [Priority.LOWEST]: 'solid/chevron-double-down',
};

export class PriorityValue {
  value: Priority;
  text: string;
  color: string;
  icon: string;
  constructor(priority: Priority) {
    this.value = priority;
    this.text = PriorityDisplayValues[priority];
    this.color = PriorityColors[priority];
    this.icon = PriorityIconValues[priority];
  }
}

export const PriorityValues = [
  new PriorityValue(Priority.HIGHEST),
  new PriorityValue(Priority.HIGH),
  new PriorityValue(Priority.MEDIUM),
  new PriorityValue(Priority.LOW),
  new PriorityValue(Priority.LOWEST)
];
