import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CfTaskMetadata} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-task-metadata',
  templateUrl: './task-metadata.component.html',
  styleUrls: ['./task-metadata.component.scss'],
})
export class TaskMetadataComponent {
  @Input() controlKey!: FormControl;
  @Input() controlValue!: FormControl;
  @Output() metadataChanged = new EventEmitter<CfTaskMetadata>();

  onSaveMetadataForm() {
    this.metadataChanged.emit({
      key: this.controlKey.value,
      value: this.controlValue.value,
    });
    this.reset();
  }

  private reset() {
    this.controlKey.reset();
    this.controlValue.reset();
  }
}
