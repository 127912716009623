import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterDesktopComponent} from './footer-desktop.component';
import {TranslateModule} from "@ngx-translate/core";
import {NzModalModule} from "ng-zorro-antd/modal";

@NgModule({
  imports: [
    CommonModule,
    NzModalModule,
    TranslateModule,
  ],
  declarations: [
    FooterDesktopComponent
  ],
  exports: [
    FooterDesktopComponent
  ],
})
export class FooterDesktopModule {
}
