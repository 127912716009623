import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {HttpClientModule} from "@angular/common/http";
import {TranslateModule} from "@ngx-translate/core";
import {WebLegalModule} from "@app-web-central/web/shell/feature";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule,
    WebLegalModule
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {
}
