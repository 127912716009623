import {CfAddress} from "@app-web-central/web/shared/data-access/models";

export class AddressUtil {
  static replaceAddressAndGetNewAddresses(addresses: CfAddress[] | null, address: CfAddress) {
    const newAddresses = addresses !== null ? [...addresses] : [];
    const index = newAddresses.findIndex((x) => x.id === address.id);
    if (index === -1) {
      newAddresses.push(address);
    } else {
      newAddresses[index] = address;
    }
    return newAddresses;
  }

  static removeAddressFromAddresses(addresses: CfAddress[] | null, address: CfAddress) {
    const newAddresses = addresses !== null ? [...addresses] : [];
    const index = newAddresses.findIndex((x) => x.id === address.id);
    newAddresses.splice(index, 1);
    return newAddresses;
  }
}
