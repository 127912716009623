import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import * as SettingsActions from './settings.actions';
import {SettingsService} from "../services";
import {SettingsState} from "./settings.reducer";
import {getLanguages} from "./settings.selectors";

@Injectable()
export class SettingsFacade {
  readonly languages$ = this._store.pipe(select(getLanguages));
  constructor(
    private _store: Store<SettingsState>,
    private _settingsService: SettingsService
  ) { }

  public init() {
    this._settingsService.loadSettingsData().subscribe(settings => {
      if (settings) {
        this._store.dispatch(SettingsActions.loadSettingsSuccess({...settings}));
      }
    });
  }

}
