<button
  [type]="type"
  [ngClass]="['btn', className, isActive ? 'is-active': '', content?.innerHTML?.trim() ? '': 'icon-only']"
  [disabled]="disabled">
  <svg-icon
    class="!flex !items-center {{ iconColor }}"
    *ngIf="!isWorking && icon && !hasBadge"
    [key]="icon"
    [size]="size">
  </svg-icon>
  <svg-icon
    *ngIf="isWorking"
    key="outline/spin"
    class="!flex !items-center spinner mr-1.5 {{ iconColor }}"
    [size]="size">
  </svg-icon>
  <span
    #content
    class="flex items-center"
    [class.with-padding]="isWorking || icon">
    <ng-content></ng-content>
  </span>
  <svg-icon
    *ngIf="isDropdown"
    class="!flex !items-center"
    [ngClass]="iconColor"
    key="mini/chevron-down">
  </svg-icon>
  <svg-icon
    *ngIf="isCombobox"
    class="absolute inset-y-2.5 right-0 flex items-center mr-2.5"
    [ngClass]="iconColor"
    key="mini/chevron-up-down">
  </svg-icon>
  <nz-badge
    *ngIf="hasBadge"
    [nzCount]="countValue">
    <svg-icon
      *ngIf="!isWorking && icon"
      class="!flex !items-center {{ iconColor }}"
      [key]="icon"
      [size]="size">
    </svg-icon>
  </nz-badge>
</button>
