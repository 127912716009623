<div *nzModalTitle>
  {{ 'modals.folder.title' | translate }}
</div>

<div>
  <form [formGroup]="folderForm">
    <div class="form-group">
      <input
        type="text"
        id="folder_name"
        class="form-input"
        placeholder="{{ 'modals.folder.folder_name_placeholder' | translate }}"
        formControlName="name"/>
    </div>
  </form>
</div>

<div *nzModalFooter>
  <as-button
    className="primary mr-2"
    type="submit"
    (click)="handleOk()"
    [disabled]="folderForm.invalid">
    {{ 'buttons.save' | translate }}
  </as-button>
  <as-button
    className="tertiary"
    type="reset"
    (click)="handleCancel()">
    {{ 'buttons.cancel' | translate }}
  </as-button>
</div>

