import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AvatarComponent} from './avatar.component';
import {NzAvatarModule} from "ng-zorro-antd/avatar";
import {InitialPipeModule} from "@app-web-central/web/shared/pipes/initial-pipe";

@NgModule({
  imports: [
    CommonModule,
    NzAvatarModule,
    InitialPipeModule
  ],
  declarations: [
    AvatarComponent
  ],
  exports: [
    AvatarComponent
  ],
})
export class AvatarModule {

}
