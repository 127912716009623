import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {
  CfUser,
  UserStatusValue, UserStatusValues,
  UserStatusValuesDisplay
} from "@app-web-central/web/shared/data-access/models";
import {NzModalRef} from "ng-zorro-antd/modal";
import {UserStore} from "@app-web-central/web/user/data-access";

@Component({
  selector: 'as-modal-user-status',
  templateUrl: './modal-user-status.component.html',
  styleUrls: ['./modal-user-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserStore]
})
export class ModalUserStatusComponent implements OnInit {
  @Input() session!: CfUser;

  statuses = UserStatusValuesDisplay;
  currentStatus = UserStatusValues as any;
  statusSelected!: UserStatusValue | null;

  constructor(
    private _modal: NzModalRef,
    private _userStore: UserStore
  ) { }

  ngOnInit(): void {
    this._updateUserStatus();
  }

  _updateUserStatus() {
    if (this.session.accountStatus) {
      this.statuses.forEach(status => {
        if (status.status === this.session.accountStatus) {
          status.active = true;
          this.statusSelected = status;
        }
      });
    }
  }

  closeModal() {
    this._modal.close();
  }

  removeStatus() {
    this.statusSelected = null;
    this._updateStatus();
  }

  onSetUserStatus(status: UserStatusValue) {
    this.statuses.forEach(userStatus => {
      userStatus.active = false;
      if (userStatus.value === status.value) {
        userStatus.active = true;
        this.statusSelected = userStatus;
      }
    });
  }

  submit() {
    if (!this.statusSelected) {
      return;
    }

    this._updateStatus();
    this.closeModal();
  }

  _updateStatus() {
    const newValue = {...this.session};
    newValue.accountStatus = this.statusSelected !== null ? this.statusSelected.status : null;
    this._userStore.updateStatus(newValue);
  }

}
