<div *ngIf="!fullScreen" class="h-full w-full flex justify-center items-center flex-col">
  <i nz-icon nzType="loading" class="{{iconSize}} {{color}}"></i>
</div>

<div *ngIf="fullScreen" class="h-screen w-full bg-purple-600 flex justify-center items-center flex-col">
  <div class="w-[500px] text-white text-center">
    <div class="w-[150px] m-auto">
      <img src="assets/images/pendora-cubes-10-white.svg" alt="cube by pendoralab">
    </div>
    <p class="mb-2">{{ 'buttons.loading' | translate }}</p>
    <i nz-icon nzType="loading" class="text-4xl"></i>
  </div>
</div>
