import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {NavItem} from "@app-web-central/web/shared/data-access/models";
import {switchMap, take, tap} from "rxjs";

export interface topBarItems {
  isActive: boolean;
  data: Set<string>;
}

interface UIState {
  asideNavItems: NavItem[];
  leftNavItems: NavItem[];
  leftNavTopItems: NavItem[];
  topBarActive: topBarItems;
}

/**
 * If the state needs to be cleaned up when you change the URL that goes in component store.
 * We use store if the state needs to persist when you change the URL ....
 */
@Injectable({ providedIn: 'root' })
export class MenuItemStore extends ComponentStore<UIState> {
  topBarActive$ = this.select(({ topBarActive }) => topBarActive);
  readonly asideNavItems$ = this.select(({ asideNavItems }) => asideNavItems);
  readonly leftNavItems$ = this.select(({ leftNavItems }) => leftNavItems);
  readonly leftNavTopItems$ = this.select(({ leftNavTopItems }) => leftNavTopItems);

  updateTopBarStatus$ = this.effect<topBarItems>((params$) =>
    params$.pipe(
      switchMap((topBarItems) => this.topBarActive$
        .pipe(
          take(1),
          tap(() => {
            this.patchState({
              topBarActive: topBarItems
            });
          })
        )
      )
    )
  );

  constructor(
  ) {
    super({
      topBarActive: {
        isActive: false,
        data: new Set<string>()
      },
      asideNavItems: [],
      leftNavTopItems: [],
      leftNavItems: [],
    });
  }

}
