import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HeaderApi} from "./header-api";
import {HttpClient} from "@angular/common/http";
import {CfChannel, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class ChannelApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) { }

  get(channelId: string) {
    return this._http.get<ResponseState<CfChannel>>(
      `${this._appConfig.channelV1Url}manage/channels/${channelId}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  list() {
    return this._http.get<ResponseState<CfChannel[]>>(
      `${this._appConfig.channelV1Url}manage/channels`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  startWatchingChannel(channelId: string, userId: string) {
    return this._http.get<ResponseState<CfChannel>>(
      `${this._appConfig.channelV1Url}manage/channels/${channelId}/subscribe/${userId}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      });
  }

  stopWatchingChannel(channelId: string, userId: string) {
    return this._http.get<ResponseState<CfChannel>>(
      `${this._appConfig.channelV1Url}manage/channels/${channelId}/unsubscribe/${userId}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      });
  }

}
