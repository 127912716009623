import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {loadTasks, TasksState} from "@app-web-central/web/task/data-access";

@Injectable({ providedIn: 'root' })
export class TasksFacade {
  constructor(
    private _store: Store<TasksState>,
  ) { }

  init() {
    this._store.dispatch(loadTasks());
  }
}
