import {CfRRule} from "./rrule";

export enum CfReminderPriority {
  NONE = 'NONE',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export const ReminderPriorityDisplay = {
  [CfReminderPriority.NONE]: 'none',
  [CfReminderPriority.LOW]: 'low',
  [CfReminderPriority.MEDIUM]: 'medium',
  [CfReminderPriority.HIGH]: 'high',
}

export class ReminderPriorityValue {
  value: CfReminderPriority;
  label: string;
  constructor(reminderPriority: CfReminderPriority) {
    this.value = reminderPriority;
    this.label = ReminderPriorityDisplay[reminderPriority];
  }
}

export interface CfReminder {
  id: string;
  title: string;
  message: string;
  dueDate: number;
  rrule: CfRRule;
  priority: string;
  enabled: boolean;
  isAction: boolean;
  action: string;
  component: string;
  dateAdd: number;
  dateUpd: number;
  client: string;
  rooms: string[];
  referenceObject: string; // task or incident id etc...
}
