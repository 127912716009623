import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import * as moment from 'moment-timezone';
import {TranslateService} from "@ngx-translate/core";
import {getCountries, getLanguages, VehicleStore} from "@app-web-central/web/settings/data-access";
import {getSession, SessionFacade} from "@app-web-central/web/session/data-access";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {
  CfUser,
  CfLanguage,
  CfUserSettings,
  DateFormats,
  FullDateFormats,
  TimeFormatValues
} from "@app-web-central/web/shared/data-access/models";
import {take} from "rxjs";
import {NzI18nService} from "ng-zorro-antd/i18n";
import {I18nUtil} from "@app-web-central/web/shared/utils";


@UntilDestroy()
@Component({
  selector: 'as-drawer-quick-settings',
  templateUrl: './drawer-quick-settings.component.html',
  styleUrls: ['./drawer-quick-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerQuickSettingsComponent implements OnInit {
  readonly timezones = moment.tz.names();
  readonly countries$ = this._store.select(getCountries);
  readonly languages$ = this._store.select(getLanguages);
  readonly session$ = this._store.select(getSession);
  readonly vehicles$ = this._vehicleStore.vehicles$;
  readonly timeFormats = TimeFormatValues;

  session!: CfUser;
  quickSettingsForm!: FormGroup;
  selectedDateFormats!: string[];
  selectedFullDateFormats!: string[];

  compareFn = (o1: any, o2: any) => (o1 && o2 ? o1.id === o2.id : o1 === o2);

  constructor(
    private _store: Store,
    private _i18n: NzI18nService,
    private _formBuilder: FormBuilder,
    private _vehicleStore: VehicleStore,
    private _translate: TranslateService,
    private _sessionFacade: SessionFacade
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.session$.pipe(untilDestroyed(this), take(1)).subscribe(session => {
      if (session) {
        this.session = {...session} as CfUser;
        const settings = this.session.settings;
        this.updateForm(settings);
      }
    });
  }

  private initForm() {
    this.quickSettingsForm = this._formBuilder.group({
      preferredLanguage: [null, [Validators.required]],
      country: [null, [Validators.required]],
      timezone: [null, [Validators.required]],
      userVehicle: ['CAR', [Validators.required]],
      userAppTheme: [null, [Validators.required]],
      dateFormat: [null, [Validators.required]],
      dateFormatFull: [null, [Validators.required]],
      timeFormat: [null, [Validators.required]],
      meridian: [null, [Validators.required]]
    });
  }

  private updateForm(settings: CfUserSettings) {
    this.runtimeDateFormats(settings.preferredLanguage.code);
    this.quickSettingsForm.patchValue(settings);
  }

  private runtimeDateFormats(isoCode: string) {
    const shortFormat = DateFormats.find(item => item.isoCode === isoCode);
    const longFormat = FullDateFormats.find(item => item.isoCode === isoCode);
    if (shortFormat && longFormat) {
      this.selectedDateFormats = shortFormat.values;
      this.selectedFullDateFormats = longFormat.values;
    }
  }

  onChangeLanguage(value: {activatedValue: CfLanguage}) {
    this._translate.use(value.activatedValue.code);
    this._i18n.setLocale(I18nUtil.getLocaleLanguage(value.activatedValue.code));
    this.runtimeDateFormats(value.activatedValue.code);
    this.onChange();
  }

  onChange() {
    if (this.quickSettingsForm.invalid) {
      return;
    }

    const formValue = this.quickSettingsForm.getRawValue();
    const newSession = {...this.session};
    newSession.settings = {...formValue};
    this._sessionFacade.setSession(newSession);
  }
}
