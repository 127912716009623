export enum LicenseEnum {
  SMALL_BUSINESS = "SMALL_BUSINESS",
  BUSINESS = "BUSINESS",
  ENTERPRISE = "ENTERPRISE",
}

export const LicenseDisplayValues = {
  [LicenseEnum.SMALL_BUSINESS]: {title: 'subscription.plans.upgrade_small_business', description: 'subscription.plans.upgrade_small_business_description'},
  [LicenseEnum.BUSINESS]: {title: 'subscription.plans.upgrade_business', description: 'subscription.plans.upgrade_business_description'},
  [LicenseEnum.ENTERPRISE]: {title: 'subscription.plans.upgrade_enterprise', description: 'subscription.plans.upgrade_enterprise_description'}
};

export interface CfLicenseFeature {
  id: string;
  dateAdd: number;
  dateUpd: number;
  enabled: boolean;
  name: string;
  description: string;
  quantityMax: number;
  featureType: string;
  translationKey: string;
}

export interface CfLicensePricing {
  id: string;
  dateAdd: number;
  dateUpd: number;
  enabled: boolean;
  stripePriceId: string;
  billingInterval: string;
  pricePerUnit: number;
}

export interface CfLicense {
  id: string;
  dateAdd: number;
  dateUpd: number;
  enabled: boolean;
  name: string;
  description: string;
  plan: LicenseEnum;
  monthlyPrice: number;
  yearlyPrice: number;
  features: CfLicenseFeature[];
  pricings: CfLicensePricing[];
}
