import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HeaderApi} from "./header-api";
import {HttpClient} from "@angular/common/http";
import {CfProject, CfUser, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class ProjectApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) {}

  get(projectId: string) {
    return this._http.get<ResponseState<CfProject>>(
      `${this._appConfig.projectV1Url}manage/projects/${projectId}`,{
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  getByKey(projectKey: string) {
    return this._http.get<ResponseState<CfProject>>(
      `${this._appConfig.projectV1Url}manage/projects/key/${projectKey}`,{
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  list() {
    return this._http.get<ResponseState<CfProject[]>>(
      `${this._appConfig.projectV1Url}manage/projects`,{
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  create(project: CfProject) {
    return this._http.post<ResponseState<CfProject>>(
      `${this._appConfig.projectV1Url}manage/projects`, project, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      },
    );
  }

  update(projectId: string, project: CfProject) {
    return this._http.put<ResponseState<CfProject>>(
      `${this._appConfig.projectV1Url}manage/projects/${projectId}`, project, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      },
    );
  }

  delete(projectId: string) {
    return this._http.delete<ResponseState<string>>(
      `${this._appConfig.projectV1Url}manage/projects/${projectId}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      },
    );
  }

  invite(user: CfUser | any, projectId: string) {
    return this._http.post<ResponseState<CfProject>>(
      `${this._appConfig.projectV1Url}manage/projects/${projectId}/invite`, user, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      },
    );
  }

  accept(projectId: string, validationCode: string) {
    return this._http.get<ResponseState<string>>(
      `${this._appConfig.projectV1Url}manage/projects/${projectId}/accept-invitation/${validationCode}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      },
    );
  }

}
