import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {FoldersState} from "./folders.reducer";
import {loadFolders} from "./folders.actions";

@Injectable({ providedIn: 'root' })
export class FoldersFacade {
  constructor(
    private _store: Store<FoldersState>,
  ) { }

  init() {
    this._store.dispatch(loadFolders());
  }

}
