import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {loadGroups} from "./groups.actions";
import {GroupsState} from "./groups.reducer";
import {getGroups} from "./groups.selectors";

@Injectable({ providedIn: 'root' })
export class GroupsFacade {
  readonly groups$ = this._store.pipe(select(getGroups));

  constructor(
    private _store: Store<GroupsState>
  ) { }

  init() {
    this._store.dispatch(loadGroups());
  }

}
