<div class="mt-3">
  <swiper
    [config]="config">
    <ng-template
      swiperSlide
      *ngFor="let file of files; let i = index">
      <div class="carousel-item-container">
        <div class="carousel-item-image">
          <svg-icon
            *ngIf="file.fileType === 'pdf'"
            key="outline/document">
          </svg-icon>
          <img
            *ngIf="file.fileType !== 'pdf'"
            [src]="sanitizer.bypassSecurityTrustUrl(file.fileUrl)"
            alt="image slide" class="image-carousel" />
          <div class="carousel-item-caption">
            <h6 class="text-truncate">{{ file.title }}</h6>
          </div>
        </div>

        <div class="carousel-item-buttons">
          <svg-icon
            size="sm"
            key="outline/trash"
            class="!flex !items-center mr-1"
            (click)="onDeleteFile(file, i)">
          </svg-icon>
          <svg-icon
            size="sm"
            key="outline/document-arrow-down"
            class="!flex !items-center mr-1"
            (click)="onDownloadFile(file)">
          </svg-icon>
          <svg-icon
            size="sm"
            key="outline/eye"
            class="!flex !items-center"
            (click)="onViewFile(file)">
          </svg-icon>
        </div>
      </div>
    </ng-template>
  </swiper>

  <div *ngIf="files && !files.length">
    <nz-empty nzNotFoundImage="simple"></nz-empty>
  </div>
</div>
