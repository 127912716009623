import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BadgeComponent} from './badge.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [
    BadgeComponent
  ],
  exports: [
    BadgeComponent
  ],
})
export class BadgeModule {
}
