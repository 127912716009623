import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CfAddress} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-task-suite-select',
  templateUrl: './task-suite-select.component.html',
  styleUrls: ['./task-suite-select.component.scss'],
})
export class TaskSuiteSelectComponent {
  @Input() control!: FormControl;
  @Input() address!: CfAddress;
}
