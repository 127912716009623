import {createAction, props} from "@ngrx/store";
import {CfTeam} from "@app-web-central/web/shared/data-access/models";

export const loadGroups = createAction(
  '[Group List] Load Groups'
);

export const loadGroupsSuccess = createAction(
  '[Group List] Load Groups Success',
  props<{
    groups: CfTeam[]
  }>()
);

export const loadGroupsError = createAction(
  '[Group List] Load Groups Error',
  props<{
    error: string
  }>()
);
