<div class="mt-8">
  <h2 class="pb-2 text-sm font-medium text-gray-900">
    {{ 'task_detail.address' | translate }}
  </h2>

  <div class="w-full">
    <as-combobox
      [props]="['address1', 'zipCode', 'city']"
      [searchProp]="'address1'"
      placeholder="{{ 'task_detail.add_address' | translate }}"
      [items]="addresses"
      [removable]="false"
      [disabled]="!!task.dates?.validationDate"
      [selectedItem]="control.value"
      (activeItem)="onSelectAddress($event)" >
    </as-combobox>
  </div>

</div>
