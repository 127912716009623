import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {CfTeam, GenericState} from "@app-web-central/web/shared/data-access/models";
import {filter, map, Observable, switchMap, tap, withLatestFrom} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {GroupApi} from "@app-web-central/web/shared/data-access/cf-api";
import {select, Store} from "@ngrx/store";
import {getGroups, GroupsState, loadGroupsSuccess} from "../groups";
import {TranslateService} from "@ngx-translate/core";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {GroupUtil, SelectorUtil} from "@app-web-central/web/shared/utils";

interface GroupState extends GenericState<CfTeam> {
  groupId: string;
}

@Injectable({ providedIn: 'root' })
export class GroupStore extends ComponentStore<GroupState> {
  groups$ = this._store.pipe(select(getGroups));
  isGroupLoading$ = this.select(SelectorUtil.isLoading);

  groupIdParams$: Observable<string> = this._route.params.pipe(
    map((params) => params["groupId"]),
    filter((userId: string) => !!userId)
  );

  group$ = this.groupIdParams$.pipe(
    tap((groupId) => {
      this.patchState({
        groupId
      });
      this.loadGroup({ groupId });
    }),
    switchMap(() => this.select((s) => s.data))
  );

  loadGroup = this.effect<{ groupId: string }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({ groupId }) =>
        this._groupApi.get(groupId).pipe(
          tapResponse(
            (response) => {
              const group = { ...response.payload };
              this.patchState({
                data: group,
                status: 'success',
                error: ''
              });
            },
            (error) => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }
          )
        )
      )
    )
  );

  update = this.effect<{ group: CfTeam }>((params$) => (
      params$.pipe(
        withLatestFrom(this.groups$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ group }, groups]) =>
          this._groupApi.update(group.id, group)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'save');
                  const newGroups = groups !== null ? [...groups] : [];
                  const index = newGroups.findIndex((item) => item.id === response.payload.id);
                  newGroups[index] = {...response.payload};
                  this._store.dispatch(
                    loadGroupsSuccess({
                      groups: newGroups
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'save');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  create = this.effect<CfTeam>((params$) => (
      params$.pipe(
        withLatestFrom(this.groups$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([group, groups]) =>
          this._groupApi.create(group)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'create');
                  const newGroups = groups !== null ? [...groups] : [];
                  newGroups.push(response.payload);
                  this._store.dispatch(
                    loadGroupsSuccess({
                      groups: newGroups
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'create');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  delete = this.effect<{ group: CfTeam }>((params$) => (
      params$.pipe(
        withLatestFrom(this.groups$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ group }, groups]) =>
          this._groupApi.delete(group.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'delete');
                  const newGroups = GroupUtil.removeGroupFromGroups(groups, group);
                  this._store.dispatch(
                    loadGroupsSuccess({
                      groups: newGroups
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'delete');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  enable = this.effect<{ group: CfTeam }>((params$) => (
      params$.pipe(
        withLatestFrom(this.groups$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ group }, groups]) =>
          this._groupApi.enable(group.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'enable');
                  const newGroups = GroupUtil.replaceGroupAndGetNewGroups(groups, response.payload);
                  this._store.dispatch(
                    loadGroupsSuccess({
                      groups: newGroups
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'enable');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  disable = this.effect<{ group: CfTeam }>((params$) => (
      params$.pipe(
        withLatestFrom(this.groups$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ group }, groups]) =>
          this._groupApi.disable(group.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'disable');
                  const newGroups = GroupUtil.replaceGroupAndGetNewGroups(groups, response.payload);
                  this._store.dispatch(
                    loadGroupsSuccess({
                      groups: newGroups
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'disable');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  private _notify(state: string, action: string) {
    this._notification.create(
      state,
      this._translate.instant(`notifications.${state}.group.${action}.title`),
      this._translate.instant(`notifications.${state}.group.${action}.subtitle`)
    );
  }

  constructor(
    private _groupApi: GroupApi,
    private _route: ActivatedRoute,
    private _store: Store<GroupsState>,
    private _translate: TranslateService,
    private _notification: NzNotificationService
  ) {
    super(<GroupState>{});
  }
}
