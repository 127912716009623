export enum FeedbackOption {
  BILLING = 'BILLING',
  ACCOUNT = 'ACCOUNT',
  PLATFORM = 'PLATFORM',
  MOBILE = 'MOBILE',
  STORAGE = 'STORAGE',
}

type CfFeedbackOption = {
  [key: string]: any;
}

export const FeedbackOptionDisplay = {
  fr: {
    [FeedbackOption.BILLING]: 'Facturation',
    [FeedbackOption.ACCOUNT]: 'Compte',
    [FeedbackOption.PLATFORM]: 'Plateforme',
    [FeedbackOption.MOBILE]: 'App. mobile',
    [FeedbackOption.STORAGE]: 'Stockage',
  },
  en: {
    [FeedbackOption.BILLING]: 'Billing',
    [FeedbackOption.ACCOUNT]: 'Account',
    [FeedbackOption.PLATFORM]: 'Platform',
    [FeedbackOption.MOBILE]: 'Mobile',
    [FeedbackOption.STORAGE]: 'Storage',
  }
} as CfFeedbackOption;
