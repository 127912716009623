<div class="mb-10">
  <as-input
    [control]="searchControl"
    containerClassName="flat text-textMedium"
    icon="solid/magnifying-glass"
    iconSize="md"
    placeholder="{{ 'drawers.search.placeholder' | translate }}">
  </as-input>
</div>

<ng-container *ngIf="hasSearchTermInput; else noSearchInputTmpl">
  <ng-container *ngIf="results$ | async as results">
    <ng-container *ngIf="results?.length; else noResultTmpl">
      <div class="section-title">{{ 'drawers.search.search_result' | translate }}</div>
      <ng-container *ngFor="let task of results">
        <as-search-result
          *ngIf="projectStore.projectById$(task.projectId) | async as project"
          [task]="task"
          (click)="openTaskDrawer(task, project)">

          <ng-template #TaskTitleTmpl>
            <div class="ml-10 flex flex-col items-start">
              <div class="flex items-center">
                <div class="w-3 h-3 mr-3 rounded"
                     [style.background-color]="project.color">
                </div>
                <div class="truncate text-gray-800">{{ project.name }}</div>
              </div>
              <div class="flex items-center">
                <span class="text-gray-500">{{ 'task_detail.popover.identifier' | translate }} {{ task.identifier }} </span>
              </div>
            </div>
          </ng-template>
        </as-search-result>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #noSearchInputTmpl>
  <ng-container *ngIf="recentTasks$ | async as recentTasks">
    <ng-container *ngIf="recentTasks?.length; else noResultTmpl">
      <div class="section-title">{{ 'drawers.search.recent_search' | translate }}</div>
      <ng-container *ngFor="let task of recentTasks">
        <as-search-result
          *ngIf="projectStore.projectById$(task.projectId) | async as project"
          [task]="task"
          (click)="openTaskDrawer(task, project)">

          <ng-template #TaskTitleTmpl>
            <div class="ml-10 flex flex-col items-start">
              <div class="flex items-center">
                <div class="w-3 h-3 mr-3 rounded"
                     [style.background-color]="project.color">
                </div>
                <div class="truncate text-gray-800">{{ project.name }}</div>
              </div>
              <div class="flex items-center">
                <span class="text-gray-500">{{ 'task_detail.popover.identifier' | translate }} {{ task.identifier }} </span>
              </div>
            </div>
          </ng-template>
        </as-search-result>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #noResultTmpl>
  <div class="pt-10 flex flex-col justify-center items-center">
    <as-empty-list></as-empty-list>
    <div class="pt-2 text-15 text-center">
      {{ 'drawers.search.no_result' | translate }}
    </div>
    <div class="pt-2 text-15 text-center">{{ 'drawers.search.try_again' | translate }}</div>
  </div>
</ng-template>
