import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationComponent} from './navigation.component';
import {NavBarLogoAreaModule} from "@app-web-central/web/shell/ui/nav-bar-logo-area";
import {NavBarPickerAreaModule} from "@app-web-central/web/shell/ui/nav-bar-picker-area";
import {NavBarBurgerAreaModule} from "@app-web-central/web/shell/ui/nav-bar-burger-area";
import {NavBarMobileMenuModule} from "@app-web-central/web/shell/ui/nav-bar-mobile-menu";
import {NavBarDesktopMenuModule} from "@app-web-central/web/shell/ui/nav-bar-desktop-menu";

@NgModule({
  imports: [
    CommonModule,
    NavBarLogoAreaModule,
    NavBarPickerAreaModule,
    NavBarBurgerAreaModule,
    NavBarMobileMenuModule,
    NavBarDesktopMenuModule
  ],
  declarations: [
    NavigationComponent
  ],
  exports: [
    NavigationComponent
  ],
})
export class NavigationModule {}
