import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NzModalRef} from "ng-zorro-antd/modal";
import {TranslateService} from "@ngx-translate/core";
import {SecurityService} from "@app-web-central/web/settings/data-access";
import {NotificationService} from "@app-web-central/web/notification/data-access";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {FeedbackOption, FeedbackOptionDisplay} from '@app-web-central/web/shared/data-access/models';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'as-modal-feedback',
  templateUrl: './modal-feedback.component.html',
  styleUrls: ['./modal-feedback.component.scss'],
})
export class ModalFeedbackComponent implements OnInit {
  isConfirmLoading = false;
  feedbackForm!: FormGroup;

  _session!: any;
  feedbackOptions!: FeedbackOptionValueTitle[];
  _lang = this._translate.currentLang;

  constructor(
    private _modal: NzModalRef,
    private _formBuilder: FormBuilder,
    private _translate: TranslateService,
    private _securityService: SecurityService,
    private _notificationService: NotificationService,
    private _nzNotificationService: NzNotificationService,
  ) { }

  ngOnInit() {
    this._session = this._securityService.getCurrentSession();
    this.initForm();
    this.feedbackOptions = [
      new FeedbackOptionValueTitle(FeedbackOption.BILLING, this._lang),
      new FeedbackOptionValueTitle(FeedbackOption.ACCOUNT, this._lang),
      new FeedbackOptionValueTitle(FeedbackOption.PLATFORM, this._lang),
      new FeedbackOptionValueTitle(FeedbackOption.MOBILE, this._lang),
      new FeedbackOptionValueTitle(FeedbackOption.STORAGE, this._lang)
    ]
  }

  initForm() {
    this.feedbackForm = this._formBuilder.group({
      service: ['BILLING', [Validators.required]],
      email: [this._session.username, [Validators.required, Validators.email]],
      account: [this._session.tenant, [Validators.required]],
      subject: ['', [Validators.required]],
      description: ['', [Validators.required]],
      acknowledge: [false, [Validators.required]]
    });
  }

  handleOk(): void {
    this.isConfirmLoading = true;
    if (this.feedbackForm.invalid) {
      return;
    }

    const formValue = this.feedbackForm.getRawValue();
    this._notificationService.feedback(formValue).pipe(untilDestroyed(this)).subscribe((response) => {
      this._nzNotificationService.success('Success', response.payload, {});
    }, error => {
      this._nzNotificationService.error('Error', error.error.error, {});
    }, () => {
      this.isConfirmLoading = false;
      this._modal.destroy();
    });
  }

  handleCancel(): void {
    this._modal.destroy();
  }

}

class FeedbackOptionValueTitle {
  value: FeedbackOption;
  label: string;
  constructor(feedbackOption: FeedbackOption, lang: string) {
    this.value = feedbackOption;
    this.label = FeedbackOptionDisplay[lang][feedbackOption];
  }
}
