import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {map} from "rxjs";
import {loadRecipients} from "./recipients.actions";
import {RecipientsState} from "./recipients.reducer";
import {getRecipients} from "./recipients.selectors";

@Injectable({ providedIn: 'root' })
export class RecipientsFacade {
  readonly recipients$ = this._store.pipe(select(getRecipients));
  readonly recipientsEnabled$ = this.recipients$.pipe(
    map(recipients => recipients?.filter(recipient => recipient.enabled))
  );

  constructor(
    private _store: Store<RecipientsState>
  ) { }

  init() {
    this._store.dispatch(loadRecipients());
  }

}
