import {CfAddress} from "@app-web-central/web/shared/data-access/models";

export enum SuiteEnum {
  IN_DEPARTURE = 'IN_DEPARTURE',
  IN_EXPECTED_ARRIVAL = 'IN_EXPECTED_ARRIVAL',
  FREE_CLEAN = 'FREE_CLEAN',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
  IN_OVERLAP = 'IN_OVERLAP',
  FREE_DIRTY = 'FREE_DIRTY',
  IN_WORK = 'IN_WORK',
  BLANK = 'BLANK'
}

export const SuiteDisplayValue = {
  [SuiteEnum.IN_DEPARTURE]: 'suite_detail.states.in_departure',
  [SuiteEnum.IN_EXPECTED_ARRIVAL]: 'suite_detail.states.in_expected_arrival',
  [SuiteEnum.FREE_CLEAN]: 'suite_detail.states.free_clean',
  [SuiteEnum.OUT_OF_SERVICE]: 'suite_detail.states.out_of_service',
  [SuiteEnum.IN_OVERLAP]: 'suite_detail.states.in_overlap',
  [SuiteEnum.FREE_DIRTY]: 'suite_detail.states.free_dirty',
  [SuiteEnum.IN_WORK]: 'suite_detail.states.in_work',
  [SuiteEnum.BLANK]: 'suite_detail.states.blank'
}


export class SuiteWithI18n {
  label: string;
  value: SuiteEnum;
  constructor(value: SuiteEnum) {
    this.value = value;
    this.label = SuiteDisplayValue[value];
  }
}


export interface CfSuite {
  id: string;
  suiteNumber: string;
  type: string;
  price: number;
  status: SuiteEnum;
  enabled: boolean;
  dateAdd: number;
  dateUpd: number;
  address: CfAddress;
}
