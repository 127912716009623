import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavBarDesktopMenuComponent} from './nav-bar-desktop-menu.component';
import {ButtonModule} from "@app-web-central/web/shared/ui/button";
import {NzModalModule} from "ng-zorro-antd/modal";
import {TranslateModule} from "@ngx-translate/core";
import {IconModule} from "@app-web-central/web/shared/ui/icon";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {AvatarModule} from "@app-web-central/web/shared/ui/avatar";
import {InitialPipeModule} from "@app-web-central/web/shared/pipes/initial-pipe";
import {NzPopoverModule} from "ng-zorro-antd/popover";
import {RouterModule} from "@angular/router";
import {DropdownModule} from "@app-web-central/web/shared/ui/dropdown";
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {BreadcrumbsModule} from "@app-web-central/web/shared/ui/breadcrumbs";

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    NzModalModule,
    TranslateModule,
    IconModule,
    NzToolTipModule,
    AvatarModule,
    InitialPipeModule,
    NzPopoverModule,
    RouterModule,
    DropdownModule,
    NzDrawerModule,
    BreadcrumbsModule
  ],
  declarations: [
    NavBarDesktopMenuComponent
  ],
  exports: [
    NavBarDesktopMenuComponent
  ],
})
export class NavBarDesktopMenuModule {
}
