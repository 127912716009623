import {CfUser} from './user';
import {CfCountry} from './country';
import {CfLanguage} from './language';

export interface CfOrganizationCompliance {
  accepted: boolean;
  enabled: boolean;
  reviewed: boolean;
  acceptedBy: string | null;
  dateAdd: number;
  dateUpd: number;
  dateAccepted: number;
  document: string;
  documentUrl: string;
}

export interface CfOrganizationLicense {
  licenseId: string;
}

export enum InvoiceFrequencyEnum {
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY"
}

export const InvoiceFrequencyDisplay = {
  [InvoiceFrequencyEnum.MONTHLY]: 'billing.monthly',
  [InvoiceFrequencyEnum.YEARLY]: 'billing.yearly',
};

export interface CfOrganizationCard {
  cardId: string;
  last4: string;
  funding: string;
  brand: string;
  expMonth: number;
  expYear: number;
}

export interface CfOrganizationBillingAddress {
  address1: string;
  address2: string;
  city: string;
  zipCode: string;
  country: string;
  countryCode: string;
  state: string | null;
}

export interface CfOrganizationBillingContact {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
}

export interface CfOrganization {
  id: string;
  identifier: string;
  subdomain: string;
  companyName: string;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  country: CfCountry;
  siret: string;
  vat: string;
  companyPhone: string;
  companyEmail: string;
  theme: string;
  dateAdd: number;
  dateUpd: number;
  timezone: any;
  createdBy: CfUser;
  modifiedBy: CfUser;
  nextDateInvoice: number;
  preferredLanguage: CfLanguage;
  avatarUrl: string;
  appLogoUrl: string;
  stripeCustomerId: string;
  stripeCardId: string;
  stripeSubscriptionId: string;
  hasSameBillingAddress: boolean;
  organizationCard: CfOrganizationCard;
  organizationLicense: CfOrganizationLicense;
  organizationBillingAddress: CfOrganizationBillingAddress;
  organizationBillingContact: CfOrganizationBillingContact;
  invoiceFrequency: InvoiceFrequencyEnum;
  compliances: CfOrganizationCompliance[];
}
