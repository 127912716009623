import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {HeaderApi} from "./header-api";
import {ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class DirectionApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) {}

  addressLookup(body: any) {
    return this._http.post<ResponseState<any[]>>(
      `${this._appConfig.directionV1Url}autocomplete-address-lookup`, body,
      {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

}
