import {CfRecipient} from "@app-web-central/web/shared/data-access/models";

export class RecipientUtil {
  static replaceRecipientAndGetNewRecipients(recipients: CfRecipient[] | null, recipient: CfRecipient) {
    const newRecipients = recipients !== null ? [...recipients] : [];
    const index = newRecipients.findIndex((x) => x.id === recipient.id);
    if (index === -1) {
      newRecipients.push(recipient);
    } else {
      newRecipients[index] = recipient;
    }
    return newRecipients;
  }

  static removeRecipientFromRecipients(recipients: CfRecipient[] | null, recipient: CfRecipient) {
    const newRecipients = recipients !== null ? [...recipients] : [];
    const index = newRecipients.findIndex((x) => x.id === recipient.id);
    newRecipients.splice(index, 1);
    return newRecipients;
  }
}
