import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavBarMobileMenuComponent} from './nav-bar-mobile-menu.component';
import {ButtonModule} from "@app-web-central/web/shared/ui/button";
import {IconModule} from "@app-web-central/web/shared/ui/icon";
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    IconModule,
    RouterModule
  ],
  declarations: [
    NavBarMobileMenuComponent
  ],
  exports: [
    NavBarMobileMenuComponent
  ],
})
export class NavBarMobileMenuModule {
}
