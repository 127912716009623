import {createReducer, on} from "@ngrx/store";
import {CfCountry, CfLanguage} from "@app-web-central/web/shared/data-access/models";
import {loadSettingsSuccess} from "./settings.actions";

export const SETTINGS_FEATURE_KEY = 'settings';

export interface SettingsState {
  countries: CfCountry[] | null,
  languages: CfLanguage[] | null
}

export const initialState: Readonly<SettingsState> = {} as SettingsState;

export const settingsReducer = createReducer(
  initialState,
  on(loadSettingsSuccess, (state, { countries, languages }) => ({
    ...state,
    countries,
    languages
  }))
);

