import {Injectable} from "@angular/core";
import {ComponentStore} from "@ngrx/component-store";
import {GenericState, GenericStoreStatus} from "@app-web-central/web/shared/data-access/models";
import {SelectorUtil} from "@app-web-central/web/shared/utils";

interface WebAdminState extends GenericState<any> { }

@Injectable({ providedIn: 'root' })
export class WebAdminStore extends ComponentStore<WebAdminState> {
  appStatus$ = this.select(({ status }) => status);
  getAppStateLoading$ = this.select(SelectorUtil.isLoading);

  readonly setWebAdminStateLoading$ = this.updater((state: WebAdminState, status: GenericStoreStatus) => ({
    ...state,
    status
  }));

  constructor() {
    super(<WebAdminState>{status: 'pending'});
  }
}
