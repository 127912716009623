import {Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {getSession} from "@app-web-central/web/session/data-access";
import {Store} from "@ngrx/store";
import {CfUser, CfUserNotification} from "@app-web-central/web/shared/data-access/models";
import {getNotifications, NotificationFacade} from "@app-web-central/web/notification/data-access";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {DrawerNotificationDetailComponent} from "@app-web-central/web/shell/ui/drawer-notification-detail";

@UntilDestroy()
@Component({
  selector: 'as-drawer-notifications',
  templateUrl: './drawer-notifications.component.html',
  styleUrls: ['./drawer-notifications.component.scss']
})
export class DrawerNotificationsComponent implements OnInit {
  readonly session$ = this._store.select(getSession);
  notifications$ = this._store.select(getNotifications);
  list: CfUserNotification[] = [];

  loaded = false;
  loadingMore = true;
  count = 0;
  atomicCounter = 0;
  dateFormat!: string;
  fullDateFormat!: string;
  userId!: string;

  constructor(
    private _store: Store,
    private _drawerService: NzDrawerService,
    private _notificationFacade: NotificationFacade
  ) { }

  ngOnInit(): void {
    this.session$.pipe(untilDestroyed(this)).subscribe((session) => {
      if (session) {
        const newSession = session as CfUser;
        this.userId = newSession.id;
        this.dateFormat = newSession.settings.dateFormat;
        this.fullDateFormat = newSession.settings.dateFormatFull;
      }
    });
    this.notifications$.pipe(untilDestroyed(this)).subscribe((notifications) => {
      const newNotifications = notifications as CfUserNotification[];
      if (newNotifications) {
        this.list = [...newNotifications];
        this.loaded = true;
        this.loadingMore = false;
      }
    });
  }

  onLoadMore() {
    this.loadingMore = true;
    this.loaded = false;

    this.count = this.count +1;
    this._notificationFacade.loadMore10Notifications(this.userId, this.count);
  }

  openDrawerShowNotification(notification: CfUserNotification) {
    if (!notification.markedAsRead) {
      this._notificationFacade.readOneNotification(notification.id);
    }
    this._drawerService.create({
      nzContent: DrawerNotificationDetailComponent,
      nzTitle: `${notification.notificationSubject}`,
      nzContentParams: {notification: notification},
      nzPlacement: 'right',
      nzClosable: true,
      nzWidth: 500
    });
  }

}
