<div>
  <h2 class="pb-2 text-sm font-medium text-gray-900">
    {{ 'task_detail.recipient' | translate }}
  </h2>

  <as-combobox
    [props]="['fullName']"
    [searchProp]="'fullName'"
    placeholder="{{ 'task_detail.add_recipient' | translate }}"
    [items]="recipients"
    [selectedItem]="control.value"
    [disabled]="!!task.dates?.validationDate"
    (activeItem)="onSelectRecipient($event)" >
  </as-combobox>

</div>
