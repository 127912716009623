import {HeaderApi} from "./header-api";
import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfAddress, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class AddressApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) {}

  get(addressId: string) {
    return this._http.get<ResponseState<CfAddress>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/${addressId}`,{
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  list() {
    return this._http.get<ResponseState<CfAddress[]>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses`,{
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  create(address: CfAddress) {
    return this._http.post<ResponseState<CfAddress>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses`, address, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  update(addressId: string, address: CfAddress) {
    return this._http.put<ResponseState<CfAddress>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/${addressId}`, address, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  delete(addressId: string) {
    return this._http.delete<ResponseState<CfAddress>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/${addressId}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  deleteBulk(addressIds: string[]) {
    return this._http.delete<ResponseState<string>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/bulk/${addressIds}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  enable(addressId: string) {
    return this._http.get<ResponseState<CfAddress>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/${addressId}/enable`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  enableBulk(addressIds: string[]) {
    return this._http.post<ResponseState<CfAddress[]>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/bulk/enable`, addressIds, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  disable(addressId: string) {
    return this._http.get<ResponseState<CfAddress>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/${addressId}/disable`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  disableBulk(addressIds: string[]) {
    return this._http.post<ResponseState<CfAddress[]>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/bulk/disable`, addressIds, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

}
