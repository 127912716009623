<div class="relative" [ngStyle]="{'max-width': size + 'px'}">
  <div *ngIf="avatarUrl"
       [ngClass]="['avatar-container', className, active]"
       [ngStyle]="style">
  </div>

  <div *ngIf="status">
    <div class="absolute status">{{ statuses[status]?.emoji }}</div>
  </div>

  <nz-avatar
    *ngIf="!avatarUrl && text"
    [nzSize]="size"
    [nzShape]="'square'"
    nzText="{{ text | avatarLetters }}"
    style="color:#f56a00; background-color:#fde3cf;"
    class="{{ className }}">
  </nz-avatar>
</div>
