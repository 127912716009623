import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {CfUserNotification} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-drawer-notification-detail',
  templateUrl: './drawer-notification-detail.component.html',
  styleUrls: ['./drawer-notification-detail.component.scss'],
})
export class DrawerNotificationDetailComponent implements AfterViewInit {
  @Input() notification!: CfUserNotification;
  @ViewChild('notification') notificationDiv!: ElementRef;

  classNames = ['heading', 'subject', 'footer'];
  atomicCounter = 0;

  ngAfterViewInit(): void {
    this.truncateHtml();
  }

  truncateHtml(): void {
    if (this.atomicCounter === 0) {
      this.removeHtmlClasses();
      this.atomicCounter++;
    }
  }

  removeHtmlClasses() {
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.notification.html, 'text/html');
    for (const clazz of this.classNames) {
      const o = doc.getElementsByClassName(clazz);
      while (o.length > 0) {
        if (o[0].parentNode) {
          o[0].parentNode.removeChild(o[0]);
        }
      }
    }
    this.notificationDiv.nativeElement.appendChild(doc.body);
  }

}
