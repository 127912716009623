<div class="w-full pl-8 pr-4 flex-shrink-0 flex items-center space-x-10">
  <ng-container *ngIf="hasBreadcrumbs">
    <ng-container
      *ngIf="breadcrumbStore.breadcrumbs$ | async then breadcrumbTpl"
      [ngTemplateOutlet]="breadcrumbTpl"
      [ngTemplateOutletContext]="{ breadcrumbs: breadcrumbStore.breadcrumbs$ | async }">
    </ng-container>
    <ng-template #breadcrumbTpl let-breadcrumbs>
      <as-breadcrumbs [breadcrumbs]="breadcrumbs"></as-breadcrumbs>
    </ng-template>
  </ng-container>

  <nav class="flex items-center space-x-6" *ngIf="menuLinks && menuLinks.length">
    <ng-container *ngFor="let link of menuLinks">
      <ng-container *ngIf="!link.isDropdown && link.handler">
        <a
          [routerLink]="link.url"
          routerLinkActive="active"
          (click)="link.handler(null)"
          class="menu-item">
          {{ link.label | translate }}
        </a>
      </ng-container>
      <ng-container *ngIf="link.isDropdown">
        <div class="relative text-left">
          <as-dropdown [item]="link"></as-dropdown>
        </div>
      </ng-container>
    </ng-container>
  </nav>

  <div class="flex-auto"></div>

  <div class="flex items-center"
       *ngIf="hasCreationButton">
    <div class="relative">
      <as-button
        type="button"
        (click)="openAddEventModal()">
        {{ 'buttons.create' | translate }}
      </as-button>
    </div>
  </div>

  <div class="flex items-center space-x-8">
    <span class="inline-flex">
      <a
        *ngFor="let item of quickItems; let i = index"
        [ngClass]="i !== quickItems.length-1 ? 'mr-2' : ''"
        class="-mx-1 quick-item"
        nz-tooltip
        nzTooltipTitle="{{ item.label | translate }}"
        nzTooltipPlacement="bottom"
        (click)="item.handler()">
        <span class="sr-only">{{ item.label | translate }}</span>
        <svg-icon [key]="item.icon"></svg-icon>
      </a>
    </span>

    <div
      *ngIf="session$ | async as session"
      class="relative inline-block text-left"
      nz-popover
      [nzPopoverArrowPointAtCenter]="false"
      nzPopoverPlacement="bottomRight"
      [nzPopoverContent]="userSessionPopoverTmpl"
      nzPopoverTrigger="click">
      <div nz-tooltip
           [nzTooltipTitle]="session?.fullName"
           nzTooltipPlacement="right"
           class="itemIcon">
        <as-avatar
          class="flex"
          [className]="isActive(session.sessions)
            ? 'avatar-bg-rounded avatar-online shadow'
            : 'avatar-bg-rounded avatar-offline shadow'"
          [avatarUrl]="session.avatarUrl"
          [size]="36"
          [status]="session.accountStatus"
          text="{{ session.fullName | avatarLetters }}">
        </as-avatar>
      </div>
    </div>
  </div>
</div>

<ng-template #userSessionPopoverTmpl>
  <div class="user-session-popover" *ngIf="session$ | async as session">
    <div class="py-1" role="none" *ngIf="organization$ | async as organization">
      <div class="flex px-1 pb-2">
        <as-avatar
          class="flex"
          [className]="isActive(session.sessions)
            ? 'avatar-bg-rounded avatar-online'
            : 'avatar-bg-rounded avatar-offline'"
          [avatarUrl]="session.avatarUrl"
          [size]="45"
          text="{{ session.fullName | avatarLetters }}">
        </as-avatar>
        <div class="pl-2">
          <p class="text-textMedium text-13"><strong>{{ session?.fullName }}</strong></p>
          <small class="text-textMedium text-13">
            {{ organization.companyName }}
          </small>
        </div>
      </div>
    </div>
    <div class="py-1" role="none">
      <a
        (click)="openUserStatusModal(session)"
        routerLinkActive="active"
        class="submenu-item allowed"
        role="button">
        <span class="mr-1" *ngIf="getCurrentStatus(session)" [innerHTML]="getCurrentStatus(session)?.emoji"></span>
        {{ 'dropdowns.user_session.update_status' | translate }}
      </a>
      <a
        (click)="setUserSessionAsAway(session)"
        routerLinkActive="active"
        class="submenu-item allowed"
        role="button">
        {{ 'dropdowns.user_session.set_as_away' | translate }}
      </a>
    </div>
    <div class="py-1" role="none">
      <a
        (click)="routeToProfileUrl()"
        routerLinkActive="active"
        class="submenu-item allowed"
        role="button">
        {{ 'sidebar.left.profile' | translate }}
      </a>
    </div>
    <div class="py-1" role="none">
      <a
        (click)="signOut(session)"
        class="submenu-item allowed"
        role="button">
        {{ 'sidebar.left.sign_out' | translate }}
      </a>
    </div>
  </div>
</ng-template>
