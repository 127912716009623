<div
  class="flex items-center"
  *ngIf="user && user.fullName">
  <as-avatar
    [avatarUrl]="user?.avatarUrl"
    [text]="user.fullName"
    [isActive]="isActive"
    [className]="'avatar-bg-rounded shadow-md ring-2 ring-white'"
    [size]="24"
    nz-tooltip
    [nzTooltipMouseEnterDelay]="0.3"
    [nzTooltipTitle]="user.fullName"
    nzTooltipPlacement="right">
  </as-avatar>
  <span *ngIf="!imageOnly" class="ml-1-5 mr-1-5 user-name">
    {{ user.fullName }}
  </span>
</div>
