import {VehicleItem} from "@app-web-central/web/shared/data-access/models";
import {Injectable} from "@angular/core";
import {ComponentStore} from "@ngrx/component-store";

interface VehicleState {
  vehicles: VehicleItem[];
}

@Injectable({ providedIn: 'root' })
export class VehicleStore extends ComponentStore<VehicleState> {
  readonly vehicles$ = this.select(({ vehicles }) => vehicles);
  constructor() {
    super({
      vehicles: [
        {value: 'CAR', label: 'drawers.quick_settings.vehicles_list.car'},
        {value: 'BICYCLE', label: 'drawers.quick_settings.vehicles_list.bicycle'},
        {value: 'TRUCK', label: 'drawers.quick_settings.vehicles_list.truck'},
      ]
    });
  }
}
