import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {CfLicense, GenericState} from "@app-web-central/web/shared/data-access/models";
import {select, Store} from "@ngrx/store";
import {getLicenses, LicensesState} from "../licenses";
import {map, Observable} from "rxjs";

interface LicenseState extends GenericState<CfLicense> {
  licenseId: string;
}

@Injectable({ providedIn: 'root' })
export class LicenseStore extends ComponentStore<LicenseState> {
  licenses$ = this._store.pipe(select(getLicenses));

  licenseById$ = (licenseId: string): Observable<CfLicense | undefined> => this.licenses$.pipe(
    map((licenses) => licenses?.find((u) => u.id === licenseId))
  );

  constructor(
    private _store: Store<LicensesState>
  ) {
    super(<LicenseState>{});
  }
}
