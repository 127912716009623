import {en_US, fr_FR} from "ng-zorro-antd/i18n";

export class I18nUtil {
  static getLocaleLanguage(lang: string) {
    if (lang === 'fr') {
      return fr_FR;
    }
    return en_US;
  }
}
