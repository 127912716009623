import {createAction, props} from "@ngrx/store";
import {CfProject, GenericStoreStatus} from "@app-web-central/web/shared/data-access/models";

export const setLoadingProjectsState = createAction(
  '[Browse Page] Set Projects State Status',
  props<{
    status: GenericStoreStatus;
  }>()
);

export const loadProjects = createAction(
  '[Project List] Load Projects'
);

export const loadProjectsSuccess = createAction(
  '[Project List] Load Projects Success',
  props<{
    projects: CfProject[];
  }>()
);

export const loadProjectsError = createAction(
  '[Project List] Load Projects Error',
  props<{
    error: string;
  }>()
);
