import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {BreadcrumbsLink} from "@app-web-central/web/shared/data-access/models";
import {RouteUtil} from "@app-web-central/web/shared/utils";

interface BreadcrumbState {
  breadcrumbs: BreadcrumbsLink[];
}

const initialState = [
  new BreadcrumbsLink('sidebar.links.settings', RouteUtil.getAdminRouteUrl())
];

@Injectable({ providedIn: 'root' })
export class BreadcrumbsStore extends ComponentStore<BreadcrumbState> {
  breadcrumbs$ = this.select(({ breadcrumbs }) => breadcrumbs);
  menuBreadcrumbsState$ = this.updater((state, breadcrumbs: BreadcrumbsLink[]) => ({
    ...state,
    breadcrumbs: [...initialState, ...breadcrumbs]
  }));
  addToCurrentBreadcrumbsState$ = this.updater((state, breadcrumbs: BreadcrumbsLink[]) => ({
    ...state,
    breadcrumbs: [...state.breadcrumbs, ...breadcrumbs]
  }));
  toggleBreadcrumbsState$ = this.updater((state, breadcrumbs: BreadcrumbsLink[]) => ({
    ...state,
    breadcrumbs
  }));
  reset$ = this.updater((state) => ({
    ...state,
    breadcrumbs: initialState
  }));
  constructor() {
    super(<BreadcrumbState>{ breadcrumbs: initialState });
  }
}
