import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserComponent} from './user.component';
import {AvatarModule} from "@app-web-central/web/shared/ui/avatar";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";

@NgModule({
  imports: [
    CommonModule,
    AvatarModule,
    NzToolTipModule
  ],
  declarations: [
    UserComponent
  ],
  exports: [
    UserComponent
  ],
})
export class UserModule {
}
