<nav class="space-y-1 sticky lg:top-[70px]">
  <a
    *ngFor="let item of items"
    [routerLink]="item.url"
    routerLinkActive="active"
    class="group legal-item">
    <span class="truncate">{{ item.label | translate }}</span>
  </a>
</nav>


