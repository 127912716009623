export enum UIAlert {
  INFO = 'INFO',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
}

export const UIAlertDisplayValues = {
  [UIAlert.INFO]: {textColor: 'text-blue-700', bgColor: 'bg-blue-50', icon: 'solid/information-circle', iconColor: 'text-blue-400'},
  [UIAlert.WARNING]: {textColor: 'text-yellow-800', bgColor: 'bg-yellow-50', icon: 'solid/exclamation-circle', iconColor: 'text-yellow-400'},
  [UIAlert.DANGER]: {textColor: 'text-red-800', bgColor: 'bg-red-50', icon: 'solid/x-circle', iconColor: 'text-red-400'},
  [UIAlert.SUCCESS]: {textColor: 'text-green-800', bgColor: 'bg-green-50', icon: 'solid/check-circle', iconColor: 'text-green-400'}
};
