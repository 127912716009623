import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CfUser} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-task-reporter-select',
  templateUrl: './task-reporter-select.component.html',
  styleUrls: ['./task-reporter-select.component.scss'],
})
export class TaskReporterSelectComponent {
  @Input() control!: FormControl;
  @Input() users!: CfUser[];

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1.id === o2.id : o1 === o2);

  getUser(userId: string) {
    return this.users.find((user) => user.id === userId) as CfUser;
  }
}
