import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {ChannelsState, getChannels, loadChannels} from "@app-web-central/web/channel/data-access";

@Injectable({ providedIn: 'root' })
export class ChannelsFacade {
  channels$ = this._store.pipe(select(getChannels));

  constructor(
    private _store: Store<ChannelsState>,
  ) { }

  init() {
    this._store.dispatch(loadChannels());
  }
}
