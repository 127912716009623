import {Inject, Injectable} from "@angular/core";
import {CfDeviceInformation, CfSession, UserActionState} from "@app-web-central/web/shared/data-access/models";
import {CookieService} from "ngx-cookie-service";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {Device} from "@capacitor/device";
import {HttpClient} from "@angular/common/http";

const OcfCookie = 'ocf-session';
const OcfCookieId = 'ocf-session-id';

interface RemoteIPAddress {
  ip: string;
}

@Injectable({ providedIn: 'root' })
export class SecurityService {
  constructor(
    private _http: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _cookieService: CookieService
  ) { }

  static clearLocalStorage(): void {
    localStorage.clear();
  }

  static clearSessionStorage(): void {
    sessionStorage.clear();
  }

  /**
   * Sign out the current user session and clear cookies, session and local storage.
   *
   */
  public signOutSession() {
    SecurityService.clearLocalStorage();
    SecurityService.clearSessionStorage();

    this._cookieService.deleteAll(`/`, this._appConfig.domain);
    window.location.replace(this._appConfig.loginUrl);
  }

  /**
   * Get item from cookie storage by key.
   *
   * @return {CfSession}.
   */
  public getCurrentSession(): CfSession {
    const value = this._cookieService.get(OcfCookie);
    try {
      return JSON.parse(value);
    } catch (e) {
      return {} as CfSession;
    }
  }

  /**
   * Gets item from cookie storage by key.
   *
   * @return user session id { string }.
   */
  getCurrentSessionId(): string {
    const value = this._cookieService.get(OcfCookieId);
    try {
      return JSON.parse(value).sessionID;
    } catch (e) {
      this.signOutSession();
      return '';
    }
  }

  /**
   * Async function to get the current device information.
   * Return a promise of { CfDeviceInformation }
   *
   */
  async getDeviceInformation(action: UserActionState): Promise<CfDeviceInformation> {
    const info = await Device.getInfo();
    const sessionId = this.getCurrentSessionId();
    const remoteIpAddress = await this._fetchRemoteIPAddress();
    return new Promise<CfDeviceInformation>((resolve =>
        resolve(({
          ...info,
          sessionId,
          isActive: false,
          remoteIpAddress,
          fcmToken: '',
          action,
          actionDate: 0
        }))
    ));
  }

  /**
   * Async function used to fetch the current remote ip address.
   * Return a promise of the current ip.
   *
   */
  private async _fetchRemoteIPAddress() {
    return new Promise<string>((resolve) => {
      this._http.get<RemoteIPAddress>(`https://api.ipify.org?format=json`).pipe().subscribe(
        (response) => resolve(response.ip)
      );
    });
  }

}
