import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'as-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['navigation.component.scss']
})
export class NavigationComponent {
  @Input() logoArea = true;
  @Input() hasCreationButton = true;
  @Input() hasBreadcrumbs = false;
  @Output() manualToggle = new EventEmitter();
  expanded: boolean;

  constructor() {
    this.expanded = false;
  }
  toggle() {
    this.expanded = !this.expanded;
  }
}
