export class FormUtil {
  static convertToArray(field: any) {
    if (typeof field === 'string') {
      return [parseInt(field, 10)];
    } else if (typeof field === 'number') {
      return [field];
    } else {
      return field;
    }
  }

  static fieldNotNull(field: string | number): boolean {
    if (field != null || isNaN(field)) {
      return true;
    }
    return false;
  }

}
