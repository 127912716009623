import {NgModule, LOCALE_ID, ErrorHandler} from '@angular/core';
import {CommonModule} from '@angular/common';
import {webShellRoutes} from "./web-shell-routes";
import {RouterModule} from "@angular/router";
import {WebLayoutModule} from "@app-web-central/web/shell/ui/layout";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {SettingsModule} from "@app-web-central/web/settings/feature";
import {NotificationModule} from "@app-web-central/web/notification/feature";
import {StoreModule} from "@ngrx/store";
import {CookieService} from "ngx-cookie-service";
import {SessionModule} from "@app-web-central/web/session/feature";
import {InterventionShellModule} from "@app-web-central/web/intervention/shell";
import {ChannelFeatureModule} from "@app-web-central/web/channel/feature";
import {EffectsModule} from "@ngrx/effects";
import {
  TasksEffects,
  tasksFeatureKey,
  tasksReducer
} from "@app-web-central/web/task/data-access";
import {
  OrganizationsEffects,
  organizationsFeatureKey,
  organizationsReducer
} from "@app-web-central/web/organization/data-access";
import {ProjectsEffects, projectsFeatureKey, projectsReducer} from "@app-web-central/web/project/data-access";
import {SuitesEffects, suitesFeatureKey, suitesReducer} from "@app-web-central/web/suite/data-access";
import {FoldersEffects, foldersFeatureKey, foldersReducer} from "@app-web-central/web/folder/data-access";
import {TemplatesEffects, templatesFeatureKey, templatesReducer} from "@app-web-central/web/dashboard/data-access";
import * as Sentry from '@sentry/angular';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {UserShellModule} from "@app-web-central/web/user/feature/shell";
import {AddressShellModule} from "@app-web-central/web/address/feature/shell";
import {RecipientShellModule} from "@app-web-central/web/recipient/feature/shell";

/** Languages / i18n **/
import {registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
import fr from '@angular/common/locales/fr';
registerLocaleData(en);
registerLocaleData(fr);

/** Config ng-zorro-antd i18n **/
import {en_US, NZ_I18N, fr_FR} from 'ng-zorro-antd/i18n';
/** Build specific depending on environments. **/
import {extModules} from './build-specifics';

/** For loading root data in store. **/
const rootReducers = {
  [tasksFeatureKey]: tasksReducer,
  [projectsFeatureKey]: projectsReducer,
  [foldersFeatureKey]: foldersReducer,
  [suitesFeatureKey]: suitesReducer,
  [organizationsFeatureKey]: organizationsReducer,
  [templatesFeatureKey]: templatesReducer,
};

@NgModule({
  imports: [
    CommonModule,
    WebLayoutModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(webShellRoutes),
    StoreModule.forRoot(rootReducers),
    EffectsModule.forRoot([
      TasksEffects,
      OrganizationsEffects,
      ProjectsEffects,
      FoldersEffects,
      SuitesEffects,
      TemplatesEffects
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      },
      isolate: true
    }),
    SettingsModule,
    SessionModule,
    AddressShellModule,
    RecipientShellModule,
    UserShellModule,
    InterventionShellModule,
    NotificationModule,
    ChannelFeatureModule,
    NgIdleKeepaliveModule.forRoot(),
    ...extModules
  ],
  exports: [
    RouterModule
  ],
  providers: [
    CookieService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
        dialogOptions: {lang: 'fr'}
      })
    },
    {
      provide: NZ_I18N,
      useFactory: (localId: string) => {
        switch (localId) {
          case 'en':
            return en_US;
          case 'fr':
            return fr_FR;
          default:
            return en_US;
        }
      },
      deps: [LOCALE_ID]
    },
  ]
})
export class WebShellModule {

}
