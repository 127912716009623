<div class="mt-3 py-2">
  <ul class="mb-3" *ngIf="control.value.length > 0" nz-list [nzDataSource]="control.value" nzBordered nzSize="small">
    <li nz-list-item *ngFor="let intervention of control.value" nzNoFlex>
      <ul nz-list-item-actions>
        <nz-list-item-action>
          <a (click)="removeIntervention(intervention)">{{ 'buttons.delete' | translate}}</a>
        </nz-list-item-action>
      </ul>
      {{ intervention.name }}
    </li>
  </ul>

  <div class="w-full"
       *ngIf="visible">
    <as-combobox
      [props]="['name']"
      [searchProp]="'name'"
      placeholder="{{ 'task_detail.add_intervention' | translate }}"
      [items]="interventions"
      [removable]="false"
      [disabled]="!!task.dates?.validationDate"
      [selectedItem]="control.value"
      [multipleSelect]="true"
      (activeItem)="onSelectInterventions($event)" >
    </as-combobox>
  </div>

  <as-button
    *ngIf="!visible"
    [className]="'tertiary'"
    (click)="visible = !visible"
    [disabled]="!!task.dates?.validationDate"
    class="uppercase text-gray-800 text-sm">
    <div class="flex items-center">
      <svg-icon key="mini/plus" class="!flex !items-center"></svg-icon>
      {{ 'task_detail.add_intervention' | translate }}
    </div>
  </as-button>

</div>
