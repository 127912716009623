import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'avatarLetters'
})
export class InitialPipe implements PipeTransform {
  transform(fullName: string): any {
    return fullName
      .split(' ')
      .map(n => n[0])
      .join('');
  }
}
