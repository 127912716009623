import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'filter',
  pure: false
})
export class ListFilterPipe implements PipeTransform {
  transform(items: any[] | null, searchTerm: string, prop: any, subProp: any): any {
    if (!items || !searchTerm) {
      return items;
    }
    return items
      .filter((item) => Array.isArray(item[prop])
        ? item[prop].includes(searchTerm)
        : typeof item[prop] === 'object'
          ? item[prop][subProp].toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          : item[prop].toLowerCase()
      .indexOf(searchTerm.toLowerCase()) !== -1);
  }
}
