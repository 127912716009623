import {CfDashboardTemplate, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {loadTemplates, loadTemplatesError, loadTemplatesSuccess} from "./templates.actions";

export interface TemplatesState extends GenericState<CfDashboardTemplate[]> { }

const initialState: TemplatesState = {
  data: null,
  error: null,
  status: 'pending'
}

export const templatesFeatureKey = 'templates';

export const templatesReducer = createReducer(
  initialState,
  on(loadTemplates, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadTemplatesSuccess, (state, { templates }) => ({
    ...state,
    data: templates,
    status: 'success',
    error: null
  })),
  on(loadTemplatesError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
