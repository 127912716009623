import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {map} from "rxjs";
import {getSuites} from "./suites.selectors";
import {SuitesState} from "./suites.reducer";
import {loadSuites} from "./suites.actions";

@Injectable({ providedIn: 'root' })
export class SuitesFacade {
  readonly suites$ = this._store.pipe(select(getSuites));
  readonly suitesEnabled$ = this.suites$.pipe(
    map(addresses => addresses?.filter(address => address.enabled))
  );

  constructor(
    private _store: Store<SuitesState>
  ) { }

  init() {
    this._store.dispatch(loadSuites());
  }
}
