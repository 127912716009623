import {createAction, props} from "@ngrx/store";
import {CfCountry, CfLanguage} from "@app-web-central/web/shared/data-access/models";

export const loadSettingsSuccess = createAction(
  '[Settings] Persist Data',
  props<{
    countries: CfCountry[] | null,
    languages: CfLanguage[] | null
  }>()
);
