export enum NotificationType {
  MAIL = 'Mail',
  PUSH = 'Push'
}

export enum NotificationFormat {
  HTML = 'Html',
  TEXT = 'Text'
}

export const NotificationTypeDisplay = {
  [NotificationType.MAIL]: 'Send me email notifications',
  [NotificationType.PUSH]: 'Send me push notifications'
};
