<div class="relative mt-3 mr-[8px] text-15">

  <div *ngIf="createMode">
    <as-avatar
      *ngIf="session"
      class="absolute top-0 left-0"
      [className]="'avatar-bg-rounded shadow'"
      [avatarUrl]="session.avatarUrl"
      [size]="30">
    </as-avatar>
  </div>

  <div class="pl-10">
    <div class="editing-area" *ngIf="createMode">
      <form [formGroup]="commentForm" (ngSubmit)="submit()">

        <textarea
          #commentBoxRef
          id="creationTextarea"
          class="textarea-inline-control textarea-border"
          formControlName="message"
          placeholder="{{ 'comment_detail.add_comment' | translate }}"
          [rows]="1"
          cdkTextareaAutosize
          (click)="setCommentEdit(true)">
        </textarea>
        <div *ngIf="isEditing"
             class="flex pt-2 pb-4 items-center">
          <as-button
            type="submit"
            [disabled]="commentForm.invalid"
            [className]="'primary mr-2'">
            {{ 'buttons.save' | translate }}
          </as-button>
          <as-button
            [className]="'tertiary'"
            (click)="cancel()">
            {{ 'buttons.cancel' | translate }}
          </as-button>
        </div>

      </form>
    </div>

    <div *ngIf="comment && !createMode && comment.dateAdd | dateAgo as date">
      <ng-template #commentTemplateRef let-comment="comment" let-dateAdd="dateAdd">
        <nz-comment [nzAuthor]="comment.createdBy.fullName" [nzDatetime]="dateAdd">

          <nz-avatar
            nz-comment-avatar nzIcon="user"
            [nzSrc]="comment.createdBy.avatarUrl">
          </nz-avatar>

          <nz-comment-content>
            <p (click)="edit(comment)">{{ comment.message }}</p>
          </nz-comment-content>

          <nz-comment-action>
            <span class="inline-block align-middle">
              <svg-icon
                class="!flex !items-center"
                [key]="'outline/thumb-up'"
                (click)="like(comment)"
                [size]="'xs'">
              </svg-icon>
            </span>
            <span class="count like ml-1">{{ getLikes(comment.likes) }}</span>
          </nz-comment-action>
          <nz-comment-action>
            <span class="inline-block align-middle">
              <svg-icon
                class="!flex !items-center"
                [key]="'outline/thumb-down'"
                (click)="dislike(comment)"
                [size]="'xs'">
            </svg-icon>
            </span>
            <span class="count dislike ml-1">{{ getDislikes(comment.likes) }}</span>
          </nz-comment-action>
          <nz-comment-action>
            <span (click)="reply(comment)">{{ 'buttons.reply' | translate }}</span>
          </nz-comment-action>
          <ng-container *ngIf="session">
            <nz-comment-action *ngIf="session.id === comment.createdBy.id">
              <span (click)="remove(comment)">{{ 'buttons.delete' | translate }}</span>
            </nz-comment-action>
          </ng-container>

          <ng-container *ngIf="comment.children && comment.children.length">
            <ng-template ngFor let-child [ngForOf]="comment.children">
              <ng-template
                *ngIf="child.dateAdd | dateAgo as newDate"
                [ngTemplateOutlet]="commentTemplateRef"
                [ngTemplateOutletContext]="{ comment: child, dateAdd: newDate }"
              ></ng-template>
            </ng-template>
          </ng-container>

        </nz-comment>
      </ng-template>

      <ng-template
        [ngTemplateOutlet]="commentTemplateRef"
        [ngTemplateOutletContext]="{ comment: comment, dateAdd: date }">
      </ng-template>
    </div>

  </div>
</div>
