import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {CfChannel, GenericState} from "@app-web-central/web/shared/data-access/models";
import {switchMap, tap} from "rxjs";
import {ChannelApi} from "@app-web-central/web/shared/data-access/cf-api";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {TranslateService} from "@ngx-translate/core";


interface ChannelState extends GenericState<CfChannel> {
  channelId: string;
}

@Injectable({ providedIn: 'root' })
export class ChannelStore extends ComponentStore<ChannelState> {
  readonly startWatchingChannel$ = this.effect<{ channelId: string, userId: string }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({channelId, userId}) =>
        this._channelApi.startWatchingChannel(channelId, userId)
          .pipe(
            tapResponse(
              (response) => {
                this.patchState({
                  data: response.payload,
                  status: 'success',
                  error: ''
                });
              },
              error => {
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });
                this._notification.create(
                  'error',
                  this._translate.instant('notifications.join.error'),
                  this._translate.instant('notifications.join.error_message')
                );
              }, () => {
                this._notification.create(
                  'success',
                  this._translate.instant('notifications.join.success'),
                  this._translate.instant('notifications.join.success_message'),
                );
              }
            )
          )
      )
    )
  )

  readonly stopWatchingChannel$ = this.effect<{ channelId: string, userId: string }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({channelId, userId}) =>
        this._channelApi.stopWatchingChannel(channelId, userId)
          .pipe(
            tapResponse(
              (response) => {
                this.patchState({
                  data: response.payload,
                  status: 'success',
                  error: ''
                });
              },
              error => {
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });
                this._notification.create(
                  'error',
                  this._translate.instant('notifications.leave.error'),
                  this._translate.instant('notifications.leave.error_message')
                );
              }, () => {
                this._notification.create(
                  'success',
                  this._translate.instant('notifications.leave.success'),
                  this._translate.instant('notifications.leave.success_message'),
                );
              }
            )
          )
      )
    )
  )

  constructor(
    private _channelApi: ChannelApi,
    private _translate: TranslateService,
    private _notification: NzNotificationService
  ) {
    super(<ChannelState>{});
  }
}
