import {createAction, props} from "@ngrx/store";
import {CfIntervention} from "@app-web-central/web/shared/data-access/models";

export const loadInterventions = createAction('[Intervention List] Load Interventions');

export const loadInterventionsSuccess = createAction(
  '[Intervention List] Load Interventions Success',
  props<{
    interventions: CfIntervention[];
  }>()
);

export const loadInterventionsError = createAction(
  '[Intervention List] Load Interventions Error',
  props<{
    error: string;
  }>()
);
