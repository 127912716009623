<header class="bg-white shadow">
  <div class="max-w-7xl mx-auto px-4 sm:px-6">
    <div class="flex justify-between h-16">
      <div class="flex px-2 lg:px-0">
        <div class="flex-shrink-0 flex items-center">
          <a href="#">
            <img class="h-8 w-auto" src="./assets/images/logo_cfcube_4224x1024_purple.png" alt="CFCube App Logo">
          </a>
        </div>
      </div>
    </div>
  </div>
</header>
