import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FileUtil} from "@app-web-central/web/shared/utils";

@Component({
  selector: 'as-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
})
export class DropzoneComponent implements AfterViewInit {
  @Input() topPlacement = 77;
  @Input() leftPlacement = 80;
  @ViewChild('dropzone') dropzone!: ElementRef<HTMLElement>;
  @Output() public selectedFiles = new EventEmitter<File[]>();
  private lastTarget!: any;

  ngAfterViewInit(): void {
    this.dropzone.nativeElement.style.visibility = 'hidden';
    let isVisible = false;
    document.addEventListener('dragenter', () => {
      if (this.dropzone.nativeElement.style.visibility === 'visible') {
        // do nothing.
      } else {
        isVisible = true;
        if (isVisible) {
          this.dropzone.nativeElement.style.visibility = 'visible';
        }
      }
    });
  }

  /**
   * @public method to catch on drag enter action.
   * @param event the event to catch.
   */
  public onDragEnter(event: any): void {
    if (FileUtil.isFile(event)) {
      this.lastTarget = event.target;
      this.dropzone.nativeElement.style.opacity = '1';
      this.dropzone.nativeElement.style.visibility = 'visible';
    }
  }

  /**
   * @public method to catch on drag leave action.
   * @param event the event to catch.
   */
  public onDragLeave(event: any): void {
    event.preventDefault();
    if (event.target === document || event.target === this.lastTarget) {
      this.dropzone.nativeElement.style.opacity = '0';
      this.dropzone.nativeElement.style.visibility = 'hidden';
    }
  }

  /**
   * @public method to catch on drag over action.
   * Stop the propagation to avoid other actions.
   * @param event the event to catch.
   */
  public onDragOver(event: any): void {
    event.preventDefault();
  }

  /**
   * @public method to catch on drop action.
   * @param event the event to catch.
   */
  public onDrop(event: any): void {
    event.preventDefault();
    this.dropzone.nativeElement.style.opacity = '0';
    this.dropzone.nativeElement.style.visibility = 'hidden';

    const result = [];
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s).
      for (const item of event.dataTransfer.items) {
        if (item.kind !== 'file') {
          return;
        }
        result.push(item.getAsFile());
      }
    } else {
      // Use DataTransfer interface to access the file(s).
      for (const file of event.dataTransfer.files) {
        result.push(file);
      }
    }

    this.selectedFiles.emit(result);
  }

  /**
   * @public method to catch on click dropzone container and stop propagation to open a popup selection file.
   * @param inputHtml the dropzone html element.
   */
  public stopPopupPropagation(inputHtml: any): boolean {
    inputHtml.preventDefault();
    inputHtml.stopPropagation();
    return false;
  }

}
