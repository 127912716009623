import {CfUser} from "./user";

export enum FileType {
  PNG = 'PNG',
  JPG = 'JPG',
  JPEG = 'JPEG',
  PDF = 'PDF',
  TEXT = 'TEXT',
  XLS = 'XLS',
  XLSX = 'XLSX',
}

export const FileDocTypes = [
  FileType.PDF, FileType.TEXT, FileType.XLS, FileType.XLSX
]

export const FileImageTypes = [
  FileType.JPG, FileType.JPEG, FileType.PNG
]

export class FileTypeIcon {
  icon: string;
  color: string;
  constructor(fileType: FileType) {
    this.icon = FileDocTypes.includes(fileType) ? 'solid/document-text' : 'solid/photo';
    this.color = FileDocTypes.includes(fileType) ? 'text-rose-500' : 'text-zinc-500';
  }
}

export interface CfAttachment {
  id: string;
  filename: string;
  title: string;
  description: string;
  destinationUrl: string;
  folder: string;
  fileUrl: string;
  fileType: string;
  fileSize: string;
  dateAdd: number;
  dateUpd: number;
  clientId: string;
  folderId: string;
  owner: CfUser;
  trashed: boolean;
  trash: any;
}

export interface CfFolderMember {
  folderId: string;
  member: string;
  role: string;
}

export interface CfFolder {
  id: string;
  identifier: string;
  name: string;
  description: string;
  dateAdd: number;
  dateUpd: number;
  owner: string;
  parentId: string;
  trashed: boolean;
  trash: any;
  private: boolean;
  starred: boolean;
  members: CfFolderMember[];
  files: CfAttachment[];
}

export interface CfUIFolder {
  name: any;
  owner: CfUser | undefined;
  dateUpd: number;
  fileSize: string;
}
