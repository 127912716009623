import {HeaderApi} from "./header-api";
import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfRecipient, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({providedIn: 'root'})
export class RecipientApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) {}

  get(recipientId: string) {
    return this._http.get<ResponseState<CfRecipient>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/${recipientId}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  list() {
    return this._http.get<ResponseState<CfRecipient[]>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  create(recipient: CfRecipient) {
    return this._http.post<ResponseState<CfRecipient>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients`, recipient, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  update(recipientId: string, recipient: CfRecipient) {
    return this._http.put<ResponseState<CfRecipient>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/${recipientId}`, recipient, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  delete(recipientId: string) {
    return this._http.delete<ResponseState<CfRecipient>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/${recipientId}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  deleteBulk(recipientIds: string[]) {
    return this._http.delete<ResponseState<string>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/bulk/${recipientIds}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  enable(recipientId: string) {
    return this._http.get<ResponseState<CfRecipient>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/${recipientId}/enable`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  enableBulk(recipientIds: string[]) {
    return this._http.post<ResponseState<CfRecipient[]>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/bulk/enable`, recipientIds, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  disable(recipientId: string) {
    return this._http.get<ResponseState<CfRecipient>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/${recipientId}/disable`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  disableBulk(recipientIds: string[]) {
    return this._http.post<ResponseState<CfRecipient[]>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/bulk/disable`, recipientIds, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

}
