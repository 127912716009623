import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {NavItemWithGroup} from "@app-web-central/web/shared/data-access/models";
import {loadTasks} from "@app-web-central/web/task/data-access";
import {Store} from "@ngrx/store";
import {loadFolders} from "@app-web-central/web/folder/data-access";

interface TopBarState {
  title: string;
  items: NavItemWithGroup[];
}

@Injectable({ providedIn: 'root' })
export class TopBarMenuStore extends ComponentStore<TopBarState> {
  title$ = this.select(({ title }) => title);
  items$ = this.select(({ items }) => items);

  todoListMenuItems$ = this.updater((state) => ({
    ...state,
    items: [
      new NavItemWithGroup('buttons.export', 'mini/download', this.refreshList.bind(this), true),
      new NavItemWithGroup('buttons.refresh', 'mini/refresh', this.refreshList.bind(this), true)
    ]
  }));

  folderListMenuItems$ = this.updater((state) => ({
    ...state,
    items: [
      new NavItemWithGroup('buttons.refresh', 'mini/refresh', this.refreshFolders.bind(this)),
    ]
  }));

  setTitle$ = this.updater((state, title: string) => ({
    ...state,
    title
  }));

  refreshList() {
    this._store.dispatch(loadTasks());
  }

  refreshFolders() {
    this._store.dispatch(loadFolders());
  }

  constructor(
    private _store: Store
  ) {
    super(<TopBarState>{});
  }
}
