import {
  startOfMonth,
  isSameMonth,
  subDays,
  addDays,
  addMonths,
  startOfWeek,
  isSameWeek,
  isSameYear,
  differenceInMinutes,
  startOfYear,
  startOfDay
} from 'date-fns';
import {
  CfUser,
  MonthGrid,
  MonthGridValue,
  WeekGrid,
  WeekGridValue,
} from "@app-web-central/web/shared/data-access/models";

const START_DAY = 8;
const NUMBERS_OF_5_MINUTES_PER_HOUR = 12;
const FIVE_MINUTES = 5;

export class CalendarUtil {
  static scrollTo() {
    const hours = new Date().getHours() !== null ? new Date().getHours() : START_DAY;
    const element = document.getElementById(`${hours}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  static getTimeIndicator() {
    const now = new Date();
    const start = startOfDay(now);
    const minutes = differenceInMinutes(now, start);
    const timeGrid = document.getElementById('timegrid');
    if (timeGrid) {
      const heightContainer = timeGrid.clientHeight - 28; // 1584
      const pixelHeight = heightContainer / 1440;
      return minutes * pixelHeight + 22;
    }
    return 22;
  }

  static setEventPositionOnGrid(startDate: number, endDate: number) {
    const hours = new Date(startDate).getHours() * NUMBERS_OF_5_MINUTES_PER_HOUR;
    const minutes = new Date(startDate).getMinutes() / FIVE_MINUTES;
    const delta = differenceInMinutes(endDate, startDate);
    return `grid-row: ${Math.round((hours + minutes)+1)} / span ${Math.round((delta / FIVE_MINUTES)+2)}`;
  }

  static generateWeekGridCalendar(leftDate: Date) {
    const date = startOfWeek(leftDate, { weekStartsOn: 1 });
    const calendar: WeekGrid[] = [];
    while (isSameWeek(leftDate, date, { weekStartsOn: 1 })) {
      calendar.push(new WeekGridValue(date));
      date.setDate(date.getDate() + 1);
    }
    return calendar;
  }

  static generateMonthGridCalendar(firstDateOfMonth: Date) {
    const date = startOfMonth(firstDateOfMonth);
    const firstDayOfTheCurrentMonth = startOfMonth(firstDateOfMonth);
    const firstDayOfNextMonth = startOfMonth(addMonths(date, 1));
    const calendar: MonthGrid[][] = [[]];
    while (isSameMonth(date, firstDateOfMonth)) {
      calendar[calendar.length - 1].push(new MonthGridValue(date, firstDateOfMonth));
      if (CalendarUtil.getDay(date) % 7 == 6) {
        calendar.push([]);
      }
      date.setDate(date.getDate() + 1);
    }
    CalendarUtil.getPreviousDaysInGrid(calendar, firstDayOfTheCurrentMonth);
    CalendarUtil.getNextDaysInGrid(calendar, firstDayOfNextMonth);
    return calendar.flat();
  }

  static generateYearGridCalendar(leftDate: Date) {
    const date = startOfYear(leftDate);
    const calendar: MonthGrid[][] = [[]];
    while (isSameYear(date, leftDate)) {
      calendar.push(CalendarUtil.generateMonthGridCalendar(date));
      date.setDate(date.getDate() + 31);
    }
    calendar.splice(0,1);
    return calendar;
  }

  static getPreviousDaysInGrid(calendar: MonthGrid[][], firstDayOfMonth: Date) {
    const firstDayOfTheCurrentMonth = firstDayOfMonth;
    const alreadyInList = calendar[0];
    calendar[0] = [];
    while (calendar[0].length < 7) {
      const prevDate = subDays(firstDayOfMonth, 1);
      calendar[0].push(new MonthGridValue(prevDate, firstDayOfTheCurrentMonth));
      firstDayOfMonth = prevDate;
    }
    calendar[0].sort((a, b) => a.day - b.day);
    calendar[0].splice(0, alreadyInList.length);
    calendar[0] = calendar[0].concat(alreadyInList);
  }

  static getNextDaysInGrid(calendar: MonthGrid[][], firstDayOfNextMonth: Date) {
    const lastDateOfTheCurrentMonth = subDays(firstDayOfNextMonth, 1);
    let weekLength = 7;
    const lastWeek = calendar[calendar.length - 1];
    if (calendar.length < 6) {
      weekLength = 14
    }
    while (lastWeek.length < weekLength) {
      lastWeek.push(new MonthGridValue(firstDayOfNextMonth, lastDateOfTheCurrentMonth));
      firstDayOfNextMonth = addDays(firstDayOfNextMonth, 1);
    }
  }

  static getDay = (date: Date) => {
    let day = date.getDay()
    if (day === 0) day = 7
    return day - 1
  }

  static getHours(user: CfUser | null, tzOffset: number) {
    const calcOffset = tzOffset / 60;
    let offsetInHours = `${calcOffset}`;
    let sign = '+';
    if (calcOffset < 0) {
      offsetInHours = offsetInHours.split('-')[1];
      sign = '-';
    }
    const offset = calcOffset < 10 ? `${sign}0${offsetInHours}` : `${sign}${offsetInHours}`;
    if (user === null) {
      return [
        {label: `GMT${offset}`, value: 0},
        {label: "1 AM", value: 1},
        {label: "2 AM", value: 2},
        {label: "3 AM", value: 3},
        {label: "4 AM", value: 4},
        {label: "5 AM", value: 5},
        {label: "6 AM", value: 6},
        {label: "7 AM", value: 7},
        {label: "8 AM", value: 8},
        {label: "9 AM", value: 9},
        {label: "10 AM", value: 10},
        {label: "11 AM", value: 11},
        {label: "12 PM", value: 12},
        {label: "1 PM", value: 13},
        {label: "2 PM", value: 14},
        {label: "3 PM", value: 15},
        {label: "4 PM", value: 16},
        {label: "5 PM", value: 17},
        {label: "6 PM", value: 18},
        {label: "7 PM", value: 19},
        {label: "8 PM", value: 20},
        {label: "9 PM", value: 21},
        {label: "10 PM", value: 22},
        {label: "11 PM", value: 23}
      ];
    } else {
      const meridian = user.settings.meridian;
      if (meridian) {
        return [
          {label: `GMT${offset}`, value: 0},
          {label: "01:00", value: 1},
          {label: "02:00", value: 2},
          {label: "03:00", value: 3},
          {label: "04:00", value: 4},
          {label: "05:00", value: 5},
          {label: "06:00", value: 6},
          {label: "07:00", value: 7},
          {label: "08:00", value: 8},
          {label: "09:00", value: 9},
          {label: "10:00", value: 10},
          {label: "11:00", value: 11},
          {label: "12:00", value: 12},
          {label: "13:00", value: 13},
          {label: "14:00", value: 14},
          {label: "15:00", value: 15},
          {label: "16:00", value: 16},
          {label: "17:00", value: 17},
          {label: "18:00", value: 18},
          {label: "19:00", value: 19},
          {label: "20:00", value: 20},
          {label: "21:00", value: 21},
          {label: "22:00", value: 22},
          {label: "23:00", value: 23}
        ];
      } else {
        return [
          {label: `GMT${offset}`, value: 0},
          {label: "1 AM", value: 1},
          {label: "2 AM", value: 2},
          {label: "3 AM", value: 3},
          {label: "4 AM", value: 4},
          {label: "5 AM", value: 5},
          {label: "6 AM", value: 6},
          {label: "7 AM", value: 7},
          {label: "8 AM", value: 8},
          {label: "9 AM", value: 9},
          {label: "10 AM", value: 10},
          {label: "11 AM", value: 11},
          {label: "12 PM", value: 12},
          {label: "1 PM", value: 13},
          {label: "2 PM", value: 14},
          {label: "3 PM", value: 15},
          {label: "4 PM", value: 16},
          {label: "5 PM", value: 17},
          {label: "6 PM", value: 18},
          {label: "7 PM", value: 19},
          {label: "8 PM", value: 20},
          {label: "9 PM", value: 21},
          {label: "10 PM", value: 22},
          {label: "11 PM", value: 23}
        ];
      }
    }
  }

}

