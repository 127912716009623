import {CfRole} from "@app-web-central/web/shared/data-access/models";

export class RoleUtil {
  static replaceRoleAndGetNewRoles(roles: CfRole[] | null, role: CfRole) {
    const newRoles = roles !== null ? [...roles] : [];
    const index = newRoles.findIndex((x) => x.id === role.id);
    if (index === -1) {
      newRoles.push(role);
    } else {
      newRoles[index] = role;
    }
    return newRoles;
  }

  static removeRoleFromRoles(roles: CfRole[] | null, role: CfRole) {
    const newRoles = roles !== null ? [...roles] : [];
    const index = newRoles.findIndex((x) => x.id === role.id);
    newRoles.splice(index, 1);
    return newRoles;
  }
}
