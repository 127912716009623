import {Component, Input} from '@angular/core';
import {CfChannelSubscribers, CfUser} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent {
  @Input() user!: CfUser | CfChannelSubscribers;
  @Input() imageOnly = false;
  @Input() isActive = false;
}
