<div class="mt-6">
  <as-task-drawer-comment
    [createMode]="true"
    [task]="task"
    [session]="session"
    [commentToEdit]="editComment"
    [commentToReply]="replyComment">
  </as-task-drawer-comment>

  <as-task-drawer-comment
    *ngFor="let comment of task?.comments"
    [comment]="comment"
    [task]="task"
    [session]="session"
    (editComment)="onEditComment($event)"
    (replyComment)="onReplyComment($event)">
  </as-task-drawer-comment>

  <nz-empty class="mt-28" *ngIf="!task.comments || !task.comments.length"></nz-empty>
</div>
