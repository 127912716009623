export class RouterUtil {
  static Configuration = {
    Home: 'home',
    Todo: 'todo',
    Tasks: 'list',
    TaskId: 'taskId',
    Storage: 'storage',
    Folders: 'folders',
    Folder: 'folder',
    FolderId: 'folderId',
    FolderKey: 'folderKey',
    FoldersMy: 'my-folders',
    FolderShared: 'shared-folders',
    FolderRecent: 'recent',
    FolderStarred: 'starred',
    FolderTrashed: 'trashed',
    Projects: 'projects',
    ProjectKey: 'projectKey',
    ProjectDetails: 'project-details',
    ProjectAccess: 'project-access',
    ProjectAccessInvite: 'invite',
    Profile: 'profile',
    ProfileId: 'profileId',
    Calendar: 'calendar',
    Map: 'map',
    Settings: 'settings',
    GeneralSettings: 'general',
    Users: 'users',
    UserId: 'userId',
    Groups: 'groups',
    GroupId: 'groupId',
    Roles: 'roles',
    RoleId: 'roleId',
    Hubs: 'hubs',
    HubId: 'hubId',
    Addresses: 'addresses',
    AddressId: 'addressId',
    Suites: 'suites',
    SuiteId: 'suiteId',
    Recipients: 'recipients',
    RecipientId: 'recipientId',
    AppSettings: 'app-settings',
    Subscription: 'subscription',
    SubscriptionChange: 'change',
    Compliance: 'compliance',
  };
  static ExternalUrls = {
    Administration: '/settings',
    Profile: '/a/profile',
    Privacy: '/p/legal/privacy-policy',
    TermsOfUser: '/p/legal/terms-of-use',
    ServiceStatus: '/s/services/health-check',
    Api: '/s/services/apis',
    Documentation: 'https://confluence.external-share.com/content/45522/cloudfleeter_guide_de_l_utilisateur_fr'
  };
}
