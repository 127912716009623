import {HeaderApi} from "./header-api";
import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {
  CfDeviceInformation,
  CfRole,
  CfUserConfiguration,
  ResponseState
} from "@app-web-central/web/shared/data-access/models";
import {CfUser} from "../../../models/src/lib/api/user";

export interface CfPassword {
  accountId: string;
  password: string;
  confirmPassword: string;
}

@Injectable({providedIn: 'root'})
export class UserApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) { }

  get(userId: string) {
    return this._http.get<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}`, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  listByTenantId(tenantId: string) {
    return this._http.get<ResponseState<CfUser[]>>(
      `${this._appConfig.usersV1Url}manage/users/all/${tenantId}`, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  listRoles() {
    return this._http.get<ResponseState<CfRole[]>>(
      `${this._appConfig.usersV1Url}manage/roles`, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  listUsersConfig() {
    return this._http.get<ResponseState<CfUserConfiguration[]>>(
      `${this._appConfig.localDataUrl}/user-config.json`
    );
  }

  bulk(userIds: string[]) {
    return this._http.post<ResponseState<CfUser[]>>(
      `${this._appConfig.usersV1Url}manage/users/batch`, userIds, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  create(user: CfUser) {
    return this._http.post<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users`, user, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  update(userId: string, user: CfUser) {
    return this._http.put<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}`, user, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  updateStatus(userId: string, user: CfUser) {
    return this._http.put<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/status`, user, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  updateAsAway(userId: string) {
    return this._http.get<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/away`, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  updatePassword(password: CfPassword) {
    return this._http.put<ResponseState<string>>(
      `${this._appConfig.usersV1Url}manage/users/change-password`, password, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  activate(userId: string, deviceInfo: CfDeviceInformation) {
    return this._http.post<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/activate`, deviceInfo, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  isAlive(userId: string, sessionId: string) {
    return this._http.get<ResponseState<string>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/is-alive/${sessionId}`, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  deactivate(userId: string, deviceInfo: CfDeviceInformation) {
    return this._http.post<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/deactivate`, deviceInfo, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  logout(userId: string, deviceInfo: CfDeviceInformation) {
    return this._http.post<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/logout`, deviceInfo, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  enable(userId: string) {
    return this._http.get<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/enable`, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  disable(userId: string) {
    return this._http.get<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/disable`, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  delete(userId: string) {
    return this._http.delete<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}`, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

}
