export const TimeFormatValues = [
  'HH:mm',
  'HH:mm:ss'
];

export const DateFormatValuesFr = [
  'dd/MM/yyyy',
  'dd-MM-yyyy'
];

export const DateFormatValuesEn = [
  'yyyy/MM/dd',
  'yyyy-MM-dd',
  'yyyy/dd/MM',
  'yyyy-dd-MM'
];

export const FullDateFormatValuesFr = [
  'dd/MM/yyyy HH:mm',
  'dd/MM/yyyy HH:mm:ss',
  'dd/MM/yyyy HH:mm:ss a zzzz',
  'dd-MM-yyyy HH:mm',
  'dd-MM-yyyy HH:mm:ss',
  'dd-MM-yyyy HH:mm:ss a zzzz'
];

export const FullDateFormatValuesEn = [
  'yyyy/MM/dd HH:mm',
  'yyyy/MM/dd HH:mm:ss',
  'yyyy/MM/dd HH:mm:ss a zzzz',
  'yyyy-MM-dd HH:mm',
  'yyyy-MM-dd HH:mm:ss',
  'yyyy-MM-dd HH:mm:ss a zzzz',
  'yyyy/dd/MM HH:mm',
  'yyyy/dd/MM HH:mm:ss',
  'yyyy/dd/MM HH:mm:ss a zzzz',
  'yyyy-dd-MM HH:mm',
  'yyyy-dd-MM HH:mm:ss',
  'yyyy-dd-MM HH:mm:ss a zzzz'
];

class DateFormat {
  value!: [];
  constructor(public isoCode: string, public values: string[]) { }
}

export const DateFormats = [
  new DateFormat('fr', DateFormatValuesFr),
  new DateFormat('en', DateFormatValuesEn),
];

export const FullDateFormats = [
  new DateFormat('fr', FullDateFormatValuesFr),
  new DateFormat('en', FullDateFormatValuesEn),
];
