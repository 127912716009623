import {CfAttachment, CfFolder, CfUser} from "@app-web-central/web/shared/data-access/models";
import {RouteUtil} from "./route-util";

export class FileUtil {
  static isFile(evt: any): boolean {
    const dt = evt.dataTransfer;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < dt.types.length; i++) {
      if (dt.types[i] === 'Files') {
        return true;
      }
    }
    return false;
  }

  static getFileData(file: CfAttachment) {
    return {
      keyName: file.filename,
      folder: file.fileUrl.split('/')[4],
      tenant: file.fileUrl.split('/')[3],
      title: file.title,
      awsUrl: file.fileUrl,
      extension: file.fileType
    };
  }

  static getFileParams(userId: string, folder: string, objectId: string, tenant: string) {
    return {
      direction: folder,
      tenant: tenant,
      clientId: userId,
      objectId: objectId
    };
  }

  static buildUIFolder(folder: CfFolder, users: CfUser[] | null, session: CfUser | null) {
    return ({
      name: {
        value: folder.name,
        key: folder.identifier,
        id: folder.id,
        isPrivate: folder.private,
        trashed: folder.trashed,
        isSharedWithMe: folder.members.some((u) => u.member === session?.id) && folder.owner !== session?.id,
        starred: folder.starred,
        route: RouteUtil.getFolderRouteUrl(folder.identifier)
      },
      owner: users?.find((u) => u.id === folder.owner),
      dateUpd: folder.dateUpd,
      fileSize: '-'
    });
  }

  static buildUIFile(folder: CfFolder, users: CfUser[] | null, session: CfUser | null, file: CfAttachment) {
    return ({
      name: {
        value: file.title,
        key: file.destinationUrl,
        id: file.id,
        isPrivate: folder.private,
        isFile: true,
        trashed: file.trashed,
        fileType: file.fileType,
        isSharedWithMe: folder.members.some((u) => u.member === session?.id) && folder.owner !== session?.id,
        starred: false,
        route: RouteUtil.getFolderRouteUrl(folder.identifier),
        file: ({
          ...file,
          owner: users?.find((u) => u.id === file.clientId)
        }),
        folder: { ...folder, members: folder.members.map((m) => users?.find((u) => u.id === m.member)) }
      },
      owner: file.clientId
        ? users?.find((u) => u.id === file.clientId)
        : users?.find((u) => u.id === folder.owner),
      dateUpd: file.dateUpd,
      fileSize: file.fileSize
    });
  }

  static setFormData(file: File, params: any) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('direction', params.direction);
    formData.append('tenant', params.tenant);
    formData.append('clientId', params.clientId);
    formData.append('objectId', params.objectId);
    return formData;
  }

  static getBytesToBlob(bytes: any, extension: string): Blob {
    const arrayBuffer = new ArrayBuffer(bytes.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < bytes.length; i++) {
      int8Array[i] = bytes.charCodeAt(i);
    }
    return new Blob([int8Array], {
      type: FileUtil.getContentType(extension)
    });
  }

  static getContentType(extension: string) {
    switch (extension) {
      case 'csv':
        return 'text/csv';
      case 'txt':
        return 'text/plain';
      case 'png':
        return 'image/png';
      case 'jpg':
        return 'image/jpg';
      case 'pdf':
        return 'application/pdf';
      default:
        return 'application/octet-stream';
    }
  }

  static readableFileSize(size: number) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = 0;
    while(size >= 1024) {
      size /= 1024;
      ++i;
    }
    return `${size.toFixed(1)} ${units[i]}`;
  }

}
