import {CfDashboardTemplate, GenericState} from "@app-web-central/web/shared/data-access/models";
import {Injectable} from "@angular/core";
import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {select, Store} from "@ngrx/store";
import {getTemplates, loadTemplatesSuccess, TemplatesState} from "../templates";
import {switchMap, take, tap} from "rxjs";

interface TemplateState extends GenericState<CfDashboardTemplate> {
  templateId: string;
}

@Injectable({ providedIn: 'root' })
export class TemplateStore extends ComponentStore<TemplateState> {
  templates$ = this._store.pipe(select(getTemplates));

  updateTemplate = this.effect<{ template: CfDashboardTemplate, projectId: string }>((params$) => (
      params$.pipe(
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(({ template, projectId }) =>
          this.templates$
            .pipe(
              take(1),
              tapResponse(
                (templates) => {
                  const newTemplates = templates !== null ? [...templates] : [];
                  const index = newTemplates.findIndex((x) => x.id === template.id);
                  newTemplates[index] = { ...template };
                  this._store.dispatch(
                    loadTemplatesSuccess({
                      templates: newTemplates
                    })
                  );
                  this.patchState({
                    data: template,
                    status: 'success',
                    error: ''
                  });
                },
                error => {
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  constructor(
    private _store: Store<TemplatesState>
  ) {
    super(<TemplateState>{});
  }
}
