import {CfHub, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {loadHubs, loadHubsError, loadHubsSuccess} from "./hubs.actions";

export interface HubsState extends GenericState<CfHub[]> { }

const initialState: HubsState = {
  data: null,
  error: null,
  status: 'pending'
}

export const hubsFeatureKey = 'hubs';

export const hubsReducer = createReducer(
  initialState,
  on(loadHubs, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadHubsSuccess, (state, { hubs }) => ({
    ...state,
    data: hubs,
    status: 'success',
    error: null
  })),
  on(loadHubsError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
