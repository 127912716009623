<div class="help-content">
  <div *ngFor="let item of items">
    <a *ngIf="item.handler"
       (click)="item.handler(item.url)"
       routerLinkActive="active"
       class="link allowed" >
      <div class="flex items-center">
        <svg-icon class="mr-4" key="{{ item.icon }}" [size]="'md'"></svg-icon>
        <div class="pt-px text-15">{{ item.label | translate }}</div>
      </div>
      <svg-icon *ngIf="item.external" key="external-link" [size]="'sm'"></svg-icon>
    </a>
  </div>
</div>

<div class="help-footer absolute bottom-0 left-0 p-2 w-full">
  <div class="divider"></div>
  <div class="flex flex-col items-center">
    <div class="app-logo">
      <img src="./assets/images/logo_cfcube_4224x1024_gray.png" alt="app-logo" height="35">
    </div>
    <div class="app-version">
      <p class="text-xs text-textMedium">v2.2 (202201080001)</p>
    </div>
  </div>
</div>
