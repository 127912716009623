import {CfAddress, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {
  loadAddresses,
  loadAddressesError,
  loadAddressesSuccess
} from "./addresses.actions";

export interface AddressesState extends GenericState<CfAddress[]> { }

const initialState: AddressesState = {
  data: null,
  error: null,
  status: 'pending'
}

export const addressesFeatureKey = 'addresses';

export const addressesReducer = createReducer(
  initialState,
  on(loadAddresses, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadAddressesSuccess, (state, { addresses }) => ({
    ...state,
    data: addresses,
    status: 'success',
    error: null
  })),
  on(loadAddressesError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
)

