import {Component, Inject, OnInit} from '@angular/core';
import {RouterUtil} from "@app-web-central/web/shared/utils";
import {NzModalService} from "ng-zorro-antd/modal";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {ModalFeedbackComponent} from "@app-web-central/web/shell/ui/modal-feedback";

@Component({
  selector: 'as-footer-desktop',
  templateUrl: './footer-desktop.component.html',
  styleUrls: ['./footer-desktop.component.scss'],
})
export class FooterDesktopComponent implements OnInit {
  leftItems!: FooterNavItem[];
  rightItems!: FooterNavItem[];
  currentYear = new Date().getFullYear();

  constructor(
    private _modal: NzModalService,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  ngOnInit(): void {
    this.leftItems = [
      new FooterNavItem('tooltips.privacy', `${this._appConfig.legalUrl}${RouterUtil.ExternalUrls.Privacy}`),
      new FooterNavItem('tooltips.terms', `${this._appConfig.legalUrl}${RouterUtil.ExternalUrls.TermsOfUser}`)
    ];
    this.rightItems = [
      new FooterNavItem('tooltips.service_status', null),
      new FooterNavItem('tooltips.api', null),
      new FooterNavItem('tooltips.documentation', RouterUtil.ExternalUrls.Documentation)
    ];
  }

  routerUrl(url: string | null) {
    if (url) {
      window.open(url, '_target');
    }
  }

  onOpenSupportModal() {
    this._modal.create({
      nzContent: ModalFeedbackComponent,
      nzClosable: true,
      nzWidth: 450
    });
  }
}

export class FooterNavItem {
  constructor(public label: string, public url: string | null) { }
}
