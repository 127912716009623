import {createReducer, on} from "@ngrx/store";
import {loadSessionSuccess, updateSessionSuccess} from "./session.actions";
import {CfUser} from "@app-web-central/web/shared/data-access/models";

export const SESSION_FEATURE_KEY = 'session';

export interface SessionsState {
  session: CfUser | null;
}

export const initialState = {} as SessionsState;

export const sessionReducer = createReducer(
  initialState,
  on(updateSessionSuccess, (state, { session }) => ({
    ...state,
    session
  })),
  on(loadSessionSuccess, (state, { session }) => ({
    ...state,
    session
  }))
)
