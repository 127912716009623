import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {SelectorUtil} from "@app-web-central/web/shared/utils";
import {GenericState, GenericStoreStatus} from "@app-web-central/web/shared/data-access/models";

interface AppState extends GenericState<any> { }

@Injectable({ providedIn: 'root' })
export class InitializeStore extends ComponentStore<AppState> {
  appStatus$ = this.select(({ status }) => status);
  getAppStateLoading$ = this.select(SelectorUtil.isLoading);

  readonly setAppStateLoading$ = this.updater((state: AppState, status: GenericStoreStatus) => ({
    ...state,
    status
  }));

  constructor() {
    super(<AppState>{status: 'pending'});
  }
}
