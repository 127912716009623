import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {ScopesState} from "./scopes.reducer";
import {getScopes} from "./scopes.selectors";
import {loadScopes} from "./scopes.actions";

@Injectable({ providedIn: 'root' })
export class ScopesFacade {
  scopes$ = this._store.pipe(select(getScopes));

  constructor(
    private _store: Store<ScopesState>
  ) { }

  init() {
    this._store.dispatch(loadScopes());
  }
}
