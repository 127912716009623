import {HttpHeaders} from "@angular/common/http";
import {CfSession} from "@app-web-central/web/shared/data-access/models";
import {CookieService} from "ngx-cookie-service";
import {TranslateService} from "@ngx-translate/core";
import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";

const OcfCookie = 'ocf-session';

@Injectable({ providedIn: 'root' })
export class HeaderApi {
  _commonTenantId: string;
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private cookieService: CookieService,
    private translate: TranslateService
  ) {
    this._commonTenantId = this._appConfig.commonTenantID;
  }

  getCommonHeader() {
    if (this._getAccessTokenLocalDev()) {
      return new HttpHeaders({
        'X-TenantID': this._commonTenantId,
        'Authorization': `Bearer ${this._getAccessTokenLocalDev()}`,
        'Accept-Language': this.translate.getBrowserLang() as string
      });
    }
    return new HttpHeaders({
      'X-TenantID': this._commonTenantId,
      'Accept-Language': this.translate.getBrowserLang() as string
    });
  }

  getServiceHeader() {
    if (this._getAccessTokenLocalDev()) {
      return new HttpHeaders({
        'X-TenantID': this.getCurrentSession().tenant,
        'Authorization': `Bearer ${this._getAccessTokenLocalDev()}`,
        'Accept-Language': this.translate.getBrowserLang() as string
      });
    }
    return new HttpHeaders({
      'X-TenantID': this.getCurrentSession().tenant,
      'Accept-Language': this.translate.getBrowserLang() as string
    });
  }

  _getAccessTokenLocalDev() {
    if (!this._appConfig.production) {
      return this.cookieService.get('access_token');
    }
    return null;
  }

  private getCurrentSession(): CfSession {
    const value = this.cookieService.get(OcfCookie);
    try {
      return JSON.parse(value);
    } catch (e) {
      return {} as CfSession;
    }
  }

}
