import {createAction, props} from "@ngrx/store";
import {CfAddress} from "@app-web-central/web/shared/data-access/models";

export const loadAddresses = createAction('[Address List] Load Addresses');

export const loadAddressesSuccess = createAction(
  '[Address List] Load Addresses Success',
  props<{
    addresses: CfAddress[];
  }>()
);

export const loadAddressesError = createAction(
  '[Address List] Load Addresses Error',
  props<{
    error: string;
  }>()
);
