<div *nzModalTitle>
  {{ 'modals.feedback.title' | translate }}
</div>

<div>
  <form [formGroup]="feedbackForm">
    <div class="form-group">
      <label for="service" class="label">
        {{ 'modals.feedback.service' | translate }}
      </label>

      <nz-select id="service"
                 class="w-full"
                 formControlName="service">
        <nz-option *ngFor="let state of feedbackOptions"
                   nzLabel=" {{ state.label }} "
                   [nzValue]="state.value">
          {{ state.value }}
        </nz-option>
      </nz-select>
    </div>

    <div class="form-group mt-3">
      <label class="label">
        {{ 'modals.feedback.subject' | translate }}
      </label>
      <input type="text"
             class="form-input"
             placeholder="{{ 'modals.feedback.subject_placeholder' | translate }}"
             formControlName="subject"/>
    </div>

    <div class="form-group mt-3">
      <label class="label">
        {{ 'modals.feedback.description' | translate }}
      </label>
      <textarea class="form-input"
                placeholder="{{ 'modals.feedback.description_placeholder' | translate }}"
                formControlName="description">
      </textarea>
    </div>

    <div class="form-group mt-3">
      <label class="label">
        {{ 'modals.feedback.acknowledge' | translate }}
      </label>

      <div class="form-check">
        <input type="checkbox"
               id="acknowledge"
               class="form-check-input"
               formControlName="acknowledge" />
        <label class="form-check-label" for="acknowledge">
          {{ 'modals.feedback.acknowledge_desc' | translate }}
        </label>
      </div>
    </div>
  </form>
</div>

<div *nzModalFooter>
  <as-button
    className="primary mr-2"
    type="submit"
    (click)="handleOk()"
    [disabled]="feedbackForm.invalid">
    {{ 'buttons.save' | translate }}
  </as-button>
  <as-button
    className="tertiary"
    type="reset"
    (click)="handleCancel()">
    {{ 'buttons.cancel' | translate }}
  </as-button>
</div>

