import {CfAttachment, CfFolder} from "@app-web-central/web/shared/data-access/models";

export class FolderUtil {
  static findFolderById(folders: CfFolder[] | null, folderId: string) {
    const newFolders = folders !== null ? [...folders] : [];
    return newFolders.find((x) => x.id === folderId);
  }

  static replaceFolderAndGetNewFolders(folders: CfFolder[] | null, folder: CfFolder) {
    const newFolders = folders !== null ? [...folders] : [];
    const index = newFolders.findIndex((x) => x.id === folder.id);
    if (index === -1) {
      newFolders.push(folder);
    } else {
      newFolders[index] = folder;
    }
    return newFolders;
  }

  static removeFolderAndGetNewFolders(folders: CfFolder[] | null, folderId: string) {
    const newFolders = folders !== null ? [...folders] : [];
    const index = newFolders.findIndex((x) => x.id === folderId);
    if (index !== -1) {
      newFolders.splice(index, 1);
    }
    return newFolders;
  }

  static removeAllTrashedFoldersAndGetNewFolders(folders: CfFolder[] | null) {
    const newFolders = folders !== null ? [...folders] : [];
    newFolders.forEach((newFolder, index) => {
      if (newFolder) {
        if (newFolder.trashed) {
          newFolders.splice(index, 1);
        }
        const folder = { ...newFolder };
        const newFiles = [ ...folder.files ].filter((file) => !file.trashed);
        folder.files = [ ...newFiles ];
        newFolders[index] = { ...folder };
      }
    });
    return newFolders;
  }

  static replaceFileInFolder(folders: CfFolder[] | null, file: CfAttachment) {
    const newFolders = folders !== null ? [...folders] : [];
    const newFolder = newFolders.find((x: CfFolder) => x.id === file.folderId);
    if (newFolder) {
      const folder = { ...newFolder };
      const newFiles = [ ...folder.files ];
      newFiles.forEach((f, index) => {
        if (file.id === f.id) {
          newFiles[index] = file;
        }
      });
      folder.files = [ ...newFiles ];
      return folder;
    }
    return {} as CfFolder;
  }

  static removeFileInFolder(folders: CfFolder[] | null, fileId: string, folderId: string) {
    const newFolders = folders !== null ? [...folders] : [];
    const newFolder = newFolders.find((x: CfFolder) => x.id === folderId);
    if (newFolder) {
      const folder = { ...newFolder };
      const newFiles = [ ...folder.files ];
      newFiles.forEach((f, index) => {
        if (fileId === f.id) {
          newFiles.splice(index, 1);
        }
      });
      folder.files = [ ...newFiles ];
      return folder;
    }
    return {} as CfFolder;
  }

}
