import {CfUser, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {
  loadUsers,
  loadUsersError,
  loadUsersSuccess
} from "./users.actions";

export interface UsersState extends GenericState<CfUser[]> { }

const initialState: UsersState = {
  data: null,
  error: null,
  status: 'pending'
}

export const usersFeatureKey = 'users';

export const usersReducer = createReducer(
  initialState,
  on(loadUsers, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadUsersSuccess, (state, { users }) => ({
    ...state,
    data: users,
    status: 'success',
    error: null
  })),
  on(loadUsersError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
