import {TaskStatus} from "../api/task";

export const SegmentValues = {
  [TaskStatus.UNASSIGNED]: 'task_detail.status.unassigned',
  [TaskStatus.ASSIGNED]: 'task_detail.status.assigned',
  [TaskStatus.IN_TRANSIT]: 'task_detail.status.in_transit',
  [TaskStatus.COMPLETED]: 'task_detail.status.completed',
  [TaskStatus.FAILED]: 'task_detail.status.failed',
};


export class Segment {
  key: string;
  value: string;
  status: TaskStatus;
  constructor(status: TaskStatus) {
    this.key = SegmentValues[status];
    this.value = status.toLowerCase();
    this.status = status;
  }
}
