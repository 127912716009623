import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SecurityService} from "@app-web-central/web/settings/data-access";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {RouteUtil} from "@app-web-central/web/shared/utils";
import {FolderStore} from "@app-web-central/web/folder/data-access";

@UntilDestroy()
@Component({
  selector: 'as-join-folder',
  templateUrl: './join-folder.component.html',
  styleUrls: ['./join-folder.component.scss'],
})
export class JoinFolderComponent implements OnInit {
  validationCode!: string;
  folderId!: string;
  loading!: boolean;
  organization!: string;
  owner!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _security: SecurityService,
    private _folderStore: FolderStore
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        if (params) {
          this.folderId = params["folder"];
          this.validationCode = params["validation_code"];
          this.owner = params["invitor"];
          this.organization = params["organization"];

          if (!this._security.getCurrentSession().isConnected) {
            this._security.signOutSession();
          }
          if (!this.folderId && !this.validationCode) {
            if (this._security.getCurrentSession().isConnected) {
              this.router.navigateByUrl(RouteUtil.getHomeRouteUrl());
            } else {
              this._security.signOutSession();
            }
          }
        }
      });
  }

  acceptInvitation() {
    this.loading = true;
    this._folderStore.joinFolder({ folderId: this.folderId, validationCode: this.validationCode });
  }
}
