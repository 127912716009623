import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {NavItem} from "@app-web-central/web/shared/data-access/models";
import {FilterStore} from "../filters";
import {Router} from "@angular/router";
import {RouteUtil} from "@app-web-central/web/shared/utils";

export interface NavBarSecondaryState {
  title: string;
  items: NavItem[];
  secondaryTitle: string;
  secondaryItems: NavItem[];
  calendar: boolean;
  team: boolean;
  chart: boolean;
}

@Injectable({providedIn: 'root'})
export class NavBarSecondaryStore extends ComponentStore<NavBarSecondaryState> {
  all$ = this.select((state: NavBarSecondaryState) => state);
  title$ = this.select(({title}) => title);
  secondaryTitle$ = this.select(({secondaryTitle}) => secondaryTitle);
  hasItems$ = this.select(({items}) => items);
  hasSecondaryItems$ = this.select(({secondaryItems}) => secondaryItems);
  hasCalendar$ = this.select(({calendar}) => calendar);
  hasTeam$ = this.select(({team}) => team);
  hasChart$ = this.select(({chart}) => chart);

  initialState = [
    new NavItem('components.todo.in_progress', 'outline/truck', this.filterEventsByInProgress.bind(this), true),
    new NavItem('components.todo.unassigned', 'outline/user-plus', this.filterEventsByUnassigned.bind(this), false),
    new NavItem('components.todo.today', 'outline/calendar', this.filterEventsByTodayDate.bind(this), false),
    new NavItem('components.todo.week', 'outline/calendar-days', this.filterEventsByWeekDate.bind(this), false),
    new NavItem('components.todo.next7_days', 'outline/clock', this.filterEventsByNext7Days.bind(this), false),
    new NavItem('components.todo.all', 'outline/clipboard-document', this.filterEventsByAll.bind(this), false),
    new NavItem('components.todo.priority', 'outline/fire', this.filterEventsByHighestPriority.bind(this), false),
  ];

  projectSettingsState = [
    new NavItem('components.project.details', 'outline/adjustments-horizontal', this.routeToProjectDetails.bind(this), true),
    new NavItem('components.project.access', 'outline/key', this.routeToProjectAccess.bind(this), false)
  ];

  folderState = [
    new NavItem('components.folder.folders', 'outline/inbox', this.routeToFolders.bind(this), true),
    new NavItem('components.folder.my_folders', 'outline/inbox', this.routeToMyFolders.bind(this), false),
    new NavItem('components.folder.shared', 'outline/inbox-stack', this.routeToSharedFolders.bind(this), false),
  ];

  folderSecondaryState = [
    new NavItem('components.folder.recent', 'outline/clock', this.routeToRecentFolders.bind(this), false),
    new NavItem('components.folder.starred', 'outline/star', this.routeToStarredFolders.bind(this), false),
    new NavItem('components.folder.trash', 'outline/trash', this.routeToTrashFolders.bind(this), false),
  ];

  secondaryNavBarProject$ = this.updater((state) => ({
    ...state,
    title: 'constants.todo',
    items: this.initialState,
    secondaryItems: [],
    calendar: false,
    team: true,
    chart: true,
  }));

  setSecondaryItems$ = this.updater((state, values: NavItem[]) => ({
    ...state,
    secondaryItems: values
  }));

  setProjectSettings$ = this.updater((state) => ({
    ...state,
    title: 'Settings',
    items: this.projectSettingsState,
    calendar: false,
    team: false,
    chart: false,
    secondaryItems: []
  }))

  setCalendar$ = this.updater((state) => ({
    ...state,
    title: 'constants.calendar',
    items: [],
    calendar: true,
    team: true,
    chart: true,
    secondaryItems: []
  }));

  setFolder$ = this.updater((state) => ({
    ...state,
    title: 'constants.folder',
    items: this.folderState,
    calendar: false,
    team: false,
    chart: false,
    secondaryTitle: 'components.folder.my_collection',
    secondaryItems: this.folderSecondaryState
  }));

  setFolderMy$ = this.updater((state) => ({
    ...state,
    items: [
      new NavItem('components.folder.folders', 'outline/inbox', this.routeToFolders.bind(this), false),
      new NavItem('components.folder.my_folders', 'outline/inbox', this.routeToMyFolders.bind(this), true),
      new NavItem('components.folder.shared', 'outline/inbox-stack', this.routeToSharedFolders.bind(this), false),
    ],
  }));

  setFolderShared$ = this.updater((state) => ({
    ...state,
    items: [
      new NavItem('components.folder.folders', 'outline/inbox', this.routeToFolders.bind(this), false),
      new NavItem('components.folder.my_folders', 'outline/inbox', this.routeToMyFolders.bind(this), false),
      new NavItem('components.folder.shared', 'outline/inbox-stack', this.routeToSharedFolders.bind(this), true),
    ],
  }));

  constructor(
    private _router: Router,
    private _filterStore: FilterStore
  ) {
    super(<NavBarSecondaryState>{})
  }

  filterEventsByInProgress() {
    this._filterStore.toggleInProgress();
  }

  filterEventsByUnassigned() {
    this._filterStore.toggleUnassigned();
  }

  filterEventsByTodayDate() {
    this._filterStore.toggleIsToday();
  }

  filterEventsByWeekDate() {
    this._filterStore.toggleInWeek();
  }

  filterEventsByNext7Days() {
    this._filterStore.toggleNext7Days();
  }

  filterEventsByAll() {
    this._filterStore.toggleIsAll();
  }

  filterEventsByHighestPriority() {
    this._filterStore.toggleHighPriority();
  }

  routeToProjectDetails() {
    const projectKey = this._router.url.split('/')[2];
    this._router.navigateByUrl(`${RouteUtil.getProjectDetailRouteUrl(projectKey)}`);
  }

  routeToProjectAccess() {
    const projectKey = this._router.url.split('/')[2];
    this._router.navigateByUrl(`${RouteUtil.getProjectAccessRouteUrl(projectKey)}`);
  }

  routeToFolders() {
    this._router.navigateByUrl(`${RouteUtil.getFoldersRouteUrl()}`);
  }

  routeToMyFolders() {
    this._router.navigateByUrl(`${RouteUtil.getFoldersMyRouteUrl()}`);
  }

  routeToSharedFolders() {
    this._router.navigateByUrl(`${RouteUtil.getFolderForSharedRouteUrl()}`);
  }

  routeToRecentFolders() {
    this._router.navigateByUrl(`${RouteUtil.getFoldersRecentRouteUrl()}`);
  }

  routeToStarredFolders() {
    this._router.navigateByUrl(`${RouteUtil.getFoldersStarredRouteUrl()}`);
  }

  routeToTrashFolders() {
    this._router.navigateByUrl(`${RouteUtil.getFoldersTrashedRouteUrl()}`);
  }

}
