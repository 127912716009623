<div class="relative">
  <as-button
    type="button"
    nz-dropdown
    nzTrigger="click"
    [nzDropdownMenu]="menu"
    [className]="className">
    <span *ngIf="!currentSelection">
      {{ item.label | translate }}
    </span>
    <as-badge
      *ngIf="currentSelection"
      [text]="currentSelection">
    </as-badge>
    <svg-icon
      key="mini/chevron-down"
      class="!flex !items-center">
    </svg-icon>
  </as-button>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="inline-flex">
      <ul class="dropdown mt-3" nz-menu>
        <li class="dropdown-item"
            [attr.nz-menu-item]="!sublink.isDropdown"
            [attr.nz-submenu]="sublink.isDropdown"
            *ngFor="let sublink of item.items">
          <ng-container *ngIf="!sublink.isDropdown">
            <ng-container *ngIf="sublink.url && sublink.url !== ''">
              <a [routerLink]="sublink.url" class="flex items-center">
                {{ sublink.label | translate }}
              </a>
            </ng-container>
            <ng-container *ngIf="sublink.handler">
              <a (click)="sublink.handler(sublink.value); onSelect(sublink)"
                 class="flex items-center">
                {{ sublink.label | translate }}
              </a>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="sublink.isDropdown">
            <a (click)="onOpenDatePicker()"
               class="flex items-center">
              {{ sublink.label | translate }}
            </a>
          </ng-container>
        </li>

        <ng-container *ngIf="sublist && sublist.items">
          <li class="dropdown-item" nz-menu-item *ngFor="let sublink of sublist.items">
            <ng-container>
              <a (click)="sublist.handler(sublink); onSelect(sublink)"
                 class="flex items-center">
                {{ sublink }}
              </a>
            </ng-container>
          </li>
        </ng-container>
      </ul>

      <div *ngIf="visible" class="mt-3">
        <nz-range-picker
          nzInline
          [(ngModel)]="rangeDate"
          (ngModelChange)="onChange($event)">
        </nz-range-picker>
      </div>
    </div>

  </nz-dropdown-menu>
</div>
