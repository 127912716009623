<div class="combobox">
  <label *ngIf="label" for="combobox" class="combobox-label">
    {{ label | translate }}
  </label>
  <div class="relative">
    <as-button
      type="button"
      nz-dropdown
      className="secondary block"
      nzTrigger="click"
      [isCombobox]="true"
      [disabled]="disabled"
      [nzDisabled]="disabled"
      [nzDropdownMenu]="menu">
      <as-user
        class="selected-item z-0 {{ getIsVisible() }}"
        *ngIf="selectedItem && selectedItem.avatarUrl"
        [user]="selectedItem">
      </as-user>
      <ng-container *ngIf="selectedItem && !selectedItem.avatarUrl">
            <span *ngFor="let prop of props; let idx = index">
              <span *ngIf="idx === 0">{{ selectedItem[prop] | translate }}</span>
              <span *ngIf="idx === 1">, {{ selectedItem[prop] | translate }}&nbsp;</span>
              <span *ngIf="idx > 1">{{ selectedItem[prop] | translate }}&nbsp;</span>
            </span>
      </ng-container>
      <span *ngIf="!selectedItem">
        <span>{{ placeholder }}</span>
      </span>
    </as-button>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ng-container
        *ngIf="items && items.length then listRef else emptyRef"
        [ngTemplateOutlet]="listRef">
      </ng-container>

      <ng-template #listRef>
        <ul nz-menu
            class="dropdown absolute right-0 top-0 pt-0 max-h-[250px] overflow-y-auto">
          <li class="sticky top-0 left-0 bg-white z-50">
            <input
              id="combobox"
              type="text"
              role="combobox"
              class="combobox-search"
              [readonly]="disabled"
              [(ngModel)]="search"
              (blur)="onBlur()"
              placeholder="{{ 'top_bar.search' | translate }}"
              [disabled]="disabled"/>
          </li>
          <li nz-menu-item class="combobox-item"
              *ngFor="let item of items | filter:search:searchProp:searchSubProp">
            <span
              class="block"
              (mousedown)="handleSelectItem(item)"
              *ngIf="item.avatarUrl">
              <as-user [user]="item"></as-user>
            </span>
            <span
              class="block truncate"
              (mousedown)="handleSelectItem(item)"
              *ngIf="!item.avatarUrl">
              <span *ngFor="let prop of props; let idx = index" class="text-sm font-medium">
                <span *ngIf="idx === 0">{{ item[prop] | translate }}</span>
                <span *ngIf="idx === 1">, {{ item[prop] | translate }} </span>
                <span *ngIf="idx > 1">{{ item[prop] | translate }} </span>
              </span>
            </span>
            <span class="absolute inset-y-0 left-0 flex items-center pl-1.5"
                  *ngIf="selectedItem && (item.id === selectedItem.id) && !removable"
                  [class.active]="item.id === selectedItem.id">
              <svg-icon key="mini/check" class="!flex !items-center text-purple-600"></svg-icon>
            </span>
            <span (click)="handleRemoveItem()"
                  class="absolute inset-y-0 right-0 flex items-center pr-1.5"
                  *ngIf="selectedItem && (item.id === selectedItem.id) && removable"
                  [class.active]="item.id === selectedItem.id">
              <svg-icon key="mini/x-circle" class="!flex !items-center text-gray-300"></svg-icon>
            </span>
          </li>
        </ul>
      </ng-template>

      <ng-template #emptyRef>
        <div nz-menu
             class="dropdown absolute right-0 top-0 pb-6">
          <nz-empty nzNotFoundImage="simple"></nz-empty>
        </div>
      </ng-template>

    </nz-dropdown-menu>

  </div>
</div>


<!--<div class="combobox">-->
<!--  <label *ngIf="label" for="combobox" class="combobox-label">-->
<!--    {{ label | translate }}-->
<!--  </label>-->
<!--  <div class="relative">-->
<!--    <input-->
<!--      id="combobox"-->
<!--      type="text"-->
<!--      role="combobox"-->
<!--      [readonly]="disabled"-->
<!--      [(ngModel)]="search"-->
<!--      (blur)="onBlur()"-->
<!--      (click)="visible = !visible"-->
<!--      [placeholder]="!selectedItem || !visible ? placeholder : ''"-->
<!--      nz-dropdown-->
<!--      [disabled]="disabled"-->
<!--      nzTrigger="click"-->
<!--      [nzDropdownMenu]="menu"/>-->

<!--    <as-user-->
<!--      class="selected-item z-0 {{ getIsVisible() }}"-->
<!--      *ngIf="selectedItem && selectedItem.avatarUrl"-->
<!--      [user]="selectedItem">-->
<!--    </as-user>-->

<!--    <div-->
<!--      *ngIf="selectedItem && !selectedItem.avatarUrl"-->
<!--      class="selected-item z-0 {{ getIsVisible() }}">-->
<!--      <span *ngFor="let prop of props; let idx = index">-->
<!--        <span *ngIf="idx === 0">{{ selectedItem[prop] }}</span>-->
<!--        <span *ngIf="idx === 1">, {{ selectedItem[prop] }}&nbsp;</span>-->
<!--        <span *ngIf="idx > 1">{{ selectedItem[prop] }}&nbsp;</span>-->
<!--      </span>-->
<!--    </div>-->

<!--    <as-button-->
<!--      type="button"-->
<!--      icon="mini/chevron-up-down"-->
<!--      nz-dropdown-->
<!--      className="btn-empty !p-0"-->
<!--      nzTrigger="click"-->
<!--      [disabled]="disabled"-->
<!--      [nzDisabled]="disabled"-->
<!--      [nzDropdownMenu]="menu">-->
<!--    </as-button>-->

<!--    <nz-dropdown-menu #menu="nzDropdownMenu">-->
<!--      <ng-container-->
<!--        *ngIf="items && items.length then listRef else emptyRef"-->
<!--        [ngTemplateOutlet]="listRef">-->
<!--      </ng-container>-->

<!--      <ng-template #listRef>-->
<!--        <ul nz-menu-->
<!--            class="dropdown absolute right-0 top-0 max-h-[250px] overflow-y-auto">-->
<!--          <li nz-menu-item class="dropdown-item"-->
<!--              *ngFor="let item of items | filter:search:searchProp:searchSubProp">-->
<!--            <div-->
<!--              class="block"-->
<!--              (mousedown)="handleSelectItem(item)"-->
<!--              *ngIf="item.avatarUrl">-->
<!--              <as-user [user]="item"></as-user>-->
<!--            </div>-->
<!--            <div-->
<!--              class="block"-->
<!--              (mousedown)="handleSelectItem(item)"-->
<!--              *ngIf="!item.avatarUrl">-->
<!--            <span *ngFor="let prop of props; let idx = index" class="text-sm font-medium">-->
<!--              <span *ngIf="idx === 0">{{ item[prop] }}</span>-->
<!--              <span *ngIf="idx === 1">, {{ item[prop] }} </span>-->
<!--              <span *ngIf="idx > 1">{{ item[prop] }} </span>-->
<!--            </span>-->
<!--            </div>-->
<!--            <div-->
<!--              class="dropdown-check"-->
<!--              (click)="handleRemoveItem()"-->
<!--              *ngIf="selectedItem && (item.id === selectedItem.id) && removable"-->
<!--              [class.active]="item.id === selectedItem.id">-->
<!--              <svg-icon key="mini/x-circle" class="!flex !items-center"></svg-icon>-->
<!--            </div>-->
<!--            <div-->
<!--              class="dropdown-check"-->
<!--              *ngIf="selectedItem && (item.id === selectedItem.id) && !removable"-->
<!--              [class.active]="item.id === selectedItem.id">-->
<!--              <svg-icon key="mini/check" class="!flex !items-center text-purple-600"></svg-icon>-->
<!--            </div>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </ng-template>-->

<!--      <ng-template #emptyRef>-->
<!--        <div nz-menu-->
<!--            class="dropdown absolute right-0 top-0 pb-6">-->
<!--          <nz-empty nzNotFoundImage="simple"></nz-empty>-->
<!--        </div>-->
<!--      </ng-template>-->

<!--    </nz-dropdown-menu>-->

<!--  </div>-->
<!--</div>-->
