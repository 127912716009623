import {HeaderApi} from "./header-api";
import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfAttachment, CfFolder, CfUser, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class FileApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) {}

  upload(formData: FormData) {
    return this._http.post<ResponseState<CfAttachment>>(`${this._appConfig.uploadV1Url}manage/upload-file`, formData, {
      headers: this._header.getServiceHeader(),
      withCredentials: true,
      reportProgress: true,
      responseType: 'json',
      observe: 'events'
    });
  }

  download(params: any) {
    return this._http.get<ResponseState<any>>(
      `${this._appConfig.uploadV1Url}manage/download-file?keyName=${params.keyName}&folder=${params.folder}&tenant=${params.tenant}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  updateFile(file: CfAttachment) {
    return this._http.put<ResponseState<CfAttachment>>(
      `${this._appConfig.uploadV1Url}manage/update-file/${file.id}`, file,{
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  delete(filename: string) {
    return this._http.delete<ResponseState<string>>(
      `${this._appConfig.uploadV1Url}manage/delete-file?filename=${filename}`,{
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  fileDeleteById(fileId: string) {
    return this._http.delete<ResponseState<string>>(
      `${this._appConfig.uploadV1Url}manage/storage/files/${fileId}`,{
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  fileMoveToTrash(fileId: string) {
    return this._http.get<ResponseState<CfAttachment>>(
      `${this._appConfig.uploadV1Url}manage/storage/files/${fileId}/move-to-trash`,{
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  fileRestore(fileId: string) {
    return this._http.get<ResponseState<CfAttachment>>(
      `${this._appConfig.uploadV1Url}manage/storage/files/${fileId}/restore`,{
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  deleteAll() {
    return this._http.delete<ResponseState<string>>(
      `${this._appConfig.uploadV1Url}manage/delete-all`,{
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  getByKey(folderKey: string) {
    return this._http.get<ResponseState<CfFolder>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/key/${folderKey}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  list() {
    return this._http.get<ResponseState<CfFolder[]>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  listParents() {
    return this._http.get<ResponseState<CfFolder[]>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/parents`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  create(folder: CfFolder) {
    return this._http.post<ResponseState<CfFolder>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders`, folder, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      },
    );
  }

  update(folderId: string, folder: CfFolder) {
    return this._http.put<ResponseState<CfFolder>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/${folderId}`, folder, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      },
    );
  }

  invite(user: CfUser | any, folderId: string) {
    return this._http.post<ResponseState<CfFolder>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/${folderId}/invite`, user, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      },
    );
  }

  accept(folderId: string, validationCode: string) {
    return this._http.get<ResponseState<string>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/${folderId}/join-folder/${validationCode}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      },
    );
  }

  folderRestore(folderId: string) {
    return this._http.get<ResponseState<CfFolder>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/${folderId}/restore`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      },
    );
  }

  folderTrash(folderId: string) {
    return this._http.get<ResponseState<CfFolder>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/${folderId}/move-to-trash`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      },
    );
  }

  folderDelete(folderId: string) {
    return this._http.delete<ResponseState<string>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/${folderId}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      },
    );
  }

  storageSize(userId: string | undefined) {
    return this._http.get<ResponseState<string>>(
      `${this._appConfig.uploadV1Url}manage/storage/files/size/${userId}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      },
    );
  }

}
