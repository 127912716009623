import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InitialPipe} from "./initial.pipe";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    InitialPipe
  ],
  exports: [
    InitialPipe
  ]
})
export class InitialPipeModule {
}
