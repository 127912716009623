import {Component, Input} from '@angular/core';
import {
  CfTask,
  CfTaskStep,
  TaskStepEnum,
  TaskStepTitleLabelsAndValues
} from "@app-web-central/web/shared/data-access/models";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'as-task-drawer-steps',
  templateUrl: './task-drawer-steps.component.html',
  styleUrls: ['./task-drawer-steps.component.scss'],
})
export class TaskDrawerStepsComponent {
  @Input() task!: CfTask;
  @Input() steps!: CfTaskStep[];

  constructor(
    public translate: TranslateService
  ) { }

  get currentStep() {
    return this.steps[this.steps.length-1].step;
  }

  sortBy(prop: string) {
    const copySteps = [...this.steps] as any[];
    return copySteps.sort(
      (a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
    );
  }

  getCurrentStep(step: CfTaskStep) {
    return TaskStepTitleLabelsAndValues[step.stepStatus].label;
  }

  getLoadingStatus(step: CfTaskStep) {
    if (TaskStepTitleLabelsAndValues[step.stepStatus].status === 'progress' && step.step === this.steps.length-1) {
      return 'loading';
    }
    return '';
  }

  getCurrentStatus(step: CfTaskStep) {
    if (TaskStepTitleLabelsAndValues[step.stepStatus].status === 'progress' && step.step === this.steps.length-1) {
      return 'progress';
    }
    if (TaskStepTitleLabelsAndValues[step.stepStatus].status === 'progress' && step.step !== this.steps.length-1) {
      return 'wait';
    }
    return TaskStepTitleLabelsAndValues[step.stepStatus].status;
  }

  getCurrentStepDescription(step: CfTaskStep) {
    return TaskStepTitleLabelsAndValues[step.stepStatus].description;
  }

  getCurrentUserStep(step: CfTaskStep) {
      if (step.stepStatus === TaskStepEnum.INITIALIZATION) {
        return this.task.createdBy?.fullName;
      }
      return this.task.assignee?.fullName;
  }

}
