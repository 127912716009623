<div class="flex h-full">
  <as-task-drawer-sidebar
    [countComments]="countComments"
    (selectedMenu)="onChangeSelectedMenu($event)">
  </as-task-drawer-sidebar>

  <as-dropzone
    (selectedFiles)="onFilesSelected($event)">
  </as-dropzone>

  <div class="flex min-w-0 flex-1 flex-col overflow-hidden">
    <main class="flex flex-1 overflow-hidden">
      <section class="border-l border-gray-200 flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
        <form
          [formGroup]="taskForm"
          (ngSubmit)="submit()">
          <div class="bg-white">
            <div class="px-4 pt-6 sm:px-6 lg:max-w-screen-2xl lg:px-8">

              <ng-container
                *ngIf="task$ | async then contentRef else skeletonRef"
                [ngTemplateOutlet]="contentRef" [ngTemplateOutletContext]="{ task: task$ | async }">
              </ng-container>

              <ng-template #contentRef let-task>
                <div class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
                  <!-- Left Side -->
                  <div class="pb-6">

                    <div class="flex flex-col">
                      <small
                        class="text-sm text-gray-500"
                        nz-tooltip
                        nzTooltipTitle="{{ task.dateAdd | date: 'medium' : '' : translate.currentLang }}"
                        nzTooltipPlacement="topLeft">
                        {{ 'task_detail.created' | translate }} {{ task.dateAdd | dateAgo }} par {{ task.createdBy?.fullName }}
                      </small>
                      <small
                        class="text-sm text-gray-500"
                        nz-tooltip
                        nzTooltipTitle="{{ task.dateUpd | date: 'medium' : '' : translate.currentLang }}"
                        nzTooltipPlacement="topLeft">
                        {{ 'task_detail.updated' | translate }} {{ task.dateUpd | dateAgo }} par {{ task.modifiedBy?.fullName }}
                      </small>
                    </div>

                    <ng-container *ngIf="selectedMenu.label === 'constants.details'">
                      <as-task-drawer-name
                        [task]="task"
                        [control]="f('name')">
                      </as-task-drawer-name>

                      <div class="flex flex-col mt-3">
                        <h2 class="pb-2 text-sm font-medium text-gray-900">{{ 'task_detail.assignee' | translate }}</h2>

                        <div class="grid gap-2 grid-cols-2 grid-rows-1 w-full">
                          <as-combobox
                            class="col-span-1"
                            [props]="['fullName']"
                            [searchProp]="'fullName'"
                            placeholder="{{ 'task_detail.add_assignee' | translate }}"
                            [items]="assignees$ | async"
                            [disabled]="!!task.dates?.validationDate"
                            [selectedItem]="f('assignee').value"
                            (activeItem)="onSelectAssignee($event)" >
                          </as-combobox>

                          <as-task-drawer-priority
                            class="col-span-1"
                            [task]="task"
                            [control]="f('priority')">
                          </as-task-drawer-priority>
                        </div>
                      </div>

                      <as-task-drawer-dates
                        *ngIf="session$ | async as session"
                        [task]="task"
                        [session]="session"
                        [control]="f('dates')"
                        [isRruleControl]="f('isRecurring')"
                        [rruleControl]="f('recurrence')">
                      </as-task-drawer-dates>

                      <as-task-drawer-address
                        *ngIf="addresses$ | async as addresses"
                        [task]="task"
                        [control]="f('address')"
                        [addresses]="addresses">
                      </as-task-drawer-address>

                      <div class="grid gap-2 grid-cols-2 grid-rows-1 w-full mt-3">
                        <as-task-drawer-suite
                          class="col-span-1"
                          *ngIf="!!f('address').value?.suites.length"
                          [task]="task"
                          [control]="f('suite')"
                          [suites]="f('address').value.suites">
                        </as-task-drawer-suite>

                        <as-task-drawer-customer
                          class="col-span-1"
                          *ngIf="recipients$ | async as recipients"
                          [task]="task"
                          [control]="f('recipient')"
                          [recipients]="recipients">
                        </as-task-drawer-customer>
                      </div>

                      <as-task-drawer-description
                        [task]="task"
                        [control]="f('description')">
                      </as-task-drawer-description>

                      <as-task-drawer-tags
                        [task]="task"
                        [control]="f('tags')">
                      </as-task-drawer-tags>
                    </ng-container>

                    <ng-container *ngIf="selectedMenu.label === 'constants.comments'">
                      <as-task-drawer-comments
                        *ngIf="session$ | async as session"
                        [task]="task"
                        [session]="session">
                      </as-task-drawer-comments>
                    </ng-container>

                  </div>

                  <!-- Right side -->
                  <div class="mt-10 lg:mt-0">
                    <div class="flex items-center mt-2 mb-6">
                      <as-button
                        className="primary mr-2"
                        type="submit"
                        [isWorking]="isWorking$ | async"
                        [disabled]="taskForm.invalid">
                        {{ 'buttons.save' | translate }}
                      </as-button>
                      <as-button
                        (click)="cancel()"
                        className="tertiary">
                        {{ 'buttons.cancel' | translate }}
                      </as-button>

                      <div class="flex-auto"></div>

                      <as-button
                        nz-dropdown
                        nzTrigger="click"
                        [nzDropdownMenu]="status"
                        [nzDisabled]="!!task.dates?.validationDate"
                        [className]="'secondary'"
                        class="uppercase text-gray-500 text-sm">
                        {{ 'task_detail.status.' + task?.status?.toLowerCase() | translate }}
                      </as-button>

                      <as-button
                        class="ml-2"
                        type="button"
                        icon="outline/eye"
                        iconColor="text-gray-900"
                        iconSize="sm"
                        className="tertiary"
                        nz-dropdown
                        nz-tooltip
                        nzTooltipTitle="{{ 'tooltips.watch' | translate }}"
                        nzTooltipPlacement="bottom"
                        [hasBadge]="true"
                        [countValue]="subscribers(task.channel)"
                        [nzTrigger]="'click'"
                        nzPlacement="bottomRight"
                        [nzDropdownMenu]="watch">
                      </as-button>

                      <as-button
                        type="button"
                        *asHasPermission="['task:create', 'task:edit']"
                        icon="outline/square-2-stack"
                        iconColor="text-gray-900"
                        iconSize="sm"
                        className="tertiary"
                        nz-tooltip
                        [disabled]="!!task.dates?.validationDate"
                        nzTooltipTitle="{{ 'tooltips.clone' | translate }}"
                        nzTooltipPlacement="bottom"
                        (click)="onClone(task)">
                      </as-button>

                      <as-button
                        type="button"
                        *asHasPermission="['task:delete']"
                        icon="outline/trash"
                        iconColor="text-gray-900"
                        iconSize="sm"
                        className="tertiary"
                        nz-tooltip
                        [disabled]="!!task.dates?.validationDate"
                        nzTooltipTitle="{{ 'tooltips.remove' | translate }}"
                        nzTooltipPlacement="bottom"
                        (click)="onDelete(task)">
                      </as-button>

                      <as-button
                        type="button"
                        icon="solid/dots-horizontal"
                        iconColor="text-gray-900"
                        iconSize="sm"
                        className="tertiary"
                        nz-dropdown
                        [nzTrigger]="'click'"
                        [nzDropdownMenu]="menu">
                      </as-button>
                    </div>

                    <div class="mt-24">
                      <nz-tabset class="pt-4 pb-2">
                        <nz-tab nzTitle="{{ 'task_detail.interventions' | translate }}">
                          <div class="p-1">
                            <as-task-drawer-interventions
                              [task]="task"
                              [control]="f('interventions')"
                              [interventions]="interventions$ | async">
                            </as-task-drawer-interventions>
                            <div class="mt-3">
                              <label class="label">
                                {{ 'add_task.task_metadata' | translate }}
                              </label>
                              <as-task-drawer-metadata
                                (removeMetadata)="onRemoveMetadata($event)"
                                (editMetadata)="onEditMetadata($event)"
                                [control]="f('metadata')">
                              </as-task-drawer-metadata>
                              <div *ngIf="openMetadataForm">
                                <as-task-drawer-metadata-add
                                  [controlKey]="f('metadataKey')"
                                  [controlValue]="f('metadataValue')"
                                  (metadataChanged)="onAddMetadata($event)">
                                </as-task-drawer-metadata-add>
                              </div>
                              <div class="mt-3">
                                <as-button
                                  className="tertiary"
                                  (click)="onOpenMetadataForm()"
                                  [disabled]="!!task.dates?.validationDate"
                                  class="uppercase text-gray-800 text-sm">
                                  <div class="flex items-center">
                                    <svg-icon key="mini/plus" class="!flex !items-center"></svg-icon>
                                    {{ 'task_detail.add_metadata' | translate }}
                                  </div>
                                </as-button>
                              </div>
                            </div>
                          </div>
                        </nz-tab>
                        <nz-tab nzTitle="{{ 'task_detail.timeline' | translate }}">
                          <as-task-drawer-steps
                            [task]="task"
                            [steps]="task.steps">
                          </as-task-drawer-steps>
                        </nz-tab>
                        <nz-tab nzTitle="{{ 'task_detail.documents' | translate }} ({{!!f('attachments')?.value.length ? f('attachments')?.value.length : 0}})">
                          <as-task-drawer-documents
                            *ngIf="session$ | async as session"
                            [task]="task"
                            [filesDropped]="filesLoaded"
                            [folder]="'task'"
                            [session]="session"
                            [control]="f('attachments')">
                          </as-task-drawer-documents>
                        </nz-tab>
                      </nz-tabset>
                    </div>

                  </div>
                </div>

                <nz-dropdown-menu #status="nzDropdownMenu">
                  <ul nz-menu class="dropdown mt-3">
                    <ng-container *ngFor="let state of statuses; let i = index">
                      <li nz-menu-item class="dropdown-item" (click)="onChangeStatus(task, i)">
                        <div class="flex items-center">
                          <span class="ml-3 font-medium text-gray-800 text-sm">
                            {{ 'task_detail.status.' + state.value.toLowerCase() | translate }}
                          </span>
                        </div>
                      </li>
                    </ng-container>
                  </ul>
                </nz-dropdown-menu>

                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ng-container nz-menu>
                    <ul class="dropdown" nz-menu *asHasPermission="['task:edit']">
                      <li class="dropdown-item" nz-menu-item (click)="onStart(task)"
                          [ngClass]="{'disabled': task.dates?.validationDate}">
                        {{ 'buttons.start' | translate }}
                      </li>
                      <li class="dropdown-item" nz-menu-item (click)="onStop(task)"
                          [ngClass]="{'disabled': task.dates?.validationDate}">
                        {{ 'buttons.stop' | translate }}
                      </li>
                      <li class="dropdown-item" nz-menu-item (click)="onValidate(task)"
                          [ngClass]="{'disabled': task.dates?.validationDate}">
                        {{ 'buttons.validate' | translate }}
                      </li>
                    </ul>
                  </ng-container>
                </nz-dropdown-menu>

                <nz-dropdown-menu #watch="nzDropdownMenu">
                  <ng-container nz-menu>
                    <ul class="dropdown" nz-menu *ngIf="session$ | async as session">
                      <li class="dropdown-item" nz-menu-item
                          (click)="onStartWatching(task, session.id)">
                        <div class="flex items-center">
                          <svg-icon
                            key="outline/eye">
                          </svg-icon>
                          <span class="ml-1-5 mr-1-5">{{ 'task_detail.start_watching' | translate }}</span>
                        </div>
                      </li>
                      <li class="dropdown-item" nz-menu-item
                          (click)="onStopWatching(task, session.id)">
                        <div class="flex items-center">
                          <svg-icon
                            key="outline/eye-off">
                          </svg-icon>
                          <span class="ml-1-5 mr-1-5">{{ 'task_detail.stop_watching' | translate }}</span>
                        </div>
                      </li>
                      <li nz-menu-divider></li>
                      <li nz-menu-item>
                        <span class="text-sm text-textLight uppercase">{{ 'task_detail.who_watching' | translate }}</span>
                      </li>
                      <ng-container *ngIf="task.channel && task.channel.subscribers">
                        <li class="dropdown-item" nz-menu-item *ngFor="let subscriber of task.channel.subscribers">
                          <div class="flex items-center" *ngIf="!subscriber.unsubscribed">
                            <as-user [user]="subscriber"></as-user>
                            <svg-icon
                              *ngIf="session.id === subscriber.subscriberId"
                              (click)="onStopWatching(task, subscriber.subscriberId)"
                              key="outline/x-mark">
                            </svg-icon>
                          </div>
                        </li>
                        <li class="dropdown-item" nz-menu-item *ngIf="task.channel.subscribers.length < 1">
                          <div class="flex items-center">
                            <svg-icon
                              key="mini/magnifying-glass">
                            </svg-icon>
                            <span class="ml-1-5 mr-1-5 text-sm text-gray-500">{{ 'task_detail.no_watchers' | translate }}</span>
                          </div>
                        </li>
                      </ng-container>
                    </ul>
                  </ng-container>
                </nz-dropdown-menu>

              </ng-template>

              <ng-template #skeletonRef>
                <div class="flex w-full flex-wrap pb-16">
                  <div class="sm:w-full md:w-7/12 lg:w-4/6 pr-10">
                    <nz-skeleton class="my-4"></nz-skeleton>
                    <nz-skeleton class="mt-4"
                                 [nzAvatar]="true"
                                 [nzParagraph]="{ rows: 2 }">
                    </nz-skeleton>
                  </div>
                  <div class="sm:w-full md:w-5/12 lg:w-2/6 pt-1">
                    <nz-skeleton class="my-4"></nz-skeleton>
                  </div>
                </div>
              </ng-template>

            </div>
          </div>
        </form>
      </section>
    </main>
  </div>

</div>
