import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'as-task-drawer-metadata-add',
  templateUrl: './task-drawer-metadata-add.component.html',
  styleUrls: ['./task-drawer-metadata-add.component.scss'],
})
export class TaskDrawerMetadataAddComponent {
  @Input() controlKey!: FormControl;
  @Input() controlValue!: FormControl;
  @Output() metadataChanged = new EventEmitter<any | null>();

  onSaveMetadataForm() {
    this.metadataChanged.emit({
      key: this.controlKey.value,
      value: this.controlValue.value,
    });
    this.reset();
  }

  private reset() {
    this.controlKey.reset();
    this.controlValue.reset();
  }

  onCancelMetadataForm() {
    this.reset();
    this.metadataChanged.emit(null);
  }
}
