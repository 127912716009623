import {CfOrganization, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {loadOrganizations, loadOrganizationsError, loadOrganizationsSuccess} from "./organizations.actions";

export interface OrganizationsState extends GenericState<CfOrganization[]> { }

const initialState: OrganizationsState = {
  data: null,
  error: null,
  status: 'pending'
}

export const organizationsFeatureKey = 'organizations';

export const organizationsReducer = createReducer(
  initialState,
  on(loadOrganizations, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadOrganizationsSuccess, (state, { organizations }) => ({
    ...state,
    data: organizations,
    status: 'success',
    error: null
  })),
  on(loadOrganizationsError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
)
