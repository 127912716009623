import {Injectable} from "@angular/core";
import {ConfigApi} from "@app-web-central/web/shared/data-access/cf-api";
import {combineLatest, map} from "rxjs";

@Injectable({ providedIn: 'root' })
export class SettingsService {
  constructor(
    private _configApi: ConfigApi
  ) { }

  loadSettingsData() {
    return combineLatest([
      this._configApi.fetchCountries(),
      this._configApi.fetchLanguages()
    ]).pipe(
      map(combine => {
        return {countries: combine[0].payload, languages: combine[1].payload};
      })
    );
  }

  /**
   * Get base app available countries from api.
   *
   */
  getCountries() {
    return this._configApi.fetchCountries().pipe(map(countries => countries.payload));
  }

  /**
   * Get base app available languages from api.
   *
   */
  getLanguages() {
    return this._configApi.fetchLanguages().pipe(map(languages => languages.payload));
  }

}
