import {HeaderApi} from "./header-api";
import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient, HttpParams} from "@angular/common/http";
import {CfEventStats, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({providedIn: 'root'})
export class EventStatsApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) { }

  /**
   * Get event stats by params.
   *
   * @param queryParams as { *UrlParams } cast to { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; }.
   */
  get(queryParams: { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; }) {
    return this._http.get<ResponseState<CfEventStats>>(
      `${this._appConfig.eventStatsV1Url}dataset`, {
        params: new HttpParams({ fromObject: queryParams }),
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

}
