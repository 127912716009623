import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavItem} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-task-drawer-sidebar',
  templateUrl: './task-drawer-sidebar.component.html',
  styleUrls: ['./task-drawer-sidebar.component.scss'],
})
export class TaskDrawerSidebarComponent implements OnInit {
  @Input() countComments!: number;
  @Output() selectedMenu = new EventEmitter<NavItem>();
  items!: NavItem[];

  ngOnInit(): void {
    this.items = [
      new NavItem('constants.details', 'outline/document-text', this.switchView.bind(this), true),
      new NavItem('constants.comments', 'outline/chat-bubble-bottom', this.switchView.bind(this), false)
    ];
    this.selectedMenu.emit(this.items[0]);
  }

  className(item: NavItem) {
    if (item.checked) {
      return `tertiary checked`
    }
    return `tertiary`
  }

  switchView() {
    this.items.forEach((item) => {
      item.checked = false;
    });
  }

  emitView(item: NavItem) {
    this.selectedMenu.emit(item);
  }

}
