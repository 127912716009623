import {HeaderApi} from "./header-api";
import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfTeam, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({providedIn: 'root'})
export class GroupApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) { }

  create(group: CfTeam) {
    return this._http.post<ResponseState<CfTeam>>(
      `${this._appConfig.generalConfigV1Url}manage/teams`, group, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  update(groupId: string, group: CfTeam) {
    return this._http.put<ResponseState<CfTeam>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/${groupId}`, group, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  get(groupId: string) {
    return this._http.get<ResponseState<CfTeam>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/${groupId}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  list() {
    return this._http.get<ResponseState<CfTeam[]>>(
      `${this._appConfig.generalConfigV1Url}manage/teams`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  delete(groupId: string) {
    return this._http.delete<ResponseState<CfTeam>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/${groupId}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  deleteBulk(groupIds: string[]) {
    return this._http.delete<ResponseState<string>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/bulk/${groupIds}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  enable(groupId: string) {
    return this._http.get<ResponseState<CfTeam>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/${groupId}/enable`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  enableBulk(groupIds: string[]) {
    return this._http.post<ResponseState<CfTeam[]>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/bulk/enable`, groupIds, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  disable(groupId: string) {
    return this._http.get<ResponseState<CfTeam>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/${groupId}/disable`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  disableBulk(groupIds: string[]) {
    return this._http.post<ResponseState<CfTeam[]>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/bulk/disable`, groupIds, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

}
