import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CfComment, CfCommentLikes, CfTask, CfUser} from "@app-web-central/web/shared/data-access/models";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TaskStore} from "@app-web-central/web/task/data-access";

@Component({
  selector: 'as-task-drawer-comment',
  templateUrl: './task-drawer-comment.component.html',
  styleUrls: ['./task-drawer-comment.component.scss'],
})
export class TaskDrawerCommentComponent implements OnInit {
  @Input() task!: CfTask;
  @Input() taskId!: string | undefined;
  @Input() comment!: CfComment;
  @Input() session!: CfUser;
  @Input() createMode!: boolean;
  @Output() editComment = new EventEmitter<{ comment: CfComment, editMode: boolean }>();
  @Output() replyComment = new EventEmitter<{ comment: CfComment, editMode: boolean }>();

  @Input() set commentToEdit(comment: CfComment) {
    if (comment && this.createMode) {
      this._updateForm(comment);
      this.setCommentEdit(true);
    }
  }

  @Input() set commentToReply(comment: CfComment) {
    if (comment && this.createMode) {
      this._replyForm(comment);
      this.setCommentEdit(true);
    }
  }

  @ViewChild('commentBoxRef') commentBoxRef!: ElementRef;
  commentForm!: FormGroup;
  isEditing!: boolean;

  constructor(
    private _taskStore: TaskStore,
    private _formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this._initForm();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (!this.createMode || this.isEditing) {
      return;
    }
    if (event.key === 'C') {
      this.commentBoxRef.nativeElement.focus();
      this.isEditing = true;
    }
  }

  setCommentEdit(mode: boolean) {
    this.isEditing = mode;
  }

  cancel() {
    this.commentForm.reset();
    this.setCommentEdit(false);
  }

  remove(comment: CfComment) {
    if (!comment.id) {
      return;
    }
    this._taskStore.removeCommentTask({ task: this.task, comment });
  }

  reply(comment: CfComment) {
    if (!comment.id) {
      return;
    }
    this.replyComment.emit({ comment, editMode: true });
  }

  edit(comment: CfComment) {
    if (!comment.id) {
      return;
    }
    this.editComment.emit({ comment, editMode: true });
  }

  like(comment: CfComment): void {
    const userId = this.session.id;
    this._taskStore.likeComment({ task: this.task, comment, userId });
  }

  getLikes(likes: CfCommentLikes[]) {
    let sum = 0;
    if (likes && likes.length > 0) {
      likes.forEach(like => {
        sum = sum + like.liked;
      });
    }
    return sum;
  }

  dislike(comment: CfComment): void {
    const userId = this.session.id;
    this._taskStore.dislikeComment({ task: this.task, comment, userId });
  }

  getDislikes(dislikes: CfCommentLikes[]) {
    let sum = 0;
    if (dislikes && dislikes.length > 0) {
      dislikes.forEach(dislike => {
        sum = sum + dislike.disliked;
      });
    }
    return sum;
  }

  submit() {
    if (this.commentForm.invalid) {
      return;
    }

    const formValue = this.commentForm.getRawValue();
    if (!formValue.id) {
      this._taskStore.addCommentTask({ task: this.task, comment: formValue });
    } else {
      this._taskStore.editCommentTask({ task: this.task, comment: formValue });
    }
    this.cancel();
  }

  private _initForm() {
    this.commentForm = this._formBuilder.group({
      id: [null],
      message: [null, [Validators.required]],
      createdBy: [this.session, [Validators.required]],
      eventId: [this.task.id, [Validators.required]],
      parentId: [null]
    });
  }

  private _updateForm(comment: CfComment) {
    this.commentForm.patchValue(comment);
  }

  private _replyForm(comment: CfComment) {
    this.commentForm.controls['createdBy'].setValue(this.session);
    this.commentForm.controls['eventId'].setValue(comment.eventId);
    this.commentForm.controls['parentId'].setValue(comment.id);
  }

}
