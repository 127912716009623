<div class="relative">
  <as-button
    nz-dropdown
    nzTrigger="click"
    [nzDropdownMenu]="menu"
    [className]="'secondary shadow-sm'"
    [disabled]="!!task.dates?.validationDate"
    [nzDisabled]="!!task.dates?.validationDate">
    <div class="flex items-center" *ngIf="control.value">
      <svg-icon
        size="sm"
        [key]="priorityIcon().icon"
        [color]="priorityIcon().color"
        class="!flex !items-center">
      </svg-icon>
      <span class="ml-1-5 mr-1-5">
        {{ 'task_detail.priorities.' + control.value.toLowerCase() | translate }}
      </span>
      <svg-icon
        (click)="removePriority()"
        size="sm"
        key="outline/x-mark"
        class="!flex !items-center text-gray-400">
      </svg-icon>
    </div>
    <div class="flex items-center text-gray-400" *ngIf="!control.value">
      {{ 'task_detail.add_priority' | translate }}
    </div>
  </as-button>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu class="dropdown absolute left-0 top-0 mt-4">
      <li nz-menu-item
          class="dropdown-item"
          *ngFor="let priority of priorities"
          (click)="onChangePriority(priority.value)">
        <div class="flex items-center">
          <svg-icon
            size="sm"
            [key]="priority.icon"
            [color]="priority.color"
            class="!flex !items-center">
          </svg-icon>
          <span class="ml-1-5 mr-1-5">
          {{ 'task_detail.' + priority.text.toLowerCase() | translate }}
        </span>
        </div>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>
