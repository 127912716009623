import {HeaderApi} from "./header-api";
import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {
  CfContactUs,
  CfFeedback,
  CfUserNotification,
  ResponseState
} from "@app-web-central/web/shared/data-access/models";

@Injectable({providedIn: 'root'})
export class NotificationApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) {}

  contactUs(contactUs: CfContactUs) {
    return this._http.post<ResponseState<any>>(
      `${this._appConfig.notificationV1Url}contact-us`, contactUs, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  feedback(notification: CfFeedback) {
    return this._http.post<ResponseState<any>>(
      `${this._appConfig.notificationV1Url}support`, notification, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  findLoad10ByUserId(userId: string, count: number) {
    return this._http.get<ResponseState<CfUserNotification[]>>(
      `${this._appConfig.notificationV1Url}users/notifications/load-10?count=${count}&userId=${userId}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  read(notificationId: string) {
    return this._http.get<ResponseState<CfUserNotification>>(
      `${this._appConfig.notificationV1Url}users/notifications/${notificationId}/read`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

}
