import {CfIntervention, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {loadInterventions, loadInterventionsError, loadInterventionsSuccess} from "./interventions.actions";

export interface InterventionsState extends GenericState<CfIntervention[]> { }

const initialState: InterventionsState = {
  data: null,
  error: null,
  status: 'pending'
}

export const interventionsFeatureKey = 'interventions';

export const interventionsReducer = createReducer(
  initialState,
  on(loadInterventions, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadInterventionsSuccess, (state, { interventions }) => ({
    ...state,
    data: interventions,
    status: 'success',
    error: null
  })),
  on(loadInterventionsError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
