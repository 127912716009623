<div class="recurrence-modal">
  <div class="flex items-center pb-3 text-textDarkest">
    <div class="text-xl">
      {{ 'add_task.title' | translate }}
    </div>
    <div class="flex-auto"></div>
    <as-button
      icon="outline/x-mark"
      iconSize="md"
      (click)="closeModal()"
      className="tertiary">
    </as-button>
  </div>

  <form [formGroup]="taskForm" (ngSubmit)="submit()" class="w-full">
    <div class="lg:grid lg:grid-cols-6 lg:gap-x-12 xl:gap-x-16">
      <div class="col-span-4">
        <div class="form-group">
          <label class="label">
            {{ 'add_task.task_project' | translate }}
          </label>
          <as-task-project-select
            [control]="f('project')"
            (activeProject)="onChangeProject($event)"
            [projects]="projects$ | async">
          </as-task-project-select>
        </div>

        <div class="mt-3 form-group">
          <label class="label">
            {{ 'add_task.task_title' | translate }}
          </label>
          <input
            type="text"
            class="form-input"
            placeholder="{{ 'add_task.task_title_placeholder' | translate }}"
            formControlName="name"
            asAutofocus/>
        </div>

        <div class="mt-3 form-group">
          <label class="label">
            {{ 'add_task.task_description' | translate }}
          </label>
          <quill-editor
            class="content-editor"
            [styles]="{'min-height': '120px'}"
            [modules]="editorOptions"
            formControlName="description"
            placeholder="{{ 'add_task.task_description_placeholder' | translate }}">
          </quill-editor>
        </div>

        <div class="mt-3 form-group">
          <label class="label">
            {{ 'add_task.task_reporter' | translate }}
          </label>
          <as-task-reporter-select
            *ngIf="reporters$ | async as reporters"
            [control]="f('createdBy')"
            [users]="reporters">
          </as-task-reporter-select>
        </div>

        <ng-container *ngIf="session$ | async as session">
          <div class="mt-3 form-group">
            <label class="label">
              {{ 'add_task.task_delivery_date' | translate }}
            </label>
            <as-task-delivery-date
              [control]="f('deliveryDate')"
              [session]="session">
            </as-task-delivery-date>
            <p class="mt-2 text-xs text-gray-500 flex items-center">
              <svg-icon key="mini/question-circle" class="!flex !items-center mr-1"></svg-icon>
              {{ 'add_task.task_delivery_date_optional' | translate }}
            </p>
          </div>
        </ng-container>
      </div>
      <div class="col-span-2 mt-10 lg:mt-0">
        <div class="form-group">
          <label class="label">
            {{ 'add_task.task_assignees' | translate }}
          </label>
          <as-task-assignees-select
            *ngIf="assignees$ | async as assignees"
            [control]="f('assignee')"
            [users]="assignees">
          </as-task-assignees-select>
          <p class="mt-2 text-xs text-gray-500 flex items-center">
            <svg-icon key="mini/question-circle" class="!flex !items-center mr-1"></svg-icon>
            {{ 'add_task.task_assignees_optional' | translate }}
          </p>
        </div>

        <div class="mt-3 form-group">
          <label class="label">
            {{ 'add_task.task_address' | translate }}
          </label>
          <as-task-address-select
            *ngIf="addresses$ | async as addresses"
            [control]="f('address')"
            [addresses]="addresses">
          </as-task-address-select>
        </div>

        <div class="mt-3 form-group"
             *ngIf="f('address').value
                && f('address').value.suites
                && f('address').value.suites.length">
          <label class="label">
            {{ 'add_task.task_suite' | translate }}
          </label>
          <as-task-suite-select
            *ngIf="f('suite')"
            [control]="f('suite')"
            [address]="f('address').value">
          </as-task-suite-select>
        </div>

        <div class="mt-3 form-group">
          <label class="label">
            {{ 'add_task.task_metadata' | translate }}
          </label>

          <div class="border border-gray-200 rounded-md p-2"
               *ngIf="f('metadata').value && f('metadata').value.length">
            <ng-container *ngFor="let data of f('metadata').value">
              <div class="flex items-center justify-between">
                <p class="text-gray-500 max-w-[12rem]">
                  <span class="font-semibold">{{ data.key }}</span> : {{ data.value }}
                </p>
                <div>
                  <as-button
                    class="ml-1.5"
                    (click)="onEditMetadata(data)"
                    className="tertiary"
                    iconSize="xs"
                    icon="outline/pencil">
                  </as-button>
                  <as-button
                    class="ml-1.5"
                    (click)="onRemoveMetadata(data)"
                    className="tertiary"
                    iconSize="xs"
                    icon="outline/trash">
                  </as-button>
                </div>
              </div>
            </ng-container>
          </div>

          <div *ngIf="openMetadataForm">
            <as-task-metadata
              [controlKey]="f('metadataKey')"
              [controlValue]="f('metadataValue')"
              (metadataChanged)="onAddMetadata($event)">
            </as-task-metadata>
          </div>

          <div class="mt-3 flex items-center">
            <as-button
              (click)="onOpenMetadataForm()"
              className="secondary">
              {{ 'buttons.add' | translate }}
            </as-button>
            <as-button
              *ngIf="openMetadataForm"
              class="ml-2"
              (click)="onCancelMetadataForm()"
              className="tertiary">
              {{ 'buttons.cancel' | translate }}
            </as-button>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 form-group form-action">
      <as-button
        className="primary mr-2"
        type="submit"
        [disabled]="taskForm.invalid">
        {{ 'buttons.save' | translate }}
      </as-button>
      <as-button
        className="tertiary"
        (click)="cancel()">
        {{ 'buttons.cancel' | translate }}
      </as-button>
    </div>

  </form>

</div>
