export enum InvoiceEnum {
  PAID = "PAID",
  DRAFT = "DRAFT",
  VOID = "VOID",
  OPEN = "OPEN",
  UNCOLLECTIBLE = "UNCOLLECTIBLE"
}

export const InvoiceDisplayValues = {
  [InvoiceEnum.OPEN]: 'invoice.status.open',
  [InvoiceEnum.DRAFT]: 'invoice.status.draft',
  [InvoiceEnum.PAID]: 'invoice.status.paid',
  [InvoiceEnum.VOID]: 'invoice.status.void',
  [InvoiceEnum.UNCOLLECTIBLE]: 'invoice.status.uncollectible'
}

export const InvoiceDisplayColor = {
  [InvoiceEnum.OPEN]: 'badge-primary',
  [InvoiceEnum.DRAFT]: 'badge-secondary',
  [InvoiceEnum.PAID]: 'badge-success',
  [InvoiceEnum.VOID]: 'badge-danger',
  [InvoiceEnum.UNCOLLECTIBLE]: 'badge-warning'
}
