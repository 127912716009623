import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComboboxComponent} from './combobox.component';
import {ButtonModule} from "@app-web-central/web/shared/ui/button";
import {TranslateModule} from "@ngx-translate/core";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {UserModule} from "@app-web-central/web/shared/ui/user";
import {IconModule} from "@app-web-central/web/shared/ui/icon";
import {FormsModule} from "@angular/forms";
import {ListFilterPipeModule} from "@app-web-central/web/shared/pipes/list-filter";
import {NzEmptyModule} from "ng-zorro-antd/empty";

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule,
    NzDropDownModule,
    UserModule,
    IconModule,
    FormsModule,
    ListFilterPipeModule,
    NzEmptyModule
  ],
  declarations: [
    ComboboxComponent
  ],
  exports: [
    ComboboxComponent
  ],
})
export class ComboboxModule {
}
