import {createAction, props} from "@ngrx/store";
import {CfScope} from "@app-web-central/web/shared/data-access/models";

export const loadScopes = createAction(
  '[Scope List] Load Scopes'
);

export const loadScopesSuccess = createAction(
  '[Scope List] Load Scopes Success',
  props<{
    scopes: CfScope[]
  }>()
);

export const loadScopesError = createAction(
  '[Scope List] Load Scopes Error',
  props<{
    error: string
  }>()
);
