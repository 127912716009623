import {createAction, props} from "@ngrx/store";
import {CfRecipient} from "@app-web-central/web/shared/data-access/models";

export const loadRecipients = createAction('[Recipient List] Load Recipients');

export const loadRecipientsSuccess = createAction(
  '[Recipient List] Load Recipients Success',
  props<{
    recipients: CfRecipient[];
  }>()
);

export const loadRecipientsError = createAction(
  '[Recipient List] Load Recipients Error',
  props<{
    error: string;
  }>()
);
