import {GenericState} from "@app-web-central/web/shared/data-access/models";

export class SelectorUtil {
  static isLoading({ status }: GenericState<unknown>) {
    return status === 'loading';
  }

  static isDone({ status }: GenericState<unknown>) {
    return status === 'success' || status === 'error';
  }
}
