<div class="mt-3 grid grid-cols-12 gap-3">
  <div class="col-span-12 sm:col-span-4">
    <label class="label">
      {{ 'add_task.task_metadata_key' | translate }}
    </label>
    <input
      type="text"
      class="form-input"
      [style.max-height.rem]="2.5"
      [formControl]="controlKey" />
  </div>
  <div class="col-span-12 sm:col-span-6">
    <div>
      <label class="label">
        {{ 'add_task.task_metadata_value' | translate }}
      </label>
      <input
        type="text"
        class="form-input"
        [formControl]="controlValue" />
    </div>
  </div>
  <div class="col-span-12 sm:col-span-2">
    <label class="label" [style.height.px]="28.5"></label>
    <as-button
      icon="mini/check"
      iconSize="xs"
      (click)="onSaveMetadataForm()"
      className="tertiary">
    </as-button>
    <as-button
      icon="mini/x-circle"
      iconSize="xs"
      (click)="onCancelMetadataForm()"
      className="tertiary">
    </as-button>
  </div>
</div>
