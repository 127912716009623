import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavBarPickerAreaComponent} from './nav-bar-picker-area.component';
import {NzSelectModule} from "ng-zorro-antd/select";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    NzSelectModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  declarations: [
    NavBarPickerAreaComponent
  ],
  exports: [
    NavBarPickerAreaComponent
  ],
})
export class NavBarPickerAreaModule {
}
