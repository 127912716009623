import {RouterUtil} from "./router-util";

export class RouteUtil {
  static getHomeRouteUrl() {
    return `/${RouterUtil.Configuration.Home}`;
  }
  static getProjectsRouteUrl() {
    return `/${RouterUtil.Configuration.Projects}`;
  }
  static getProjectRouteUrl(projectKey: string) {
    return `/${RouterUtil.Configuration.Projects}/${projectKey}`;
  }
  static getProjectDetailRouteUrl(projectKey: string) {
    return `/${RouterUtil.Configuration.Projects}/${projectKey}/${RouterUtil.Configuration.ProjectDetails}`;
  }
  static getProjectAccessRouteUrl(projectKey: string) {
    return `/${RouterUtil.Configuration.Projects}/${projectKey}/${RouterUtil.Configuration.ProjectAccess}`;
  }
  static getProjectInviteMemberRouteUrl(projectKey: string) {
    return `/${RouterUtil.Configuration.Projects}/${projectKey}/${RouterUtil.Configuration.ProjectAccess}/${RouterUtil.Configuration.ProjectAccessInvite}`;
  }
  static getEventsRouteUrl(projectKey: string) {
    return `/${RouterUtil.Configuration.Projects}/${projectKey}/${RouterUtil.Configuration.Todo}`;
  }
  static getEventListRouteUrl(projectKey: string) {
    return `/${RouterUtil.Configuration.Projects}/${projectKey}/${RouterUtil.Configuration.Tasks}`;
  }
  static getCalendarRouteUrl(projectKey: string) {
    return `/${RouterUtil.Configuration.Projects}/${projectKey}/${RouterUtil.Configuration.Calendar}`;
  }
  static getMapRouteUrl() {
    return `/${RouterUtil.Configuration.Map}`;
  }
  static getStorageRouteUrl() {
    return `/${RouterUtil.Configuration.Storage}`;
  }
  static getFolderRouteUrl(folderKey: string) {
    return `/${RouterUtil.Configuration.Storage}/${RouterUtil.Configuration.Folder}/${folderKey}`;
  }
  static getFoldersRouteUrl() {
    return `/${RouterUtil.Configuration.Storage}/${RouterUtil.Configuration.Folders}`;
  }
  static getFoldersMyRouteUrl() {
    return `/${RouterUtil.Configuration.Storage}/${RouterUtil.Configuration.FoldersMy}`;
  }
  static getFolderForSharedRouteUrl() {
    return `/${RouterUtil.Configuration.Storage}/${RouterUtil.Configuration.FolderShared}`;
  }
  static getFoldersRecentRouteUrl() {
    return `/${RouterUtil.Configuration.Storage}/${RouterUtil.Configuration.FolderRecent}`;
  }
  static getFoldersStarredRouteUrl() {
    return `/${RouterUtil.Configuration.Storage}/${RouterUtil.Configuration.FolderStarred}`;
  }
  static getFoldersTrashedRouteUrl() {
    return `/${RouterUtil.Configuration.Storage}/${RouterUtil.Configuration.FolderTrashed}`;
  }
  static getMyProfileRouteUrl() {
    return `${RouterUtil.ExternalUrls.Profile}`;
  }
  static getAdminRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}`;
  }
  static getAdminGreetingRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Home}`;
  }
  static getGeneralSettingsRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.GeneralSettings}`;
  }
  static getUsersRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Users}`;
  }
  static getUserRouteUrl(userId: string) {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Users}/${userId}`;
  }
  static getUserAddRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Users}/add`;
  }
  static getGroupsRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Groups}`;
  }
  static getGroupRouteUrl(groupId: string) {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Groups}/${groupId}`;
  }
  static getGroupAddRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Groups}/add`;
  }
  static getRolesRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Roles}`;
  }
  static getRoleRouteUrl(roleId: string) {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Roles}/${roleId}`;
  }
  static getRoleAddRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Roles}/add`;
  }
  static getAddressesRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Addresses}`;
  }
  static getAddressRouteUrl(addressId: string) {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Addresses}/${addressId}`;
  }
  static getAddressAddRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Addresses}/add`;
  }
  static getSuitesRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Suites}`;
  }
  static getSuiteRouteUrl(suiteId: string) {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Suites}/${suiteId}`;
  }
  static getHubsRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Hubs}`;
  }
  static getHubRouteUrl(hubId: string) {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Hubs}/${hubId}`;
  }
  static getHubAddRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Hubs}/add`;
  }
  static getRecipientsRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Recipients}`;
  }
  static getRecipientRouteUrl(recipientId: string) {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Recipients}/${recipientId}`;
  }
  static getRecipientAddRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Recipients}/add`;
  }
  static getAppSettingsRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.AppSettings}`;
  }
  static getSubscriptionRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Subscription}`;
  }
  static getSubscriptionChangeRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Subscription}/${RouterUtil.Configuration.SubscriptionChange}`;
  }
  static getComplianceRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Compliance}`;
  }
}
