import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DropzoneComponent} from './dropzone.component';
import {TranslateModule} from "@ngx-translate/core";
import {IconModule} from "@app-web-central/web/shared/ui/icon";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    IconModule
  ],
  declarations: [
    DropzoneComponent
  ],
  exports: [
    DropzoneComponent
  ],
})
export class DropzoneModule {
}
