import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DropdownComponent} from './dropdown.component';
import {IconModule} from "@app-web-central/web/shared/ui/icon";
import {TranslateModule} from "@ngx-translate/core";
import {ButtonModule} from "@app-web-central/web/shared/ui/button";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {RouterModule} from "@angular/router";
import {BadgeModule} from "@app-web-central/web/shared/ui/badge";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {FormsModule} from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    ButtonModule,
    TranslateModule,
    NzDropDownModule,
    RouterModule,
    BadgeModule,
    NzDatePickerModule,
    FormsModule
  ],
  declarations: [
    DropdownComponent
  ],
  exports: [
    DropdownComponent
  ],
})
export class DropdownModule {
}
