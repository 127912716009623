import {
  BadgeItemWithClass,
  CfTask,
  CfUser,
  Priority,
  TaskPriorityIcon,
  TaskStatus,
  TaskStepEnum,
  TaskStepIcon,
  TaskType
} from "@app-web-central/web/shared/data-access/models";
import {RouteUtil} from "./route-util";

export class TaskUtil {
  static getTaskStatusWithBadge(taskStatus: TaskStatus) {
    return new BadgeItemWithClass(taskStatus);
  }

  static searchString(str: string, searchString: string): boolean {
    str = str ?? '';
    searchString = searchString ?? '';
    return str.trim().toLowerCase().includes(searchString.trim().toLowerCase());
  }

  static searchStringInArray(arr: string[], searchString: string): boolean {
    arr = arr ?? [];
    searchString = searchString ?? '';
    return arr.includes(searchString.trim().toLowerCase());
  }

  static getTaskTypeIcon(taskType: TaskType): string {
    return taskType?.toLowerCase();
  }

  static getTaskStepTypeIcon(taskStep: TaskStepEnum) {
    return new TaskStepIcon(taskStep);
  }

  static getTaskPriorityIcon(priority: Priority): TaskPriorityIcon {
    return new TaskPriorityIcon(priority);
  }

  static buildUITaskObject(event: CfTask) {
    return {
      name: {id: event.id, identifier: event.identifier, name: event.name, description: event.description, tags: event.tags, suite: event.suite, address: event.address },
      assignee: event.assignee !== undefined ? event.assignee : null,
      status: event.status,
      dateAdd: event.dateAdd,
      dueDate: event.dates.deliveryDate
    };
  }

  static buildUITaskObjectWithoutDateAdd(event: CfTask) {
    return {
      name: {id: event.id, identifier: event.identifier, name: event.name, description: event.description, projectId: event.projectId, route: RouteUtil.getProjectsRouteUrl() },
      assignee: event.assignee !== undefined ? event.assignee : null,
      status: event.status,
      dueDate: event.dates.deliveryDate
    };
  }

  static setChannelSubscribers(task: CfTask, users: CfUser[] | null) {
    const subscribers = [...task.channel.subscribers];
    for (const subscriber of subscribers) {
      const user = users?.find((x) => x.id === subscriber.subscriberId);
      subscriber.fullName = user != null ? user.fullName : 'Unknown user';
      subscriber.avatarUrl = user != null ? user.avatarUrl : 'Unknown avatar';
    }
    return subscribers;
  }

  static isDateWithinRange(date: number, day: number[], week: number[], month: number[], year: number[], customRange: number[]): boolean {
    if (day.length) {
      return date > day[0] && date < day[1];
    }
    if (week.length) {
      return date > week[0] && date < week[1];
    }
    if (month.length) {
      return date > month[0] && date < month[1];
    }
    if (year.length) {
      return date > year[0] && date < year[1];
    }
    if (customRange.length) {
      return date > customRange[0] && date < customRange[1];
    }
    return false;
  }

}
