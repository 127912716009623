import {CfFolder, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {
  loadFolders,
  loadFoldersError,
  loadFoldersSuccess,
  setLoadingFoldersState
} from "./folders.actions";

export interface FoldersState extends GenericState<CfFolder[]> { }

const initialState: FoldersState = {
  data: null,
  error: null,
  status: 'pending'
};

export const foldersFeatureKey = 'folders';

export const foldersReducer = createReducer(
  initialState,
  on(setLoadingFoldersState, (state, { status }) => ({ ...state, status })),
  on(loadFolders, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadFoldersSuccess, (state, { folders }) => ({
    ...state,
    data: folders,
    status: 'success',
    error: null
  })),
  on(loadFoldersError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
