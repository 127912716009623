import {HeaderApi} from "./header-api";
import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfHub, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({providedIn: 'root'})
export class HubApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) { }

  create(hub: CfHub) {
    return this._http.post<ResponseState<CfHub>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs`, hub, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  update(hubId: string, hub: CfHub) {
    return this._http.put<ResponseState<CfHub>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/${hubId}`, hub, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  get(hubId: string) {
    return this._http.get<ResponseState<CfHub>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/${hubId}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  list() {
    return this._http.get<ResponseState<CfHub[]>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  delete(hubId: string) {
    return this._http.delete<ResponseState<CfHub>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/${hubId}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  deleteBulk(hubIds: string[]) {
    return this._http.delete<ResponseState<string>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/bulk/${hubIds}`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  enable(hubId: string) {
    return this._http.get<ResponseState<CfHub>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/${hubId}/enable`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  enableBulk(hubIds: string[]) {
    return this._http.post<ResponseState<CfHub[]>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/bulk/enable`, hubIds, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  disable(hubId: string) {
    return this._http.get<ResponseState<CfHub>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/${hubId}/disable`, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  disableBulk(hubIds: string[]) {
    return this._http.post<ResponseState<CfHub[]>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/bulk/disable`, hubIds, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

}
