import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {CfReminderPriority, CfTask, ReminderPriorityValue} from "@app-web-central/web/shared/data-access/models";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SessionStore} from "@app-web-central/web/session/data-access";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {NzModalRef} from "ng-zorro-antd/modal";
import {NotificationFacade} from "@app-web-central/web/notification/data-access";

@UntilDestroy()
@Component({
  selector: 'as-modal-reminder',
  templateUrl: './modal-reminder.component.html',
  styleUrls: ['./modal-reminder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalReminderComponent implements OnInit {
  @Input() task!: CfTask;
  reminderForm!: FormGroup;
  priorities!: ReminderPriorityValue[];
  readonly session$ = this._sessionStore.session$;
  readonly fullDate$ = this._sessionStore.fullDate$;

  constructor(
    private _modalRef: NzModalRef,
    private _formBuilder: FormBuilder,
    private _sessionStore: SessionStore,
    private _notificationFacade: NotificationFacade
  ) { }

  ngOnInit(): void {
    this._initForm();
    this._initData();
    this._updateForm();
    this._setSessionId();
  }

  closeModal(hasData: boolean) {
    this._modalRef.close(hasData);
  }

  cancel() {
    this.reminderForm.reset();
    this.closeModal(false);
  }

  submit() {
    if (this.reminderForm.invalid) {
      return;
    }

    const formValue = this.reminderForm.getRawValue();
    formValue.dueDate = formValue.dueDate instanceof Date ? formValue.dueDate.getTime() : formValue.dueDate;
    if (!formValue.id) {
      this._notificationFacade.createReminder(formValue, 'NOTIFICATION');
    } else {
      this._notificationFacade.updateReminder(formValue, 'NOTIFICATION')
    }
    this.closeModal(true);
  }

  private _setSessionId() {
    this.session$.pipe(untilDestroyed(this)).subscribe(session => {
      this.reminderForm.controls['client'].setValue(session?.id);
    });
  }

  private _initForm() {
    this.reminderForm = this._formBuilder.group({
      id: [null],
      title: [null, [Validators.required]],
      message: [null],
      dueDate: [new Date(), [Validators.required]],
      priority: ['NONE'],
      enabled: [false],
      client: [null]
    });
  }

  private _initData() {
    this.priorities = [
      new ReminderPriorityValue(CfReminderPriority.NONE),
      new ReminderPriorityValue(CfReminderPriority.LOW),
      new ReminderPriorityValue(CfReminderPriority.MEDIUM),
      new ReminderPriorityValue(CfReminderPriority.HIGH)
    ];
  }

  private _updateForm() {
    // this.reminderForm.patchValue(this.task.reminder);
  }

}
