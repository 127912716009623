import {WeekdaysValues} from "./const";
import {isToday} from "date-fns";

export enum WeekDayEnum {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export const WeekDayValues = {
  [WeekDayEnum.SUNDAY]: 'calendar.week_days.sunday',
  [WeekDayEnum.MONDAY]: 'calendar.week_days.monday',
  [WeekDayEnum.TUESDAY]: 'calendar.week_days.tuesday',
  [WeekDayEnum.WEDNESDAY]: 'calendar.week_days.wednesday',
  [WeekDayEnum.THURSDAY]: 'calendar.week_days.thursday',
  [WeekDayEnum.FRIDAY]: 'calendar.week_days.friday',
  [WeekDayEnum.SATURDAY]: 'calendar.week_days.saturday',
};

export function IsToday(leftDate: Date): boolean {
  return isToday(leftDate);
}

export interface WeekGrid {
  day: number;
  isToday: boolean;
  isSelected: boolean;
  date: Date;
  value: string;
}

export class WeekGridValue {
  day: number;
  date: Date;
  isSelected: boolean;
  isToday: boolean;
  value: string;
  constructor(date: Date) {
    const weekDay = date.getDay() == 0 ? 6 : date.getDay()-1;
    this.day = date.getDate();
    this.isToday = IsToday(date);
    this.date = new Date(date);
    this.isSelected = IsToday(date);
    this.value = WeekdaysValues[weekDay];
  }
}
