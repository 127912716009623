<nz-select
  #nzProject
  class="w-full"
  nzPlaceHolder="{{ 'add_task.task_project_placeholder' | translate }}"
  [formControl]="control"
  (ngModelChange)="onChangeProject(nzProject)"
  [nzNotFoundContent]="noProjectsFoundTmpl"
  [nzCustomTemplate]="selectedProjectTmpl">
  <nz-option
    nzCustomContent
    *ngFor="let project of projects"
    [nzValue]="project">
    <div class="flex items-center">
      <div class="w-2 h-2 mr-3 rounded-lg"
           [style.background-color]="project.color">
      </div>
      <div class="truncate">{{ project.name }}</div>
    </div>
  </nz-option>
</nz-select>

<ng-template
  #selectedProjectTmpl
  let-selected>
  <div class="flex items-center">
    <div class="w-2 h-2 mr-3 rounded-lg"
         [style.background-color]="selected.nzValue.color">
    </div>
    <div class="truncate">{{ selected.nzValue.name }}</div>
  </div>
</ng-template>

<ng-template #noProjectsFoundTmpl>
  {{ 'projects.form.not_found' | translate }}
</ng-template>
