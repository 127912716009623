import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CfUser} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-task-assignees-select',
  templateUrl: './task-assignees-select.component.html',
  styleUrls: ['./task-assignees-select.component.scss'],
})
export class TaskAssigneesSelectComponent {
  @Input() control!: FormControl;
  @Input() users!: CfUser[] | null;

  getUser(userId: string): CfUser {
    return this.users?.find((user) => user.id === userId) as CfUser;
  }

}
