import {Component, Input} from '@angular/core';
import {CfTask, Priority, PriorityValues} from "@app-web-central/web/shared/data-access/models";
import {FormControl} from "@angular/forms";
import {TaskUtil} from "@app-web-central/web/shared/utils";

@Component({
  selector: 'as-task-drawer-priority',
  templateUrl: './task-drawer-priority.component.html',
  styleUrls: ['./task-drawer-priority.component.scss'],
})
export class TaskDrawerPriorityComponent {
  @Input() task!: CfTask;
  @Input() control!: FormControl;
  priorities = PriorityValues;

  priorityIcon() {
    return TaskUtil.getTaskPriorityIcon(this.control.value);
  }

  removePriority() {
    this.control.setValue(null);
  }

  onChangePriority(priority: Priority) {
    this.control.setValue(priority);
  }
}
