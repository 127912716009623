import {Component, Input} from '@angular/core';
import {CfRecipient, CfTask} from "@app-web-central/web/shared/data-access/models";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'as-task-drawer-customer',
  templateUrl: './task-drawer-customer.component.html',
  styleUrls: ['./task-drawer-customer.component.scss'],
})
export class TaskDrawerCustomerComponent {
  @Input() task!: CfTask;
  @Input() control!: FormControl;
  @Input() recipients!: CfRecipient[] | null;

  onSelectRecipient(customer: CfRecipient | null) {
    this.control.setValue(customer);
  }

}
