import {CfTask, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {
  assigneesTask,
  assigneesTaskError,
  assigneesTaskSuccess,
  createTask,
  createTaskError,
  createTaskSuccess,
  disableBulkTask,
  disableBulkTaskError,
  disableBulkTaskSuccess,
  disableTask,
  disableTaskError,
  disableTaskSuccess,
  enableBulkTask,
  enableBulkTaskError,
  enableBulkTaskSuccess,
  enableTask,
  enableTaskError,
  enableTaskSuccess,
  loadTasks,
  loadTasksError,
  loadTasksSuccess,
  removeBulkTask,
  removeBulkTaskError,
  removeBulkTaskSuccess,
  removeTask,
  removeTaskError,
  removeTaskSuccess,
  setLoadingTasksState,
  unassignedTask,
  unassignedTaskError,
  unassignedTaskSuccess,
  updateTask,
  updateTaskError,
  updateTaskStatus,
  updateTaskStatusError,
  updateTaskStatusSuccess,
  updateTaskSuccess
} from "./tasks.actions";

export interface TasksState extends GenericState<CfTask[]> { }

const initialState: TasksState = {
  data: null,
  error: null,
  status: 'pending'
}

export const tasksFeatureKey = 'tasks';

export const tasksReducer = createReducer(
  initialState,
  on(loadTasks, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadTasksSuccess, (state, { tasks }) => ({
    ...state,
    data: tasks,
    status: 'success',
    error: null
  })),
  on(loadTasksError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(setLoadingTasksState, (state, { status }) => ({ ...state, status })),
  on(updateTask, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(updateTaskSuccess, (state, { tasks }) => ({
    ...state,
    data: tasks,
    status: 'success',
    error: null
  })),
  on(updateTaskError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(updateTaskStatus, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(updateTaskStatusSuccess, (state, { tasks }) => ({
    ...state,
    data: tasks,
    status: 'success',
    error: null
  })),
  on(updateTaskStatusError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(createTask, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(createTaskSuccess, (state, { tasks }) => ({
    ...state,
    data: tasks,
    status: 'success',
    error: null
  })),
  on(createTaskError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(removeTask, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(removeTaskSuccess, (state, { tasks }) => ({
    ...state,
    data: tasks,
    status: 'success',
    error: null
  })),
  on(removeTaskError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(removeBulkTask, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(removeBulkTaskSuccess, (state, { tasks }) => ({
    ...state,
    data: tasks,
    status: 'success',
    error: null
  })),
  on(removeBulkTaskError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(enableTask, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(enableTaskSuccess, (state, { tasks }) => ({
    ...state,
    data: tasks,
    status: 'success',
    error: null
  })),
  on(enableTaskError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(enableBulkTask, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(enableBulkTaskSuccess, (state, { tasks }) => ({
    ...state,
    data: tasks,
    status: 'success',
    error: null
  })),
  on(enableBulkTaskError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(disableTask, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(disableTaskSuccess, (state, { tasks }) => ({
    ...state,
    data: tasks,
    status: 'success',
    error: null
  })),
  on(disableTaskError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(disableBulkTask, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(disableBulkTaskSuccess, (state, { tasks }) => ({
    ...state,
    data: tasks,
    status: 'success',
    error: null
  })),
  on(disableBulkTaskError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(assigneesTask, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(assigneesTaskSuccess, (state, { tasks }) => ({
    ...state,
    data: tasks,
    status: 'success',
    error: null
  })),
  on(assigneesTaskError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(unassignedTask, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(unassignedTaskSuccess, (state, { tasks }) => ({
    ...state,
    data: tasks,
    status: 'success',
    error: null
  })),
  on(unassignedTaskError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
