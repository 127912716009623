import {createFeatureSelector, createSelector} from "@ngrx/store";
import {tasksFeatureKey, TasksState} from "./tasks.reducer";
import {SelectorUtil} from "@app-web-central/web/shared/utils";
import {TaskStatus} from "@app-web-central/web/shared/data-access/models";

export const getTasksState = createFeatureSelector<TasksState>(tasksFeatureKey);

export const getTasks = createSelector(getTasksState, (state) => state.data);
export const getTasksByStatusUnassigned = createSelector(getTasksState, (state) =>
  state.data?.filter((x) => x.status === TaskStatus.UNASSIGNED)
);
export const getTasksLoading = createSelector(getTasksState, SelectorUtil.isLoading);
