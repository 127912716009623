<div class="w-full h-screen flex flex-col">
  <as-nav-header></as-nav-header>
  <div id="content">
    <div class="max-w-7xl mx-auto pb-10 lg:py-12 lg:px-8">
      <div class="lg:grid lg:grid-cols-4 gap-4">
        <div class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 relative">
          <as-nav-bar-vertical></as-nav-bar-vertical>
        </div>
        <div class="space-y-6 p-6 lg:pt-0 sm:px-6 lg:px-0 lg:col-span-3">
          <as-main-view></as-main-view>
        </div>
      </div>
    </div>
  </div>
</div>
