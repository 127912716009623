import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CfUser} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-task-delivery-date',
  templateUrl: './task-delivery-date.component.html',
  styleUrls: ['./task-delivery-date.component.scss'],
})
export class TaskDeliveryDateComponent {
  @Input() control!: FormControl;
  @Input() session!: CfUser;
}
