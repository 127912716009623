import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonComponent} from './button.component';
import {IconModule} from "@app-web-central/web/shared/ui/icon";
import {NzBadgeModule} from "ng-zorro-antd/badge";
import {HasPermissionModule} from "@app-web-central/web/shared/directives/has-permission";

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    NzBadgeModule,
    HasPermissionModule
  ],
  declarations: [
    ButtonComponent
  ],
  exports: [
    ButtonComponent
  ],
})
export class ButtonModule {
}
