import {CfUserConfiguration, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {loadUsersConfig, loadUsersConfigError, loadUsersConfigSuccess} from "./users-config.actions";

export interface UsersConfigState extends GenericState<CfUserConfiguration[]> { }

const initialState: UsersConfigState = {
  data: null,
  error: null,
  status: 'pending'
}

export const usersConfigFeatureKey = 'users-config';

export const usersConfigReducer = createReducer(
  initialState,
  on(loadUsersConfig, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadUsersConfigSuccess, (state, { usersConfigurations }) => ({
    ...state,
    data: usersConfigurations,
    status: 'success',
    error: null
  })),
  on(loadUsersConfigError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
