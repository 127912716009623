import {Component, Input} from '@angular/core';
import {InvoiceDisplayColor, InvoiceEnum, TaskStatus} from "@app-web-central/web/shared/data-access/models";
import {TaskUtil} from "@app-web-central/web/shared/utils";

@Component({
  selector: 'as-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input() data!: TaskStatus;
  @Input() count!: number | null;
  @Input() text!: string | null;
  @Input() invoiceState!: InvoiceEnum;

  get status() {
    return TaskUtil.getTaskStatusWithBadge(this.data);
  }

  get invoiceColor() {
    return InvoiceDisplayColor[this.invoiceState];
  }

}
