import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {NotificationsState} from "./notification.reducer";
import {
  createReminder,
  loadMore10Notifications,
  loadNotifications,
  readOneNotification,
  updateReminder
} from "./notification.actions";
import {CfReminder, CfSession} from "@app-web-central/web/shared/data-access/models";
import {CookieService} from "ngx-cookie-service";

const OcfCookie = 'ocf-session';

@Injectable()
export class NotificationFacade {
  constructor(
    private _store: Store<NotificationsState>,
    private _cookieService: CookieService
  ) { }

  /**
   * Initialize on app startup the last 10 notifications.
   *
   */
  public init() {
    const count = 0;
    const userId = this.getCurrentSession().id;
    this._store.dispatch(loadNotifications({ userId, count }));
  }

  /**
   * Load more 10 notifications and add to previous state.
   *
   * @param userId the user id as { string }.
   * @param count the count as { number }.
   */
  public loadMore10Notifications(userId: string, count: number) {
    this._store.dispatch(loadMore10Notifications({ userId, count }));
  }

  /**
   * Read one notification by id.
   *
   * @param notificationId as { string }.
   */
  public readOneNotification(notificationId: string) {
    this._store.dispatch(readOneNotification({ notificationId }));
  }

  /**
   * Create a reminder object.
   *
   * @param reminder as { CfReminder }.
   * @param reminderType as { string }.
   */
  public createReminder(reminder: CfReminder, reminderType: string) {
    this._store.dispatch(createReminder({ reminder, reminderType }));
  }

  /**
   * Update a reminder object.
   *
   * @param reminder as { CfReminder }.
   * @param reminderType as { string }.
   */
  public updateReminder(reminder: CfReminder, reminderType: string) {
    this._store.dispatch(updateReminder({ reminderId: reminder.id, reminder, reminderType }));
  }

  /**
   * Get item from cookie storage by key.
   *
   * @return {CfSession}.
   */
  private getCurrentSession(): CfSession {
    const value = this._cookieService.get(OcfCookie);
    try {
      return JSON.parse(value);
    } catch (e) {
      return {} as CfSession;
    }
  }

}
