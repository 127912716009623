import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavBarLogoAreaComponent} from './nav-bar-logo-area.component';
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    NavBarLogoAreaComponent
  ],
  exports: [
    NavBarLogoAreaComponent
  ],
})
export class NavBarLogoAreaModule {
}
