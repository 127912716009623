import {HeaderApi} from "./header-api";
import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfOrganization, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class OrganizationApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) {}

  list() {
    return this._http.get<ResponseState<CfOrganization[]>>(
      `${this._appConfig.organizationV1Url}manage/organizations`,{
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

  update(organizationId: string, organization: CfOrganization) {
    return this._http.put<ResponseState<CfOrganization>>(
      `${this._appConfig.organizationV1Url}manage/organizations/${organizationId}`, organization, {
        headers: this._header.getServiceHeader(),
        withCredentials: true
      }
    );
  }

}
