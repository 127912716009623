import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {LicensesState} from "./licenses.reducer";
import {loadLicenses} from "./licenses.actions";
import {getLicenses} from "./licenses.selectors";

@Injectable({ providedIn: 'root' })
export class LicensesFacade {
  readonly licenses$ = this._store.pipe(select(getLicenses));

  constructor(
    private _store: Store<LicensesState>
  ) { }

  init() {
    this._store.dispatch(loadLicenses());
  }

}
