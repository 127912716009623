import {createReducer, on} from "@ngrx/store";
import {CfChannel, GenericState} from "@app-web-central/web/shared/data-access/models";
import {
  loadChannels,
  loadChannelsError,
  loadChannelsSuccess,
  startWatchingChannel,
  startWatchingChannelError,
  startWatchingChannelSuccess,
  stopWatchingChannel,
  stopWatchingChannelError,
  stopWatchingChannelSuccess
} from "./channel.actions";

export const channelsFeatureKey = 'channels';

export interface ChannelsState extends GenericState<CfChannel[]> { }

const initialState: ChannelsState = {
  data: [],
  status: 'pending',
  error: null
};

export const channelsReducer = createReducer(
  initialState,
  on(loadChannels, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadChannelsSuccess, (state, { channels }) => ({
    ...state,
    channels,
    status: 'success',
    error: null
  })),
  on(loadChannelsError, (state, { error }) => ({
    ...state,
    status: 'error',
    error
  })),
  on(startWatchingChannel, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(startWatchingChannelSuccess, (state, { channel }) => ({
    ...state,
    channel,
    status: 'success',
    error: null
  })),
  on(startWatchingChannelError, (state, { error }) => ({
    ...state,
    status: 'error',
    error
  })),
  on(stopWatchingChannel, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(stopWatchingChannelSuccess, (state, { channel }) => ({
    ...state,
    channel,
    status: 'success',
    error: null
  })),
  on(stopWatchingChannelError, (state, { error }) => ({
    ...state,
    status: 'error',
    error
  }))
)
