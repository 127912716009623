import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {CfTask} from "@app-web-central/web/shared/data-access/models";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'as-task-drawer-tags',
  templateUrl: './task-drawer-tags.component.html',
  styleUrls: ['./task-drawer-tags.component.scss'],
})
export class TaskDrawerTagsComponent implements OnInit {
  @Input() task!: CfTask;
  @Input() control!: FormControl;
  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;
  tags!: string[];
  inputVisible = false;

  ngOnInit(): void {
    this.tags = this.task.tags;
  }

  handleClose(removedTag: any): void {
    this.tags = this.tags.filter(tag => tag !== removedTag);
    this.control.setValue(this.tags);
  }

  sliceTagName(tag: string): string {
    const isLongTag = tag.length > 20;
    return isLongTag ? `${tag.slice(0, 20)}...` : tag;
  }

  showInput(): void {
    this.inputVisible = true;
    this.control.setValue([]);
    setTimeout(() => {
      this.inputElement?.nativeElement.focus();
    }, 10);
  }

  handleInputConfirm(): void {
    if (this.control.invalid
      || this.control.value === ''
      || this.control.value === null
      || !this.control.value.length
    ) {
      this.inputVisible = false;
      return;
    }
    if (this.control.value && this.tags.indexOf(this.control.value) === -1) {
      this.tags = [...this.tags, this.control.value];
    }
    this.inputVisible = false;
    this.control.setValue(this.tags);
  }

}
