import {createFeatureSelector, createSelector} from "@ngrx/store";
import {SETTINGS_FEATURE_KEY, SettingsState} from "./settings.reducer";

/**
 * @Selector for all settings:
 * - Languages
 * - Countries...
 */
export const getSettings = createFeatureSelector<Readonly<SettingsState>>(SETTINGS_FEATURE_KEY);

/**
 * @Selector for countries.
 */
export const getCountries = createSelector(getSettings, (state) => state.countries || []);

/**
 * @Selector for languages.
 */
export const getLanguages = createSelector(getSettings, (state) => state.languages || []);
