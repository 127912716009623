import {Component, OnInit} from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

enum EditChoice {
  ALL = 'ALL',
  AFTER = 'AFTER',
  ONE = 'ONE'
}

const EditChoiceValue = {
  [EditChoice.ALL]: 'all',
  [EditChoice.AFTER]: 'after',
  [EditChoice.ONE]: 'one'
}

export interface ChoiceState {
  all: boolean;
  after: boolean;
  one: boolean;
}

@Component({
  selector: 'as-modal-recurrence-edit',
  templateUrl: './modal-recurrence-edit.component.html',
  styleUrls: ['./modal-recurrence-edit.component.scss'],
})
export class ModalRecurrenceEditComponent implements OnInit {
  recurringEditForm!: FormGroup;
  endChoiceControl!: FormControl;

  constructor(
    private _modalRef: NzModalRef,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this._initForm();
    this._initEndsSelection();
  }

  closeModal() {
    this._modalRef.close();
  }

  cancel() {
    this.recurringEditForm.reset();
    this.closeModal();
  }

  onChangeRadioEnds(event: string) {
    const choice = event.toUpperCase();
    if (choice === EditChoice.ALL) {
      this._setFormField('all',true);
      this._setFormField('after',false);
      this._setFormField('one',false);
    } else if (choice === EditChoice.AFTER) {
      this._setFormField('all',false);
      this._setFormField('after',true);
      this._setFormField('one',false);
    } else {
      this._setFormField('all',false);
      this._setFormField('after',false);
      this._setFormField('one',true);
    }
  }

  submit() {
    if (this.recurringEditForm.invalid) {
      return;
    }
    this._modalRef.close(this.recurringEditForm.getRawValue());
  }

  private _initForm() {
    this.recurringEditForm = this._formBuilder.group({
      all: [true],
      after: [false],
      one: [false],
    });
  }

  private _initEndsSelection() {
    this.endChoiceControl = new FormControl(EditChoiceValue[EditChoice.ALL]);
  }

  private _setFormField(field: string, value: any) {
    this.recurringEditForm.controls[field].setValue(value);
  }

}
