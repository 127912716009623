<div class="join-folder h-screen">
  <div class="w-full join-folder-bg">
    <div class="container mx-auto relative z-10 lg:px-40 pt-12">
      <div class="flex flex-col">
        <div>
          <img class="h-12 w-auto mx-auto" src="./assets/images/logo_cfcube_4224x1024_white.png" alt="perfo-img">
        </div>

        <div>
          <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-20">
            <div class="sm:text-center lg:text-left">
              <h1 class="text-4xl font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
                <span class="block text-white xl:inline">{{ 'files.join.title' | translate }}</span>
                <span class="block xl:inline">{{ 'files.join.title_2' | translate }}</span>
              </h1>
              <p class="mt-3 text-base text-white sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0">
                {{ 'files.join.subtitle' | translate }}<strong class="text-purple-900">{{ owner }}</strong>{{ 'projects.join.subtitle_2' | translate }}<strong>{{ organization }}</strong>.
              </p>
              <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div class="rounded-md shadow">
                  <a
                    (click)="acceptInvitation()"
                    href="javascript:void(0)"
                    class="join-folder-btn">
                    {{ 'files.join.button' | translate }}
                    <span *ngIf="!loading" class="ml-3" aria-hidden="true"> &rarr;</span>
                    <as-spinner
                      class="ml-3"
                      *ngIf="loading"
                      [fullScreen]="false"
                      [iconSize]="'text-2xl'"
                      [color]="'text-white'">
                    </as-spinner>
                  </a>
                </div>
              </div>
              <p class="mt-3 text-sm text-white sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0">
                {{ 'projects.join.agreement' | translate }}
              </p>
            </div>
          </main>
        </div>

      </div>
    </div>
  </div>

  <div class="container mx-auto sub-card">
    <div class="w-1/2 mx-auto mt-12">
      <div class="py-12 sm:grid sm:grid-cols-3 sm:gap-4">
        <div class="text-sm font-medium text-gray-500">
          <img src="./assets/images/home-1.svg" alt="performance-image" class="h-40 w-auto">
        </div>
        <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div class="flex flex-row items-center">
            <svg-icon key="outline/light-bulb" class="mr-2"></svg-icon>
            <h1 class="text-xl font-extrabold text-gray-900 sm:text-xl md:text-xl">
              <span class="block xl:inline">{{ 'projects.join.panel_title' | translate }}</span>
            </h1>
          </div>
          <p class="mt-3 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0">
            {{ 'projects.join.panel_content' | translate }}
            <a href="https://www.cloudfleeter.com/home" target="_blank" class="text-blue-500">
              {{ 'buttons.learn_more' | translate }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>

</div>
