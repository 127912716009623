import {createFeatureSelector, createSelector} from "@ngrx/store";
import {SelectorUtil} from "@app-web-central/web/shared/utils";
import {projectsFeatureKey, ProjectsState} from "./projects.reducer";

export const getProjectsState = createFeatureSelector<ProjectsState>(projectsFeatureKey);

export const getProjects = createSelector(getProjectsState, (state) => state.data);

export const getProjectsNonArchived = createSelector(getProjectsState, (state) =>
  state.data?.filter((x) => !x.archived)
);

export const getProjectsArchived = createSelector(getProjectsState, (state) =>
  state.data?.filter((x) => x.archived)
);


export const getProjectsLoading = createSelector(getProjectsState, SelectorUtil.isLoading);
