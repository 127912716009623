<div class="recurrence-modal">
  <div class="flex items-center pb-3 text-textDarkest">
    <div class="text-xl">
      {{ 'recurring.edit.title' | translate }}
    </div>
    <div class="flex-auto"></div>
    <as-button
      [icon]="'times'"
      [iconSize]="'md'"
      (click)="closeModal()"
      [className]="'btn-empty'" >
    </as-button>
  </div>

  <form
    class="w-full max-w-lg"
    [formGroup]="recurringEditForm"
    (ngSubmit)="submit()">
    <nz-radio-group
      [formControl]="endChoiceControl"
      (ngModelChange)="onChangeRadioEnds($event)">

      <label nz-radio nzValue="all">
        {{ 'recurring.edit.all' | translate }}
      </label>

      <label nz-radio nzValue="after" class="mt-1">
        {{ 'recurring.edit.after' | translate }}
      </label>

      <label nz-radio nzValue="one" class="mt-1">
        {{ 'recurring.edit.one' | translate }}
      </label>
    </nz-radio-group>

    <div class="mt-5 form-group form-action">
      <as-button className="btn-primary mr-2"
                 type="submit"
                 [disabled]="recurringEditForm.invalid">
        {{ 'buttons.save' | translate }}
      </as-button>
      <as-button className="btn-empty"
                 (click)="cancel()">
        {{ 'buttons.cancel' | translate }}
      </as-button>
    </div>
  </form>

</div>

