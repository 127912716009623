import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-web-central-root',
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private _translate: TranslateService
  ) {
    const lang = this._translate.getBrowserLang() as string;
    this._translate.use(lang);
  }
}
