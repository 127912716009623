import {createAction, props} from "@ngrx/store";
import {CfRole} from "@app-web-central/web/shared/data-access/models";

export const loadRoles = createAction(
  '[Role List] Load Roles'
);

export const loadRolesSuccess = createAction(
  '[Role List] Load Roles Success',
  props<{
    roles: CfRole[]
  }>()
);

export const loadRolesError = createAction(
  '[Role List] Load Roles Error',
  props<{
    error: string
  }>()
);
