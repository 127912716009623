import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {NavItemMixin, TaskStatus} from "@app-web-central/web/shared/data-access/models";
import {FilterStore} from "@app-web-central/web/shared/data-access/store";
import {DateUtil} from "@app-web-central/web/shared/utils";
import {TranslateService} from "@ngx-translate/core";

export interface SubListItem {
  items: any[] | null;
  handler: Handler;
}

type Handler = (params: any | null) => void;

const sortByValues = ['asc', 'desc', 'asc_upd', 'desc_upd'];

@Component({
  selector: 'as-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @Input() item!: NavItemMixin;
  @Input() sublist!: SubListItem;
  @Input() className = 'tertiary';
  currentSelection!: string | null;
  rangeDate = null;
  visible = false;

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _filterStore: FilterStore,
    private _translate: TranslateService
  ) { }

  onSelect(sublink: any) {
    this.visible = false;
    if (typeof sublink === 'string') {
      this.currentSelection = sublink;
    } else {
      if (Object.values(TaskStatus).includes(sublink.value)) {
        this.currentSelection = `task_detail.status.${sublink.value.toLowerCase()}`;
      } else if (sortByValues.includes(sublink.value)) {
        this.currentSelection = `task_detail.sort_by.sort.${sublink.value.toLowerCase()}`;
      } else {
        this.currentSelection = null;
      }
    }
    this._cdRef.detectChanges();
  }

  onChange(result: Date[]) {
    const dates = [
      DateUtil.getStartOfDay(result[0]).getTime(),
      DateUtil.getEndOfDay(result[1]).getTime()
    ];
    this.currentSelection = `${this._translate.instant('constants.from')} ${result[0].getDate()}/${result[0].getMonth()+1}/${result[0].getFullYear()}
      ${this._translate.instant('constants.to')} ${result[1].getDate()}/${result[1].getMonth()+1}/${result[1].getFullYear()}`;
    this._filterStore.toggleSortEventsForDates(dates);
    this.visible = false;
  }

  onOpenDatePicker() {
    this.visible = !this.visible;
  }

}
