import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CfUIFolder, CfUser} from "@app-web-central/web/shared/data-access/models";
import {NzModalRef} from "ng-zorro-antd/modal";
import {FolderStore} from "@app-web-central/web/folder/data-access";

@Component({
  selector: 'as-modal-folder-add',
  templateUrl: './modal-folder-add.component.html',
  styleUrls: ['./modal-folder-add.component.scss'],
})
export class ModalFolderAddComponent implements OnInit {
  @Input() folder!: CfUIFolder | null;
  @Input() parentId!: string;
  folderForm!: FormGroup;
  submitted = false;
  session!: CfUser;

  constructor(
    private _modal: NzModalRef,
    private _store: FolderStore,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this._initForm();
    this._updateForm();
  }

  handleCancel() {
    this._modal.destroy();
  }

  handleOk() {
    this.submitted = true;
    if (this.folderForm.invalid) {
      return;
    }
    const formValue = this.folderForm.getRawValue();
    if (!formValue.id) {
      this._store.createFolder(formValue);
    } else {
      this._store.updateFolder(formValue);
    }
    this._modal.destroy();
  }

  _initForm() {
    this.folderForm = this._formBuilder.group({
      id: [null],
      parentId: [this.parentId],
      name: [null, [Validators.required, Validators.maxLength(255)]]
    });
  }

  _updateForm() {
    if (this.folder) {
      this.folderForm.controls['name'].setValue(this.folder.name.value);
      this.folderForm.controls['id'].setValue(this.folder.name.id);
    }
  }

}
