<li class="item" *ngFor="let user of users | slice: 0:endSlice">
  <div class="avatar-container">
    <div class="flex-shrink-0">
      <as-avatar
        *ngIf="user.fullName"
        [avatarUrl]="user.avatarUrl"
        [text]="user.fullName"
        [className]="'avatar-bg-rounded'"
        [size]="38">
      </as-avatar>
    </div>
    <div class="min-w-0 flex-1">
      <p class="fullName">{{ user.fullName }}</p>
      <p class="email">{{ user.email }}</p>
    </div>
  </div>
  <div class="flex-shrink-0">
    <button
      type="button"
      class="invite-button"
      (click)="invite(user)">
      {{ 'buttons.send_invite' | translate }}
    </button>
  </div>
</li>
