<span *ngIf="data" [ngClass]="['badge', status.className]">
  {{ status.value | translate }}
</span>

<span *ngIf="count" [ngClass]="['badge', 'badge-primary-rounded']">
  {{ count }}
</span>

<span *ngIf="text" [ngClass]="['badge', 'badge-primary-rounded']">
  {{ text | translate }}
</span>

<span *ngIf="invoiceState" [ngClass]="['badge', invoiceColor]">
  <ng-content></ng-content>
</span>
