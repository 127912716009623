import {Component, Input} from '@angular/core';

@Component({
  selector: 'as-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() fullScreen!: boolean;
  @Input() iconSize = 'text-4xl';
  @Input() color = 'text-slate-800';
}
