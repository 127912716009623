import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputComponent} from './input.component';
import {ReactiveFormsModule} from "@angular/forms";
import {IconModule} from "@app-web-central/web/shared/ui/icon";

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    ReactiveFormsModule
  ],
  declarations: [
    InputComponent
  ],
  exports: [
    InputComponent
  ],
})
export class InputModule {
}
