import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HeaderApi} from "@app-web-central/web/shared/data-access/cf-api";
import {HttpClient} from "@angular/common/http";
import {CfRole, CfScope, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class RoleApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _header: HeaderApi,
    private _http: HttpClient
  ) { }

  get(roleId: string) {
    return this._http.get<ResponseState<CfRole>>(
      `${this._appConfig.usersV1Url}manage/roles/${roleId}`,{
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  list() {
    return this._http.get<ResponseState<CfRole[]>>(
      `${this._appConfig.usersV1Url}manage/roles`,{
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  listScopes() {
    return this._http.get<ResponseState<CfScope[]>>(
      `${this._appConfig.usersV1Url}manage/roles/scopes`,{
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  create(role: CfRole) {
    return this._http.post<ResponseState<CfRole>>(
      `${this._appConfig.usersV1Url}manage/roles`, role,{
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  update(roleId: string, role: CfRole) {
    return this._http.put<ResponseState<CfRole>>(
      `${this._appConfig.usersV1Url}manage/roles/${roleId}`, role,{
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  delete(roleId: string) {
    return this._http.delete<ResponseState<CfRole>>(
      `${this._appConfig.usersV1Url}manage/roles/${roleId}`,{
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  deleteBulk(roleIds: string[]) {
    return this._http.delete<ResponseState<string>>(
      `${this._appConfig.usersV1Url}manage/roles/bulk/${roleIds}`,{
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  enable(roleId: string) {
    return this._http.get<ResponseState<CfRole>>(
      `${this._appConfig.usersV1Url}manage/roles/${roleId}/enable`,{
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  enableBulk(roleIds: string[]) {
    return this._http.post<ResponseState<CfRole[]>>(
      `${this._appConfig.usersV1Url}manage/roles/bulk/enable`, roleIds, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  disable(roleId: string) {
    return this._http.get<ResponseState<CfRole>>(
      `${this._appConfig.usersV1Url}manage/roles/${roleId}/disable`,{
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }

  disableBulk(roleIds: string[]) {
    return this._http.post<ResponseState<CfRole[]>>(
      `${this._appConfig.usersV1Url}manage/roles/bulk/disable`, roleIds, {
        headers: this._header.getCommonHeader(),
        withCredentials: true
      }
    );
  }
}
