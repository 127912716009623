import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {CfHub, GenericState} from "@app-web-central/web/shared/data-access/models";
import {filter, map, Observable, switchMap, tap, withLatestFrom} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {HubUtil, SelectorUtil} from "@app-web-central/web/shared/utils";
import {getHubs, HubsState, loadHubsSuccess} from "../hubs";
import {HubApi} from "@app-web-central/web/shared/data-access/cf-api";

interface HubState extends GenericState<CfHub> {
  hubId: string;
}

@Injectable({ providedIn: 'root' })
export class HubStore extends ComponentStore<HubState> {
  hubs$ = this._store.pipe(select(getHubs));
  isHubLoading$ = this.select(SelectorUtil.isLoading);

  hubIdParams$: Observable<string> = this._route.params.pipe(
    map((params) => params["hubId"]),
    filter((userId: string) => !!userId)
  );

  hub$ = this.hubIdParams$.pipe(
    tap((hubId) => {
      this.patchState({
        hubId
      });
      this.loadhub({ hubId });
    }),
    switchMap(() => this.select((s) => s.data))
  );

  loadhub = this.effect<{ hubId: string }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({ hubId }) =>
        this._hubApi.get(hubId).pipe(
          tapResponse(
            (response) => {
              const hub = { ...response.payload };
              this.patchState({
                data: hub,
                status: 'success',
                error: ''
              });
            },
            (error) => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }
          )
        )
      )
    )
  );

  create = this.effect<CfHub>((params$) => (
      params$.pipe(
        withLatestFrom(this.hubs$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([hub, hubs]) =>
          this._hubApi.create(hub)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'create');
                  const newHubs = hubs !== null ? [...hubs] : [];
                  newHubs.push({...response.payload});
                  this._store.dispatch(
                    loadHubsSuccess({
                      hubs: newHubs
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'create');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  update = this.effect<CfHub>((params$) => (
      params$.pipe(
        withLatestFrom(this.hubs$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([hub, hubs]) =>
          this._hubApi.update(hub.id, hub)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'save');
                  const newHubs = hubs !== null ? [...hubs] : [];
                  const index = newHubs.findIndex((item) => item.id === response.payload.id);
                  newHubs[index] = {...response.payload};
                  this._store.dispatch(
                    loadHubsSuccess({
                      hubs: newHubs
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'save');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  delete = this.effect<{ hub: CfHub }>((params$) => (
      params$.pipe(
        withLatestFrom(this.hubs$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ hub }, hubs]) =>
          this._hubApi.delete(hub.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'delete');
                  const newHubs = HubUtil.removeHubFromHubs(hubs, hub);
                  this._store.dispatch(
                    loadHubsSuccess({
                      hubs: newHubs
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'delete');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  enable = this.effect<{ hub: CfHub }>((params$) => (
      params$.pipe(
        withLatestFrom(this.hubs$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ hub }, hubs]) =>
          this._hubApi.enable(hub.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'enable');
                  const newHubs = HubUtil.replaceHubAndGetNewHubs(hubs, response.payload);
                  this._store.dispatch(
                    loadHubsSuccess({
                      hubs: newHubs
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'enable');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  disable = this.effect<{ hub: CfHub }>((params$) => (
      params$.pipe(
        withLatestFrom(this.hubs$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ hub }, hubs]) =>
          this._hubApi.disable(hub.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'disable');
                  const newHubs = HubUtil.replaceHubAndGetNewHubs(hubs, response.payload);
                  this._store.dispatch(
                    loadHubsSuccess({
                      hubs: newHubs
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'disable');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  private _notify(state: string, action: string) {
    this._notification.create(
      state,
      this._translate.instant(`notifications.${state}.hub.${action}.title`),
      this._translate.instant(`notifications.${state}.hub.${action}.subtitle`)
    );
  }

  constructor(
    private _hubApi: HubApi,
    private _route: ActivatedRoute,
    private _store: Store<HubsState>,
    private _translate: TranslateService,
    private _notification: NzNotificationService
  ) {
    super(<HubState>{});
  }
}
