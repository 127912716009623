import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CfTask, quillConfiguration} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-task-drawer-description',
  templateUrl: './task-drawer-description.component.html',
  styleUrls: ['./task-drawer-description.component.scss'],
})
export class TaskDrawerDescriptionComponent {
  @Input() task!: CfTask;
  @Input() control!: FormControl;
  editorOptions = quillConfiguration;
}
