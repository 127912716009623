import {Component, Input} from '@angular/core';

export type IconSize = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

@Component({
  selector: 'as-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() type = 'button';
  @Input() className = 'primary';
  @Input() iconColor = 'text-gray-400';
  @Input() icon!: string;
  @Input() iconSize!: IconSize;
  @Input() isWorking!: boolean | null;
  @Input() isActive!: boolean | null;
  @Input() disabled!: boolean;
  @Input() hasBadge = false;
  @Input() isDropdown = false;
  @Input() isCombobox = false;
  @Input() countValue!: number;

  get size(): IconSize {
    return this.iconSize;
  }

}
