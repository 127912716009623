import {CfRecipient, GenericState} from "@app-web-central/web/shared/data-access/models";
import {Injectable} from "@angular/core";
import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {select, Store} from "@ngrx/store";
import {RecipientUtil, SelectorUtil} from "@app-web-central/web/shared/utils";
import {filter, map, Observable, switchMap, tap, withLatestFrom} from "rxjs";
import {RecipientApi} from "@app-web-central/web/shared/data-access/cf-api";
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {getRecipients, loadRecipientsSuccess, RecipientsState} from "../recipients";

interface RecipientState extends GenericState<CfRecipient> {
  recipientId: string;
}

@Injectable({ providedIn: 'root' })
export class RecipientStore extends ComponentStore<RecipientState> {
  recipients$ = this._store.pipe(select(getRecipients));
  isRecipientLoading$ = this.select(SelectorUtil.isLoading);

  recipientIdParams$: Observable<string> = this._route.params.pipe(
    map((params) => params["recipientId"]),
    filter((recipientId: string) => !!recipientId)
  );

  recipient$ = this.recipientIdParams$.pipe(
    tap((recipientId) => {
      this.patchState({
        recipientId
      });
      this.loadRecipient({ recipientId });
    }),
    switchMap(() => this.select((s) => s.data))
  );

  loadRecipient = this.effect<{ recipientId: string }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({ recipientId }) =>
        this._recipientApi.get(recipientId).pipe(
          tapResponse(
            (response) => {
              const recipient = { ...response.payload };
              this.patchState({
                data: recipient,
                status: 'success',
                error: ''
              });
            },
            (error) => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }
          )
        )
      )
    )
  );

  create = this.effect<CfRecipient>((params$) => (
      params$.pipe(
        withLatestFrom(this.recipients$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([recipient, recipients]) =>
          this._recipientApi.create(recipient)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'create');
                  const newRecipients = recipients !== null ? [...recipients] : [];
                  newRecipients.push({...response.payload});
                  this._store.dispatch(
                    loadRecipientsSuccess({
                      recipients: newRecipients
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'create');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  update = this.effect<CfRecipient>((params$) => (
      params$.pipe(
        withLatestFrom(this.recipients$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([recipient, recipients]) =>
          this._recipientApi.update(recipient.id, recipient)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'save');
                  const newRecipients = recipients !== null ? [...recipients] : [];
                  const index = newRecipients.findIndex((item) => item.id === response.payload.id);
                  newRecipients[index] = {...response.payload};
                  this._store.dispatch(
                    loadRecipientsSuccess({
                      recipients: newRecipients
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'save');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  delete = this.effect<{ recipient: CfRecipient }>((params$) => (
      params$.pipe(
        withLatestFrom(this.recipients$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ recipient }, recipients]) =>
          this._recipientApi.delete(recipient.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'delete');
                  const newRecipients = RecipientUtil.removeRecipientFromRecipients(recipients, recipient);
                  this._store.dispatch(
                    loadRecipientsSuccess({
                      recipients: newRecipients
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'delete');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  enable = this.effect<{ recipient: CfRecipient }>((params$) => (
      params$.pipe(
        withLatestFrom(this.recipients$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ recipient }, recipients]) =>
          this._recipientApi.enable(recipient.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'enable');
                  const newRecipients = RecipientUtil.replaceRecipientAndGetNewRecipients(recipients, response.payload);
                  this._store.dispatch(
                    loadRecipientsSuccess({
                      recipients: newRecipients
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'enable');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  disable = this.effect<{ recipient: CfRecipient }>((params$) => (
      params$.pipe(
        withLatestFrom(this.recipients$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ recipient }, recipients]) =>
          this._recipientApi.disable(recipient.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'disable');
                  const newRecipients = RecipientUtil.replaceRecipientAndGetNewRecipients(recipients, response.payload);
                  this._store.dispatch(
                    loadRecipientsSuccess({
                      recipients: newRecipients
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'disable');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  private _notify(state: string, action: string) {
    this._notification.create(
      state,
      this._translate.instant(`notifications.${state}.recipient.${action}.title`),
      this._translate.instant(`notifications.${state}.recipient.${action}.subtitle`)
    );
  }

  constructor(
    private _recipientApi: RecipientApi,
    private _route: ActivatedRoute,
    private _store: Store<RecipientsState>,
    private _translate: TranslateService,
    private _notification: NzNotificationService
  ) {
    super(<RecipientState>{});
  }
}
