import {createAction, props} from "@ngrx/store";
import {CfUserConfiguration} from "@app-web-central/web/shared/data-access/models";

export const loadUsersConfig = createAction(
  '[User Config List] Load Users Config'
);

export const loadUsersConfigSuccess = createAction(
  '[User Config List] Load Users Config Success',
  props<{
    usersConfigurations: CfUserConfiguration[]
  }>()
);

export const loadUsersConfigError = createAction(
  '[User Config List] Load Users Config Error',
  props<{
    error: string
  }>()
);
