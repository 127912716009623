import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {CfTask, CfUser, TaskStatus} from "@app-web-central/web/shared/data-access/models";
import {ModalRecurrenceComponent} from "@app-web-central/web/shell/ui/modal-recurrence";
import {NzModalService} from "ng-zorro-antd/modal";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ERROR} from "@app-web-central/web/shared/services/local-notification";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

const END_STATUS = [TaskStatus.IN_TRANSIT, TaskStatus.FAILED, TaskStatus.COMPLETED];

@UntilDestroy()
@Component({
  selector: 'as-task-drawer-dates',
  templateUrl: './task-drawer-dates.component.html',
  styleUrls: ['./task-drawer-dates.component.scss'],
})
export class TaskDrawerDatesComponent implements OnInit {
  @Input() task!: CfTask;
  @Input() session!: CfUser;
  @Input() control!: FormControl;
  @Input() isRruleControl!: FormControl;
  @Input() rruleControl!: FormControl;
  startDate!: FormControl;
  endDate!: FormControl;
  allDay!: FormControl;
  isRecurring!: FormControl;

  constructor(
    private _modal: NzModalService,
    public translate: TranslateService,
    private _notification: NzNotificationService,
  ) { }

  ngOnInit(): void {
    this.startDate = new FormControl(this.control.value.startDate);
    this.endDate = new FormControl(this.control.value.endDate);
    this.allDay = new FormControl(this.control.value.allDay);
    this.isRecurring = new FormControl(this.task.isRecurring);
  }

  handleStartOpenChange(date: Date) {
    this.control.setValue(({
      ...this.control.value,
      deliveryDate: date.getTime(),
      startDate: date.getTime()
    }));
  }

  handleEndOpenChange(date: Date) {
    const startDate = new Date(this.startDate.value);
    if (date.getTime() < startDate.getTime()) {
      this._notification.error(ERROR,
        this.translate.instant('task_detail.messages.dates.error'));
      this.endDate = new FormControl(this.control.value.endDate);
    } else {
      this.control.setValue(({
        ...this.control.value,
        endDate: date.getTime()
      }));
    }
  }

  handleOnAllDay(checked: boolean) {
    this.control.setValue(({
      ...this.control.value,
      allDay: checked
    }));
  }

  handleOnRecurring() {
    if (!this.task.recurrence) {
      this._openRecurrentModal();
    }
  }

  handleOnResetRecurring() {
    if (this.task.recurrence) {
      // TODO
      console.log('check if it is possible and remove tasks already created');
    }
  }

  onEditRecurrence() {
    if (END_STATUS.indexOf(this.task.status) > -1) {
      this.createErrorMessage();
      return;
    }
    this._openRecurrentModal();
  }

  createErrorMessage(): void {
    this._notification.error(ERROR, this.translate.instant('task_detail.messages.rrule.edition'));
  }

  private _openRecurrentModal() {
    const rruleModal = this._modal.create({
      nzContent: ModalRecurrenceComponent,
      nzComponentParams: {task: this.task},
      nzClosable: false,
      nzFooter: null,
      nzWidth: 400
    });

    rruleModal.afterClose
      .pipe(untilDestroyed(this))
      .subscribe((rrule) => {
        if (rrule) {
          this.isRruleControl.setValue(true);
          this.rruleControl.setValue(rrule);
        }
      });
  }

}
