import {CfUser} from "@app-web-central/web/shared/data-access/models";

export class UserUtil {
  static replaceUserAndGetNewUsers(users: CfUser[] | null, user: CfUser) {
    const newUsers = users !== null ? [...users] : [];
    const index = newUsers.findIndex((x) => x.id === user.id);
    if (index === -1) {
      newUsers.push(user);
    } else {
      newUsers[index] = user;
    }
    return newUsers;
  }

  static removeUserFromUsers(users: CfUser[] | null, user: CfUser) {
    const newUsers = users !== null ? [...users] : [];
    const index = newUsers.findIndex((x) => x.id === user.id);
    newUsers.splice(index, 1);
    return newUsers;
  }
}
