import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {
  loadOrganizations,
  OrganizationsState,
  OrganizationStore
} from "@app-web-central/web/organization/data-access";
import {CfOrganization} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class OrganizationsFacade {
  organization$ = this._organizationStore.organization$;

  constructor(
    private _store: Store<OrganizationsState>,
    private _organizationStore: OrganizationStore
  ) { }

  init() {
    this._store.dispatch(loadOrganizations());
  }

  update(organization: CfOrganization) {
    this._organizationStore.update(organization);
  }

}
