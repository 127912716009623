import {createAction, props} from "@ngrx/store";
import {CfDeviceInformation, CfUser} from "@app-web-central/web/shared/data-access/models";

export const loadSession = createAction('[Session Object] Load Session');

export const loadSessionSuccess = createAction(
  '[Session Object] Load Session Success',
  props<{
    session: CfUser
  }>()
);

export const loadSessionError = createAction('[Session Object] Load Session Error');

export const updateSession = createAction(
  '[Session Object] Update Session',
  props<{
    session: CfUser
  }>()
);

export const updateSessionSuccess = createAction(
  '[Session Object] Update Session Success',
  props<{
    session: CfUser | null
  }>()
);

export const updateSessionError = createAction('[Session Object] Update Session Error');

export const activateSession = createAction(
  '[Session Object] Activate Session',
  props<{
    deviceInfo: CfDeviceInformation,
    session: CfUser
  }>()
);

export const activateSessionSuccess = createAction(
  '[Session Object] Activate Session Success',
  props<{
    session: CfUser
  }>()
);

export const activateSessionError = createAction(
  '[Session Object] Activate Session Error',
  props<{
    error: string;
  }>()
);

export const deactivateSession = createAction(
  '[Session Object] Deactivate Session',
  props<{
    deviceInfo: CfDeviceInformation,
    sessionId: string
  }>()
);

export const deactivateSessionSuccess = createAction(
  '[Session Object] Deactivate Session Success',
  props<{
    session: CfUser
  }>()
);

export const deactivateSessionError = createAction(
  '[Session Object] Deactivate Session Error',
  props<{
    error: string;
  }>()
);

export const logoutSession = createAction(
  '[Session Object] Logout Session',
  props<{
    deviceInfo: CfDeviceInformation,
    session: CfUser
  }>()
);

export const logoutSessionSuccess = createAction(
  '[Session Object] Logout Session Success',
  props<{
    session: CfUser
  }>()
);

export const logoutSessionError = createAction(
  '[Session Object] Logout Session Error',
  props<{
    error: string;
  }>()
);
