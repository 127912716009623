import {Component, Input} from '@angular/core';
import {CfIntervention, CfTask, TaskStatus} from "@app-web-central/web/shared/data-access/models";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'as-task-drawer-interventions',
  templateUrl: './task-drawer-interventions.component.html',
  styleUrls: ['./task-drawer-interventions.component.scss'],
})
export class TaskDrawerInterventionsComponent {
  @Input() task!: CfTask;
  @Input() control!: FormControl;
  @Input() interventions!: CfIntervention[] | null;
  visible = false;

  removeIntervention(intervention: CfIntervention) {
    if (this.task.status === TaskStatus.COMPLETED
      || this.task.dates?.validationDate) {
      return;
    }

    if (Array.isArray(this.control.value) && this.control.value.length) {
      this.control.setValue(this.control.value.filter((i) => i.id !== intervention.id));
    }
  }

  onSelectInterventions(intervention: CfIntervention | null) {
    if (!intervention) {
      return;
    }

    const currentInterventions: CfIntervention[] = !this.control.value || !this.control.value.length
      ? []
      : this.control.value;
    if (intervention && !currentInterventions.some((i) => i.id === intervention.id)) {
      currentInterventions.push(intervention);
    } else {
      if (currentInterventions.some((i) => i.id === intervention.id)) {
        currentInterventions.filter((i) => i.id !== intervention.id);
      }
    }
    this.visible = false;
    this.control.setValue(currentInterventions);
  }

}
