import {createAction, props} from "@ngrx/store";
import {CfLicense} from "@app-web-central/web/shared/data-access/models";

export const loadLicenses = createAction(
  '[License List] Load Licenses'
);

export const loadLicensesSuccess = createAction(
  '[License List] Load Licenses Success',
  props<{
    licenses: CfLicense[]
  }>()
);

export const loadLicensesError = createAction(
  '[License List] Load Licenses Error',
  props<{
    error: string
  }>()
);
