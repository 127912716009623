<nz-select
  class="w-full"
  [formControl]="control"
  nzPlaceHolder="{{ 'add_task.task_assignee_placeholder' | translate }}"
  [nzCustomTemplate]="assigneesSelectedTmpl"
  [nzNotFoundContent]="noUserFoundTmpl"
  nzNoAnimation>
  <nz-option
    nzCustomContent
    *ngFor="let user of users"
    [nzValue]="user"
    [nzLabel]="user.fullName">
    <as-user [user]="user"></as-user>
  </nz-option>
</nz-select>

<ng-template #assigneesSelectedTmpl
             let-selected>
  <as-user [user]="getUser(selected.nzValue.id)"></as-user>
</ng-template>

<ng-template #noUserFoundTmpl>
  {{ 'add_task.assignee_not_found' | translate }}
</ng-template>
