import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CfTaskMetadata} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-task-drawer-metadata',
  templateUrl: './task-drawer-metadata.component.html',
  styleUrls: ['./task-drawer-metadata.component.scss'],
})
export class TaskDrawerMetadataComponent {
  @Input() control!: FormControl;
  @Output() removeMetadata = new EventEmitter<CfTaskMetadata>();
  @Output() editMetadata = new EventEmitter<CfTaskMetadata>();
  onRemoveMetadata(meta: CfTaskMetadata) {
    this.removeMetadata.emit(meta);
  }
  onEditMetadata(meta: CfTaskMetadata) {
    this.editMetadata.emit(meta);
  }
}
