<div>
  <h2 class="pb-2 text-sm font-medium text-gray-900">
    {{ 'task_detail.suite' | translate }}
  </h2>

  <as-combobox
    [props]="['type', 'suiteNumber']"
    [searchProp]="'suiteNumber'"
    placeholder="{{ 'task_detail.add_suite' | translate }}"
    [items]="suites"
    [selectedItem]="control.value"
    [disabled]="!!task.dates?.validationDate"
    (activeItem)="onSelectSuite($event)" >
  </as-combobox>

</div>
