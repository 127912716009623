<footer class="footer-container">
  <div class="footer-wrapper">
    <div class="footer-r-wrapper">
      <a *ngFor="let item of rightItems"
         (click)="routerUrl(item.url)"
         [class.disabled]="item.url === null"
         class="nav-item">
        <span>{{ item.label | translate }}</span>
      </a>
      <a (click)="onOpenSupportModal()"
         class="nav-item">
        <span>{{ 'tooltips.support' | translate }}</span>
      </a>
    </div>

    <div class="footer-l-wrapper">
      <p class="copyright">&copy; {{ currentYear }}, Pendora Labs</p>
      <a *ngFor="let item of leftItems"
         (click)="routerUrl(item.url)"
         class="nav-item">
        <span>{{ item.label | translate }}</span>
      </a>
    </div>
  </div>
</footer>
